import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const SuccessRegistration = () => {
    return (
        <MDBox
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '350px',
            }}
        >
            <MDBox
                // color="white"
                // bgColor="transparent"
                // variant="gradient"
                // borderRadius="lg"
                // shadow="lg"
                // opacity={1}
                p={1}
                mt={0}
                height={350}
                width={200}
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                textAlign="center"
            >
                <div style={{ width: '200px', height: '200px', fontSize: '200px', margin: '0', padding: '0', display: 'flex', justifyContent: 'center', alignItems: 'center' }}> {/* Set width and height to match icon size */}
                    <CheckCircleIcon style={{ color: '#45964c' }} />
                </div>
                <MDTypography variant="h5" sx={{ color: 'black', fontWeight: 'bold', textAlign: 'center' }}>
                    Your submission has been sent, awaiting approval...
                </MDTypography>
            </MDBox>
        </MDBox >
    );
}

export default SuccessRegistration;