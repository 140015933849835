import { ProgrammesContext } from "context/ProgrammeContext";
import { useContext } from "react";

export const useProgrammeContext = () => {
  const context = useContext(ProgrammesContext);

  if (!context) {
    throw Error(
      "useProgrammeContext must be used inside an ProgrammesContextProvider"
    );
  }

  return context;
};
