import { useState, useEffect } from "react";
import { useAuthContext } from "hooks/useAuthContext";

import { MenuItem, IconButton, useMediaQuery } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";

import { useHandleProjectSubmission } from "hooks/useHandleProjectSubmission";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import { API_URL } from "../../../../config";

const RegisterProject = ({ onRegister, onCancel }) => {
  const { user } = useAuthContext();

  const [projectTitle, setProjectTitle] = useState("");
  const [projectDescription, setProjectDescription] = useState("");
  const [selectedSupervisor, setSelectedSupervisor] = useState(null);
  const [supervisors, setSupervisors] = useState([]);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm")); // Check for small screen size

  const {
    handleprojectsubmission,
    isLoading,
    error,
    success,
    setError,
    setSuccess,
  } = useHandleProjectSubmission();

  useEffect(() => {
    const mdInput = document.querySelector(".MuiSelect-select");
    if (mdInput) {
      mdInput.style.height = "40px";
    }
  }, []);

  //Fetch projects from the backend
  useEffect(() => {
    const fetchFiles = async () => {
      try {
        const response = await fetch(`${API_URL}/supervisors/getsupervisors`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        });

        const supervisors = await response.json();

        if (response.ok) {
          if (supervisors.length > 0) {
            setSupervisors(supervisors);
          } else {
            setSupervisors([]);
          }
        }
        if (!response.ok) {
          throw new Error("Failed to fetch files");
        }
      } catch (error) {
        console.error("Error fetching files:", error);
        setSupervisors([]); // Set an empty array or handle the error as needed
      }
    };

    if (user) {
      fetchFiles();
    }
  }, [user]);
  // Data from Backend ends here

  const handleProjectSubmission = async (e) => {
    e.preventDefault();
    console.log("Selected supervisor:", selectedSupervisor);
    const selectedSupervisorEmail =
      selectedSupervisor && selectedSupervisor !== "None"
        ? supervisors.find((supervisor) => supervisor.id === selectedSupervisor)
            .email
        : "None";

    console.log("Selected supervisor email:", selectedSupervisorEmail);

    // Handle submission logic here
    await handleprojectsubmission(
      projectTitle,
      projectDescription,
      selectedSupervisorEmail
    );
  };

  useEffect(() => {
    if (success) {
      onRegister();
    }
  }, [success, onRegister, setError, setSuccess]);
  return (
    <MDBox
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "350px",
      }}
    >
      <MDBox
        position="relative"
        color="white"
        bgColor="transparent"
        variant="gradient"
        borderRadius="lg"
        shadow="lg"
        opacity={1}
        p={3}
        mt={0}
        height={350}
        width={isSmallScreen ? "100%" : 800} // Adjust width for small screens
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        alignItems="center"
      >
        <IconButton
          onClick={onCancel}
          sx={
            isSmallScreen
              ? { position: "absolute", top: 20, left: 0, color: "blue" }
              : { position: "absolute", top: 20, left: 20, color: "blue" }
          } // Adjust position as needed
        >
          <ArrowBack />
        </IconButton>
        <MDTypography
          variant="h5"
          sx={{ color: "black", fontWeight: "bold", textAlign: "center" }}
        >
          Get Started With Your Research Journey
        </MDTypography>
        <MDInput
          label="Project Title"
          value={projectTitle}
          onChange={(e) => setProjectTitle(e.target.value)}
          placeholder="Project Title here"
          fullWidth
          mb={2}
          required
        />
        <MDInput
          label="Project Description"
          value={projectDescription}
          onChange={(e) => setProjectDescription(e.target.value)}
          placeholder="Insert a brief description of your project"
          fullWidth
          multiline
          rows={isSmallScreen ? 2 : 4} // Adjust number of rows for small screens
          mb={2}
          required
        />
        <MDInput
          label="Select Supervisor"
          select
          value={selectedSupervisor}
          onChange={(e) => setSelectedSupervisor(e.target.value)}
          fullWidth
          mb={3}
          required
          SelectProps={{
            MenuProps: { PaperProps: { style: { maxHeight: 150 } } },
          }} // Set max height for the dropdown menu
        >
          <MenuItem value="None">
            <em>None</em>
          </MenuItem>
          {supervisors.map((supervisor) => (
            <MenuItem key={supervisor.id} value={supervisor.id}>
              {supervisor.first_name} {supervisor.last_name}
            </MenuItem>
          ))}
        </MDInput>
        <MDButton
          variant="contained"
          color="info"
          onClick={handleProjectSubmission}
          disabled={isLoading}
        >
          Submit
        </MDButton>

        {error && (
          <MDBox
            borderRadius="4px"
            px={3}
            m={2}
            style={{
              textAlign: "center",
              border: "1px solid #f44335", // Error redish color for box border
              backgroundColor: "rgba(244, 67, 53, 0.2)", // Lighter transparent redish color for box background
            }}
          >
            <MDTypography color="error">{error}</MDTypography>
          </MDBox>
        )}
        {success && (
          <MDBox
            borderRadius="4px"
            px={3}
            m={2}
            style={{
              textAlign: "center",
              border: "1px solid #4caf50",
              backgroundColor: "rgba(76, 175, 80, 0.2)",
            }}
          >
            <MDTypography color="success">{success}</MDTypography>
          </MDBox>
        )}
      </MDBox>
    </MDBox>
  );
};

export default RegisterProject;
