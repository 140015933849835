import { useEffect, useState } from "react";

import { PersonAdd } from "@mui/icons-material";
import { Card } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";

//Data
import supervisorsData from "../data/supervisorsData";
import MDButton from "components/MDButton";
import { API_URL } from "../../../../config";

const AllSupervisors = () => {
  const { columns, rows1 } = supervisorsData();
  const [programmes, setProgrammes] = useState([]);
  const [academicYears, setAcademicYears] = useState([]);

  //Fetch programmes and academic years from the backend
  useEffect(() => {
    const fetchProgrammes = async () => {
      try {
        const response = await fetch(`${API_URL}/programmes/getprogrammes`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            // Authorization: `Bearer ${user.token}`,
          },
        });

        const programmesjson = await response.json();

        if (response.ok) {
          setProgrammes(programmesjson);
        }
        if (!response.ok) {
          throw new Error("Failed to fetch files");
        }
      } catch (error) {
        console.error("Error fetching files:", error);
      }
    };

    const fetchAcademicYears = async () => {
      try {
        const response = await fetch(`${API_URL}/academicyears/getyears`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            // Authorization: `Bearer ${user.token}`,
          },
        });

        const academicyearsjson = await response.json();

        if (response.ok) {
          setAcademicYears(academicyearsjson);
        }
        if (!response.ok) {
          throw new Error("Failed to fetch files");
        }
      } catch (error) {
        console.error("Error fetching files:", error);
      }
    };

    fetchProgrammes();
    fetchAcademicYears();
  }, []);

  return (
    <Card>
      <MDBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        px={3}
        pt={3}
      >
        <MDTypography variant="h5" gutterBottom>
          All Supervisors
        </MDTypography>
      </MDBox>
      <MDBox mx={3} mt={2} px={3}>
        <MDButton
          variant="gradient"
          color="info"
          size="small"
          startIcon={<PersonAdd />}
          sx={{ marginRight: "10px" }}
        >
          Add Supervisor
        </MDButton>
      </MDBox>
      <MDBox>
        <DataTable
          table={{ columns, rows: rows1 }}
          isSorted={false}
          canSearch={true}
          entriesPerPage={false}
          showTotalEntries={true}
          // noEndBorder
        />
      </MDBox>
    </Card>
  );
};

export default AllSupervisors;
