import { PersonAdd, Task } from "@mui/icons-material";
import { Card } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";

//Data
import programmeData from "../data/programmesData";
import AddProgrammePopup from "../components/addProgrammePopup";

const Programme = () => {
  const { columns, rows } = programmeData();

  // Check if columns and rows are ready
  // const isDataReady = columns.length > 0 && rows.length > 0;
  return (
    <Card>
      <MDBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        px={3}
        pt={3}
      >
        <MDTypography variant="h5" gutterBottom>
          Programmes
        </MDTypography>
      </MDBox>
      <MDBox mx={3} mt={2} px={3}>
        <AddProgrammePopup />
      </MDBox>
      <MDBox pt={2}>
        <DataTable
          table={{ columns, rows }}
          isSorted={true}
          entriesPerPage={false}
          showTotalEntries={true}
          noEndBorder
        />
      </MDBox>
    </Card>
  );
};

export default Programme;
