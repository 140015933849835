import { API_URL } from "../config";

export async function syncLocalStorageWithBackend(email) {
  try {
    const response = await fetch(`${API_URL}/students/getstudent/${email}`, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    });

    if (!response.ok) {
      const json = await response.json();
      throw new Error(json.error);
    }

    const json = await response.json();

    console.log("Response from Syncing: ", json);

    // save the user to local storage
    localStorage.setItem("user", JSON.stringify(json));

    console.log("Local Storage Updated!!");
  } catch (error) {
    console.error("Failed to sync data:", error);
  }
}

export function updateLocalStorage(newData) {
  localStorage.setItem("user", JSON.stringify(newData));
}
