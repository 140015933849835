// hooks
import { useState, useEffect } from "react";
import { useAuthContext } from "hooks/useAuthContext";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// react icons
import { Chat, Settings } from "@mui/icons-material";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";

// Material Dashboard 2 React themes
import theme from "assets/theme";

// Material Dashboard 2 React Dark Mode themes
import themeDark from "assets/theme-dark";

// Material Dashboard 2 React contexts
import {
  useMaterialUIController,
  setMiniSidenav,
  setOpenConfigurator,
} from "context";

// Images
import brandWhite from "assets/images/logos/analytics.png";
import brandDark from "assets/images/logos/analytics.png";

// Material Dashboard 2 React routes
import CoordinatorRoutes from "routes/coordinatorRoutes";
import StudentRoutes from "routes/studentRoutes";
import SupervisorRoutes from "routes/supervisorRoutes";
import DepartmentCoordinatorRoutes from "routes/departmentCoordinatorRoutes";

// Dashboards
import DashboardStudent from "layouts/studentLayouts/dashboard";
import DashboardSupervisor from "layouts/supervisorLayouts/dashboard";
import DashboardCoordinator from "layouts/coordinatorLayouts/dashboard";
import DashboardDepartmentCoordinator from "layouts/departmentCoordinatorLayouts/dashboard";

// Custom components
import LandingPage from "layouts/authentication/landing-page";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";
import ResetPassword from "layouts/authentication/reset-password/cover";
import ConfirmSignup from "layouts/authentication/confirm-signup/cover";
import ForgotPassword from "layouts/authentication/forgot-password/cover";

import ViewDocumentSupervisor from "./layouts/supervisorLayouts/dashboard/components/viewDocumentSupervisor";
import ViewDocumentStudent from "./layouts/studentLayouts/dashboard/homepage/components/viewDocumentStudent";
// import Trials from "mycomponents/Trials";

import ViewProject from "layouts/coordinatorLayouts/progress/components/viewProject";
import ViewProjectAll from "layouts/authentication/landing-page/sections/components/viewProjectAll";

export default function App() {
  const { user } = useAuthContext();
  const [routes, setRoutes] = useState(null);

  useEffect(() => {
    const fetchRoutes = async () => {
      // Fetch user role and configure routes
      if (user) {
        const configuredRoutes = configureRoutes(user.role);
        setRoutes(configuredRoutes);
      }
    };

    fetchRoutes();
  }, [user]);

  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () =>
    setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the direction attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      // console.log("All Routes:", allRoutes);
      if (route.collapse) {
        // console.log("Colapse:", route.collapse);
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return (
          <Route
            exact
            path={route.route}
            element={route.component}
            key={route.key}
          />
        );
      }

      return null;
    });

  const configureRoutes = (role) => {
    if (role === "supervisor") {
      return SupervisorRoutes;
    } else if (role === "departmentcoordinator") {
      return DepartmentCoordinatorRoutes;
    } else if (role === "coordinator") {
      return CoordinatorRoutes;
    } else {
      return StudentRoutes;
    }
  };

  const configsButton = (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.25rem"
      height="3.25rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      <Chat color="inherit" />
    </MDBox>
  );

  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      {layout === "dashboard" && user && routes && (
        <>
          <Sidenav
            color={sidenavColor}
            brand={
              (transparentSidenav && !darkMode) || whiteSidenav
                ? brandDark
                : brandWhite
            }
            brandName="Research Tracker"
            routes={routes}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
          {/* <Configurator /> */}
          {/* {configsButton} */}
        </>
      )}
      {layout === "vr" && <Configurator />}
      {/* 
        <Route path="/forgotpassword" element={<ForgotPassword />} />
        <Route path="/resetpassword/:token" element={<ResetPassword />} /> */}

      {user && routes && (
        <Routes>
          {/* Route accessible after authentication */}
          <Route
            exact
            path="/"
            element={
              user.role === "coordinator" ? (
                <DashboardCoordinator />
              ) : user.role === "departmentcoordinator" ? (
                <DashboardDepartmentCoordinator />
              ) : user.role === "supervisor" ? (
                <DashboardSupervisor />
              ) : (
                <DashboardStudent />
              )
            }
          />
          {getRoutes(routes)}
          {/* Route for viewing document */}
          <Route
            path="/view-document"
            element={
              user.role === "supervisor" ? (
                <ViewDocumentSupervisor />
              ) : (
                <ViewDocumentStudent />
              )
            }
          />
          {/* Route of viewing project */}
          <Route
            path="/view-project"
            element={user.role === "coordinator" && <ViewProject />}
          />
          <Route exact path="/signin" element={<Navigate to="/" />} />
          <Route exact path="/signup" element={<Navigate to="/" />} />
          <Route exact path="/forgotpassword" element={<Navigate to="/" />} />
          <Route exact path="/confirmsignup/" element={<ConfirmSignup />} />
          <Route
            exact
            path="/resetpassword/:token"
            element={<Navigate to="/" />}
          />
          <Route path="*" element={<Navigate to="/signin" />} />
          {/* <Route path="*" element={<div>No match for {window.location.pathname}</div>} /> */}
        </Routes>
      )}

      {!user && (
        <Routes>
          {/* Routes accessible without authentication */}
          <Route exact path="/landingpage" element={<LandingPage />} />
          <Route exact path="/" element={<LandingPage />} />
          <Route path="/viewproject" element={<ViewProjectAll />} />
          <Route exact path="/signin" element={<SignIn />} />
          <Route exact path="/signup" element={<SignUp />} />
          <Route exact path="/forgotpassword" element={<ForgotPassword />} />
          <Route
            exact
            path="/resetpassword/:token"
            element={<ResetPassword />}
          />
          <Route exact path="/confirmsignup/" element={<ConfirmSignup />} />

          <Route path="*" element={<Navigate to="/landingpage" />} />
        </Routes>
      )}
    </ThemeProvider>
  );
}
