import { useEffect, useState } from "react";

import { FilterAlt } from "@mui/icons-material";
import { Card, MenuItem } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";

//Data
import AddStudentPopup from "../components/addStudentPopup";
import quickStatData from "../data/quickStatData";
import MDInput from "components/MDInput";

const QuickStats = () => {
  const { columns, rows1 } = quickStatData();
  // const { columns, rows } = studentsData();

  // const [selectedProgramme, setSelectedProgramme] = useState("");
  // const [programmes, setProgrammes] = useState([]);
  // const [academicYears, setAcademicYears] = useState([]);
  // const [academicyear, setAcademicYear] = useState(null);
  // const [filteredRows, setFilteredRows] = useState([]);

  return (
    <Card>
      <MDBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        px={3}
        pt={3}
      >
        <MDTypography variant="h5" gutterBottom>
          Quick Stats
        </MDTypography>
      </MDBox>
      <MDBox>
        <DataTable
          table={{ columns, rows: rows1 }}
          isSorted={false}
          canSearch={true}
          entriesPerPage={false}
          showTotalEntries={true}
          // noEndBorder
        />
      </MDBox>
    </Card>
  );
};

export default QuickStats;
