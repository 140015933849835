import { useState } from "react";
import { useAuthContext } from "./useAuthContext";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../config";

export const useSignup = () => {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  //   const { dispatch } = useAuthContext();

  const validateEmail = (email) => {
    const regex = /^[a-zA-Z0-9._%+-]+@std\.must\.ac\.ug$/;
    if (!regex.test(email)) {
      setError("Please enter a valid student email address");
      return false;
    } else {
      setError(null);
      return true;
    }
  };

  const validatePassword = (password, confirmPassword) => {
    if (password !== confirmPassword) {
      setError("Passwords do not match");
      return false;
    }
    return true;
  };

  const validatePhoneNumber = (phoneNumber) => {
    const regex = /^07\d{8}$/;
    if (!regex.test(phoneNumber)) {
      setError(
        "Please enter a valid phone number starting with 07 and containing exactly ten digits"
      );
      return false;
    } else {
      setError(null);
      return true;
    }
  };

  const validateRegNumber = (regNumber) => {
    const regex = /^(20[0-9]{2})\/[A-Z]{3}\/[0-9]{3}([\/][A-Z]{2})?$/;
    if (!regex.test(regNumber)) {
      setError(
        "Please enter a valid registration number in the format: 2020/BSE/043 or 2020/BSE/043/PS"
      );
      return false;
    } else {
      setError(null);
      return true;
    }
  };

  const validateYearOfStudy = (yearOfStudy) => {
    const year = parseInt(yearOfStudy);
    if (year < 1 || year > 5 || isNaN(year)) {
      setError("Year of study must be between 1 and 5");
      return false;
    } else {
      setError(null);
      return true;
    }
  };

  const validateAcademicYear = (academicYear) => {
    if (!academicYear) {
      setError("Academic Year Missing");
      return false;
    } else {
      setError(null);
      return true;
    }
  };

  const signup = async (
    first_name,
    last_name,
    email,
    programme,
    academicyear,
    password,
    confirmPassword,
    regNumber,
    phoneNumber,
    yearOfStudy
  ) => {
    if (
      !validateEmail(email) ||
      !validatePassword(password, confirmPassword) ||
      !validatePhoneNumber(phoneNumber) ||
      !validateRegNumber(regNumber) ||
      !validateYearOfStudy(yearOfStudy) ||
      !validateAcademicYear(academicyear)
    ) {
      return; // Exit early if validation fails
    }
    setIsLoading(true);
    setError(null);

    try {
      const response = await fetch(`${API_URL}/students/signup`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          first_name,
          last_name,
          email,
          programme,
          academicyear,
          regNumber,
          phoneNumber,
          yearOfStudy,
          password,
        }),
      });

      if (!response.ok) {
        const json = await response.json();
        throw new Error(json.error);
      }

      const json = await response.json();
      navigate("/confirmsignup", { state: { email: json.studentemail } });

      // save the user to local storage
      // localStorage.setItem('user', JSON.stringify(json));

      // update the auth context
      // dispatch({ type: 'LOGIN', payload: json });
    } catch (error) {
      setError(error.message || "An error occurred during signup");
    } finally {
      setIsLoading(false);
    }
  };

  return { signup, isLoading, error };
};
