import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from "react";
import { useAuthContext } from "hooks/useAuthContext";

import { API_URL } from "../config";

const QuickStatsContext = createContext();

export const useQuickStatsContext = () => {
  return useContext(QuickStatsContext);
};

export const QuickStatsProvider = ({ children }) => {
  const { user } = useAuthContext();
  const [dataFromBackend, setDataFromBackend] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchQuickStats = useCallback(async () => {
    try {
      const response = await fetch(`${API_URL}/projects/quickstats`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      });

      const quickstatsjson = await response.json();

      if (response.ok) {
        setDataFromBackend(quickstatsjson);
        // console.log("Quick Stats:", quickstatsjson);
      } else {
        throw new Error("Failed to fetch quick stats");
      }
    } catch (error) {
      console.error("Error fetching quick stats:", error);
    } finally {
      setLoading(false);
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      fetchQuickStats();
    }
  }, [user, fetchQuickStats]);

  return (
    <QuickStatsContext.Provider
      value={{ dataFromBackend, loading, fetchQuickStats }}
    >
      {children}
    </QuickStatsContext.Provider>
  );
};
