// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// @mui icons

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";

// Data

// Images
import homeDecor1 from "assets/images/home-decor-1.jpg";
import homeDecor2 from "assets/images/home-decor-2.jpg";
import homeDecor3 from "assets/images/home-decor-3.jpg";
import homeDecor4 from "assets/images/home-decor-4.jpeg";
import ProjectsTable from "../tables/previousProjectsTable";

const AllPreviousProjects = ({ onClick }) => {
  return (
    <MDBox>
      {/* from here */}
      <MDBox pt={2} px={2} lineHeight={1.25}>
        <MDTypography variant="h6" fontWeight="large">
          Previous Projects
        </MDTypography>
        <MDBox mb={1}>
          <MDTypography variant="button" color="text">
            Learn from those who did it before
          </MDTypography>
        </MDBox>
      </MDBox>
      <MDBox p={2}>
        <Grid container spacing={6}>
          <Grid item xs={12} md={6} xl={3}>
            <DefaultProjectCard
              image={homeDecor1}
              title="modern"
              description="As Uber works through a huge amount of internal management turmoil."
              action={{
                type: "internal",
                route: "/notifications",
                color: "info",
                label: "view project",
              }}
              onClick={onClick}
              // authors={[
              //   { image: team1, name: "Elena Morison" },
              //   { image: team2, name: "Ryan Milly" },
              //   // { image: team3, name: "Nick Daniel" },
              //   // { image: team4, name: "Peterson" },
              //   // { image: team4, name: "Peterson" },
              // ]}
            />
          </Grid>
          <Grid item xs={12} md={6} xl={3}>
            <DefaultProjectCard
              image={homeDecor2}
              title="scandinavian"
              description="Music is something that everyone has their own specific opinion about."
              action={{
                type: "internal",
                route: "/pages/profile/profile-overview",
                color: "info",
                label: "view project",
              }}
              // authors={[
              //   { image: team3, name: "Nick Daniel" },
              //   { image: team4, name: "Peterson" },
              //   { image: team1, name: "Elena Morison" },
              //   { image: team2, name: "Ryan Milly" },
              // ]}
            />
          </Grid>
          <Grid item xs={12} md={6} xl={3}>
            <DefaultProjectCard
              image={homeDecor3}
              // label="project #3"
              title="minimalist"
              description="Different people have different taste, and various types of music."
              action={{
                type: "internal",
                route: "/pages/profile/profile-overview",
                color: "info",
                label: "view project",
              }}
              // authors={[
              //   { image: team4, name: "Peterson" },
              //   { image: team3, name: "Nick Daniel" },
              //   { image: team2, name: "Ryan Milly" },
              //   { image: team1, name: "Elena Morison" },
              // ]}
            />
          </Grid>
          <Grid item xs={12} md={6} xl={3}>
            <DefaultProjectCard
              image={homeDecor4}
              // label="project #4"
              title="gothic"
              description="Why would anyone pick blue over pink? Pink is obviously a better color."
              action={{
                type: "internal",
                route: "/pages/profile/profile-overview",
                color: "info",
                label: "view project",
              }}
              // authors={[
              //   { image: team4, name: "Peterson" },
              //   { image: team3, name: "Nick Daniel" },
              //   { image: team2, name: "Ryan Milly" },
              //   { image: team1, name: "Elena Morison" },
              // ]}
            />
          </Grid>
        </Grid>
      </MDBox>
      <Divider
        sx={{ borderBottom: "2px solid rgba(0, 0, 0, 0.12)" }}
        variant="middle"
      />
      <MDBox>
        <ProjectsTable />
      </MDBox>

      {/* to here */}
    </MDBox>
  );
};

export default AllPreviousProjects;
