import { Card, Divider } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useAuthContext } from "hooks/useAuthContext";
import { useEffect, useState } from "react";
import { API_URL } from "../../../../../config";

const DetailSection = () => {
  const { user } = useAuthContext();
  const [project, setProject] = useState(null);

  useEffect(() => {
    const fetchProject = async () => {
      try {
        const response = await fetch(
          `${API_URL}/projects/getproject/${user.email}`,
          {
            method: "GET",
            Authorization: `Bearer ${user.token}`,
          }
        );

        const project = await response.json();
        if (response.ok) {
          setProject(project);
          // console.log("Project:", project);
        } else {
          throw new Error("Failed to fetch project");
        }
      } catch (error) {
        console.error("Error fetching project:", error);
      }
    };

    fetchProject();
  }, [user]);

  const formatProgress = (progress) => {
    if (progress < 30) {
      return (
        <MDTypography variant="h6" color="info">
          {progress}%
        </MDTypography>
      );
    } else if (progress > 60) {
      return <MDTypography color="success">{progress}%</MDTypography>;
    } else {
      return <MDTypography color="warning">{progress}%</MDTypography>;
    }
  };

  return (
    <Card sx={{ height: "100%", padding: 3 }}>
      <MDBox
        display="flex"
        justifyContent="center"
        textAlign="center"
        marginBottom={3}
      >
        <MDTypography variant="h5" fontWeight="medium">
          Quick Details
        </MDTypography>
      </MDBox>
      {project && (
        <MDBox
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          justifyContent="center"
          paddingLeft={3}
        >
          <MDBox my={4}>
            <MDTypography variant="body2" fontWeight="medium" gutterBottom>
              <b>NAME:</b>{" "}
              <span style={{ marginLeft: "5px" }}>
                {project.Student.first_name} {project.Student.last_name}
              </span>
            </MDTypography>
          </MDBox>
          <Divider sx={{ my: 0 }} />
          <MDBox mb={4}>
            <MDTypography variant="body2" fontWeight="medium" gutterBottom>
              <b>PROJECT:</b>{" "}
              <span style={{ marginLeft: "5px" }}>{project.projectTitle}</span>
            </MDTypography>
          </MDBox>
          <MDBox mb={4}>
            <MDTypography variant="body2" fontWeight="medium" gutterBottom>
              SUPERVISOR:{" "}
              <b>
                <span style={{ marginLeft: "5px" }}>
                  {project.Supervisor.first_name} {project.Supervisor.last_name}
                </span>
              </b>
            </MDTypography>
          </MDBox>
          <MDBox mb={4}>
            <MDTypography variant="body2" fontWeight="medium" gutterBottom>
              <b>STAGE:</b>{" "}
              <span style={{ marginLeft: "5px" }}>{project.stage}</span>
            </MDTypography>
          </MDBox>
          <MDBox mb={4} display="flex" flexDirection="column">
            <MDTypography
              variant="body2"
              fontWeight="medium"
              style={{ marginRight: "10px" }}
            >
              <b>OVERALL COMPLETION:</b> {formatProgress(project.progress)}
            </MDTypography>
          </MDBox>
        </MDBox>
      )}
    </Card>
  );
};

export default DetailSection;
