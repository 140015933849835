import { Link } from "react-router-dom";
import React from "react";
import { IconButton, List, ListItem, ListItemText, Divider, Tooltip } from "@mui/material";
import { ArrowBack, Visibility, GetApp, DoneAll, Done } from "@mui/icons-material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

const PreviousSubmissions = ({ onSubmit, onCancel }) => {
    const submissions = [
        {
            title: 'Proposal Draft',
            description: 'This is the first draft of my proposal.',
            received: true,
            timestamp: '2024-02-25 09:00:00',
        },
        {
            title: 'Revised Proposal',
            description: 'This is the revised version of my proposal.',
            received: false,
            timestamp: '2024-02-26 11:30:00',
        },
        // Add more submissions as needed
    ];

    return (
        <MDBox
            sx={{
                // display: 'flex',
                // flexDirection: 'column',
                // justifyContent: 'center',
                // alignItems: 'center',
                p: { xs: 2, sm: 3, md: 4 }, // Adjust padding based on screen size
            }}
        >
            <MDBox
                color="white"
                bgColor="transparent"
                variant="gradient"
                borderRadius="lg"
                shadow="lg"
                opacity={1}
                p={3}
            // width={{ xs: '100%', sm: '80%', md: '60%' }} // Adjust width based on screen size
            >
                <MDBox display="flex" alignItems="center" mb={3}>
                    <IconButton onClick={onCancel}>
                        <ArrowBack />
                    </IconButton>
                    <MDTypography variant="h5" ml={1}>
                        Previous Submissions
                    </MDTypography>
                </MDBox>
                <Divider />
                <MDBox mt={3}>
                    <MDTypography variant="h6" mb={1}>
                        Submission History
                    </MDTypography>
                    <List>
                        {submissions.map((submission, index) => (
                            <React.Fragment key={index}>
                                <ListItem>
                                    <IconButton disabled={!submission.received}>
                                        {submission.received ? <DoneAll color="info" /> : <Done />}
                                    </IconButton>
                                    <ListItemText
                                        primary={submission.title}
                                        secondary={submission.description}
                                    />
                                    <MDTypography variant="body2">
                                        Submitted Date: {submission.timestamp}
                                    </MDTypography>
                                    <Tooltip title="Preview">
                                        <IconButton>
                                            <Visibility />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Download">
                                        <IconButton>
                                            <GetApp />
                                        </IconButton>
                                    </Tooltip>
                                </ListItem>
                                <Divider />
                            </React.Fragment>
                        ))}
                    </List>
                </MDBox>
                <MDBox mt={3} textAlign="center">
                    <MDButton variant="contained" color="info" onClick={onSubmit}>
                        Submit a Progress
                    </MDButton>
                </MDBox>
            </MDBox>
        </MDBox>
    );
};

export default PreviousSubmissions;
