import React, { useState, useEffect } from "react";
import { useAuthContext } from "hooks/useAuthContext";
import { IconButton } from "@mui/material";
import { ArrowBack, AttachFile } from "@mui/icons-material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDSnackbar from "components/MDSnackbar";
import { API_URL } from "../../../../config";

const AddProgress = ({ onSubmit, onCancel, submitData }) => {
  const { user } = useAuthContext();

  const [successSB, setSuccessSB] = useState(false);
  const closeSuccessSB = () => setSuccessSB(false);
  const [errorSB, setErrorSB] = useState(false);
  const closeErrorSB = () => setErrorSB(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [file, setFile] = useState(null);

  // console.log("User:", user);

  useEffect(() => {
    const mdInput = document.querySelector(".MuiSelect-select");
    if (mdInput) {
      mdInput.style.height = "55px";
    }

    setTitle(submitData.title);
    // console.log("Submit Data in Second Child:", submitData.title);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    const allowedExtensions = /(\.pdf|\.ppt|\.pptx)$/i;
    const allowedExtensions1 = /(\.pdf)$/i;

    if (
      submitData.title === "Concept Paper" ||
      submitData.title === "Proposal" ||
      submitData.title === "Report"
    ) {
      if (!allowedExtensions1.exec(selectedFile.name)) {
        setErrorMessage("Invalid file type. Please upload a .pdf file.");
        setErrorSB(true);
        setFile(null); // Reset the file state
        event.target.value = ""; // Clear the file input
      } else {
        setFile(selectedFile);
      }
    } else {
      if (!allowedExtensions.exec(selectedFile.name)) {
        setErrorMessage(
          "Invalid file type. Please upload a .pdf, .ppt, or .pptx file."
        );
        setErrorSB(true);
        setFile(null); // Reset the file state
        event.target.value = ""; // Clear the file input
      } else {
        setFile(selectedFile);
      }
    }
  };

  const handleSubmit = () => {
    if (!file) {
      setErrorMessage("Please select a file to upload.");
      setErrorSB(true);
      return;
    }

    const formData = new FormData();
    formData.append("file", file);
    formData.append("title", title);
    formData.append("description", description);
    formData.append("to", user.project.supervisorEmail);
    formData.append("from", user.email);
    formData.append("projectId", user.project.id);

    fetch(`${API_URL}/files/upload`, {
      method: "POST",
      body: formData,
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          setErrorMessage(data.error);
          setErrorSB(true);
        } else {
          setSuccessSB(true);
          setFile(null);
          // setTitle("");
          setDescription("");
        }
      })
      .catch((error) => {
        console.log(error);
        setErrorMessage("Error uploading file. Please try again.");
        setErrorSB(true);
      });
  };

  return (
    <MDBox
      color="white"
      bgColor="transparent"
      borderRadius="lg"
      shadow="lg"
      p={3}
    >
      <MDSnackbar
        color="success"
        icon="check"
        title="Success!!"
        content="File uploaded successfully!!"
        open={successSB}
        onClose={closeSuccessSB}
        close={closeSuccessSB}
        vertical="top"
        horizontal="center"
        bgWhite
      />
      <MDSnackbar
        color="error"
        icon="warning"
        title="Error!!"
        content={errorMessage}
        open={errorSB}
        onClose={closeErrorSB}
        close={closeErrorSB}
        vertical="top"
        horizontal="center"
        // bgWhite
      />
      <MDBox display="flex" alignItems="center">
        <IconButton onClick={onCancel}>
          <ArrowBack />
        </IconButton>
        <MDTypography variant="h5" ml={1}>
          Add New Progress
        </MDTypography>
      </MDBox>
      <MDBox>
        <MDInput
          label="Title"
          value={title}
          variant="outlined"
          // onChange={(e) => setTitle(e.target.value)}
          fullWidth
          sx={{ width: "100%" }}
          disabled
        />
        <MDInput
          label="Description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          fullWidth
          variant="outlined"
          multiline
          rows={4}
          style={{ marginTop: "8px", marginBottom: "16px" }}
        />

        {/* File Upload */}
        <input
          type="file"
          id="document-upload"
          // accept=".docx, .pdf, .xlsx"
          accept={
            submitData.title === "Concept"
              ? ".pdf"
              : submitData.title === "Proposal"
              ? ".pdf"
              : submitData.title === "Report"
              ? ".pdf"
              : ".ppt,.pptx,.pdf"
          }
          style={{ display: "none" }}
          onChange={handleFileChange}
        />
        <label htmlFor="document-upload">
          <MDButton
            variant="outlined"
            component="span"
            startIcon={<AttachFile />}
            color="info"
          >
            Upload Document
          </MDButton>
        </label>
        {file && (
          <MDTypography variant="body2" mt={2}>
            Selected File: {file.name}
          </MDTypography>
        )}
      </MDBox>
      <MDBox mt={3} textAlign="center">
        <MDButton variant="contained" color="info" onClick={handleSubmit}>
          Submit Document
        </MDButton>
      </MDBox>
    </MDBox>
  );
};

export default AddProgress;
