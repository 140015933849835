import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Grid from "@mui/material/Grid";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import {
  Box,
  Button,
  FormControlLabel,
  Switch,
  TextField,
} from "@mui/material";
import MDTypography from "components/MDTypography";

import { Edit, Save } from "@mui/icons-material";

// import { useEditSupervisor } from "hooks/settingsHooks/useEditSupervisor";

// import { useEditSupervisor } from "hooks/useEditSupervisor";

const EditSupervisorPopup = ({ supervisor, academicYear = "2020/2021" }) => {
  const [open, setOpen] = useState(false);
  const [supervisorData, setSupervisorData] = useState("");
  const [availability, setAvailability] = useState(false);
  // const { editsupervisor, error, isLoading, success, setError, setSuccess } =
  //   useEditSupervisor();

  useEffect(() => {
    if (supervisor) {
      setSupervisorData(supervisor.first_name);
      setAvailability(supervisor.Availabilities.some((a) => a.available));
      console.log("Availability:", availability);
      console.log("Current Supervisor:", supervisor);
    }
  }, [supervisor]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Availability:", availability);
    // await editsupervisor(supervisor.email, "2020/2021", availability);
    handleClose();
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);

    // Clear error on close
    // setError(null);
    // setSuccess(null);
  };

  return (
    <div>
      <Button onClick={handleClickOpen}>
        <Edit sx={{ cursor: "pointer", fontWeight: "bold" }} color="warning" />
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          style: { padding: 20, borderRadius: 10 },
        }}
      >
        <DialogTitle>Edit Supervisor</DialogTitle>
        <DialogContent>
          <MDBox display="flex" flexDirection="column" alignItems="center">
            {/* <MDInput
              label="Supervisor name..."
              name="supervisorData"
              value={supervisorData}
              onChange={(e) => setSupervisorData(e.target.value)}
              fullWidth
              variant="outlined"
              margin="normal"
            /> */}
            <FormControlLabel
              control={
                <Switch
                  checked={availability}
                  onChange={(e) => setAvailability(e.target.checked)}
                />
              }
              label="Available"
            />
          </MDBox>
        </DialogContent>
        <DialogActions>
          <MDButton onClick={handleClose}>Cancel</MDButton>
          <MDButton
            onClick={handleSubmit}
            variant="contained"
            color="info"
            // disabled={isLoading}
            startIcon={<Save />}
          >
            Save
          </MDButton>
        </DialogActions>
        {/* {error && (
          <MDBox
            borderRadius="4px"
            px={3}
            mx={2}
            mb={2}
            style={{
              textAlign: "center",
              border: "1px solid #f44335", // Error redish color for box border
              backgroundColor: "rgba(244, 67, 53, 0.2)", // Lighter transparent redish color for box background
            }}
          >
            <MDTypography color="error">{error}</MDTypography>
          </MDBox>
        )}
        {success && (
          <MDBox
            borderRadius="4px"
            px={3}
            mx={2}
            mb={2}
            style={{
              textAlign: "center",
              border: "1px solid #4caf50",
              backgroundColor: "rgba(76, 175, 80, 0.2)",
            }}
          >
            <MDTypography color="success">{success}</MDTypography>
          </MDBox>
        )} */}
      </Dialog>
    </div>
  );
};

export default EditSupervisorPopup;
