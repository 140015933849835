import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useAuthContext } from "hooks/useAuthContext";

import { formatDistanceToNow } from "date-fns";

// @mui material components
import Tooltip from "@mui/material/Tooltip";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDProgress from "components/MDProgress";
import Icon from "@mui/material/Icon";
import MDBadge from "components/MDBadge";
import { API_URL } from "../../../../config";

// Appropriate Extension Icons
const extensionIcons = {
  pdf: { icon: "picture_as_pdf", color: "#F40F02" },
  doc: { icon: "description", color: "#2196F3" },
  docx: { icon: "description", color: "#2196F3" },
  xls: { icon: "description", color: "#4CAF50" },
  xlsx: { icon: "description", color: "#4CAF50" },
  ppt: { icon: "slideshow", color: "#FF9800" },
  pptx: { icon: "slideshow", color: "#FF9800" },
  jpg: { icon: "image", color: "#FFC107" },
  jpeg: { icon: "image", color: "#FFC107" },
  png: { icon: "image", color: "#FFC107" },
  gif: { icon: "image", color: "#FFC107" },
  txt: { icon: "description", color: "#9E9E9E" },
};

// Function to format the date
const formatDate = (dateString) => {
  const date = new Date(dateString);
  const formattedDate = date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
  const formattedTime = date.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    // second: "2-digit",
    hour12: true,
  });
  return `${formattedDate} ${formattedTime}`;
};

export default function useData(email) {
  const navigate = useNavigate();
  const { user } = useAuthContext();

  const [dataFromBackend, setDataFromBackend] = useState([]); // Ensure initial state is an array
  const [loading, setLoading] = useState(true); // Add loading state

  // Fetch data from the backend
  useEffect(() => {
    const fetchConcepts = async () => {
      try {
        const response = await fetch(
          //   `${API_URL}/files/getconcepts/${user.email}`,
          `${API_URL}/files/getconcepts/${email}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${user.token}`,
            },
          }
        );

        const conceptsjson = await response.json();

        if (response.ok) {
          // Check if the response is an array
          if (Array.isArray(conceptsjson)) {
            setDataFromBackend(conceptsjson);
          } else {
            console.error("Error: Response is not an array", conceptsjson);
          }
          // console.log("Concepts:", conceptsjson);
        } else {
          throw new Error("Failed to fetch files");
        }
      } catch (error) {
        console.error("Error fetching files:", error);
      } finally {
        setLoading(false);
      }
    };

    if (email) {
      fetchConcepts();
    }
  }, [email]);

  // Components
  const Message = ({ item }) => {
    const { title, description } = item;

    const documentAttached = item.filename;

    const getFileExtension = (fileName) => {
      const parts = fileName.split(".");
      return parts[parts.length - 1].toLowerCase();
    };

    const fileExtension = getFileExtension(documentAttached);
    const { icon, color } = extensionIcons[fileExtension] || {
      icon: "attachment",
      color: "#9E9E9E",
    };

    const handleDocumentClick = () => {
      //   toggleReadStatus(index);
      // alert("The item is " + JSON.stringify(item)); // Corrected alert message
      navigate("/view-document", { state: { document: item } });
    };

    return (
      <MDBox>
        <MDBox
          textAlign="center"
          lineHeight={1}
          onClick={handleDocumentClick}
          sx={{
            ":hover": {
              textDecoration: "underline",
              cursor: "pointer",
            },
          }}
        >
          <MDTypography
            variant="body"
            fontWeight="bold"
            sx={{
              color: "#6B6B6B",
            }}
          >
            {title.toUpperCase()}
          </MDTypography>
        </MDBox>
        {documentAttached && (
          <MDBox display="flex" alignItems="center" lineHeight={1} mt={1}>
            <Icon sx={{ color: color }}>{icon}</Icon>
            <MDTypography
              variant="body"
              fontWeight="bold"
              ml={1}
              lineHeight={1}
              // sx={{ color: "#6B6B6B" }}
            >
              {documentAttached}
            </MDTypography>
          </MDBox>
        )}
      </MDBox>
    );
  };

  if (loading) {
    return {
      columns: [],
      rows: [],
    };
  }

  // Function to determine badge properties
  const getBadgeProperties = (status) => {
    switch (status.toLowerCase()) {
      case "pending":
        return { content: "Pending", color: "dark" };
      case "under review":
        return { content: "Under Review", color: "warning" };
      case "reviewed":
        return { content: "Reviewed", color: "info" };
      case "approved":
        return { content: "Approved", color: "success" };
      default:
        return { content: "Unknown", color: "secondary" }; // Default case for unknown statuses
    }
  };

  // Component rows mapping
  const rows = Array.isArray(dataFromBackend)
    ? dataFromBackend.map((item, index) => {
        const { content, color } = getBadgeProperties(item.status);
        return {
          document: <Message key={index} item={item} />,
          version: item.version,
          status: (
            <MDBox ml={-1}>
              <MDBadge
                badgeContent={content}
                color={color}
                variant="gradient"
                size="sm"
              />
            </MDBox>
          ),
          // date: formatDistanceToNow(new Date(item.createdAt), {
          //   addSuffix: true,
          // }),
          date: formatDate(item.createdAt),
          date1:
            formatDate(item.createdAt) === formatDate(item.updatedAt)
              ? "N/A"
              : formatDate(item.updatedAt),
        };
      })
    : [];

  // Example usage in the component return statement
  return {
    columns: [
      { Header: "document", accessor: "document", align: "center" },
      { Header: "version", accessor: "version", align: "center" },
      { Header: "status", accessor: "status", align: "center" },
      { Header: "submission date", accessor: "date", align: "center" },
      { Header: "review date", accessor: "date1", align: "center" },
    ],
    rows: rows,
  };
}
