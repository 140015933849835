import React, { useState, useEffect } from "react";
import { useAuthContext } from "hooks/useAuthContext";
import { useProgrammeContext } from "hooks/useProgrammeContext";
import { useSupervisorContext } from "hooks/useSupervisorContext";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Icons
import {
  Delete,
  Edit,
  IndeterminateCheckBox,
  MoreVert,
} from "@mui/icons-material";
import { Button } from "@mui/material";
import DeleteProgrammePopup from "../components/deleteProgrammePopup";
import EditProgrammePopup from "../components/editSupervisorPopup";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";
import { API_URL } from "../../../../config";

export default function useData() {
  const { user } = useAuthContext();
  const { supervisors, dispatch } = useSupervisorContext();
  const [loading, setLoading] = useState(true); // Add loading state

  //Fetch supervisors from the backend
  useEffect(() => {
    const fetchSupervisors = async () => {
      try {
        const response = await fetch(
          `${API_URL}/availability/unavailablesupervisors`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${user.token}`,
            },
          }
        );

        const supervisorsjson = await response.json();

        if (response.ok) {
          dispatch({ type: "GET_SUPERVISORS", payload: supervisorsjson });
          console.log(supervisorsjson);
        }
        if (!response.ok) {
          throw new Error("Failed to fetch files");
        }
      } catch (error) {
        console.error("Error fetching files:", error);
      } finally {
        setLoading(false);
      }
    };

    if (user) {
      fetchSupervisors();
    }
  }, [user, dispatch]);
  // Supervisors from Backend ends here

  if (loading) {
    return {
      columns: [],
      rows1: [], // or handle loading state differently if you have a loading component
    };
  }

  // Filter and map supervisors
  const rows1 = supervisors
    ? supervisors
        .filter((item) => item.is_active)
        .map((item) => ({
          name: `${item.first_name} ${item.last_name}`,
          email: item.email,
          status: (
            <MDBox ml={-1}>
              <MDBadge
                badgeContent="Not Available"
                color="dark"
                variant="gradient"
                size="sm"
              />
            </MDBox>
          ),
          edit: (
            <Edit
              sx={{ cursor: "pointer", fontWeight: "bold" }}
              fontSize="small"
              color="warning"
            />
          ),
        }))
    : [];

  return {
    columns: [
      { Header: "name", accessor: "name", align: "center" },
      { Header: "email", accessor: "email", align: "center" },
      { Header: "status", accessor: "status", align: "center" },
      { Header: "edit", accessor: "edit", align: "center" },
    ],

    rows1: rows1,
  };
}
