import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Grid from "@mui/material/Grid";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import { Box, Button, MenuItem, TextField, Typography } from "@mui/material";
import MDTypography from "components/MDTypography";

import { PersonAdd } from "@mui/icons-material";

import { useAddAcademicYear } from "hooks/settingsHooks/useAddAcademicYear";
// { open, handleClose }

const AddAcademicYearPopup = () => {
  const [open, setOpen] = useState(false);
  const [academicyear, setAcademicYear] = useState("");
  const { addacademicyear, error, isLoading, success, setError, setSuccess } =
    useAddAcademicYear();

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(academicyear);
    await addacademicyear(academicyear);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    //clear all fields
    setAcademicYear("");

    // Clear error on close
    setError(null);
    setSuccess(null);
  };

  return (
    <div>
      <MDButton
        variant="contained"
        color="info"
        size="small"
        startIcon={<PersonAdd />}
        onClick={handleClickOpen}
      >
        Add Academic Year
      </MDButton>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          style: { padding: 20, borderRadius: 10 },
        }}
      >
        <DialogTitle>Add Academic Year</DialogTitle>
        <DialogContent>
          <Box display="flex" justifyContent="center" alignItems="center">
            <TextField
              label="Enter Academic year..."
              name="academicYear"
              value={academicyear}
              onChange={(e) => setAcademicYear(e.target.value)}
              fullWidth
              variant="outlined"
              margin="normal"
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <MDButton onClick={handleClose}>Cancel</MDButton>
          <MDButton
            onClick={handleSubmit}
            variant="contained"
            color="info"
            disabled={isLoading}
          >
            Submit
          </MDButton>
        </DialogActions>
        {error && (
          <MDBox
            borderRadius="4px"
            px={3}
            mx={2}
            mb={2}
            style={{
              textAlign: "center",
              border: "1px solid #f44335", // Error redish color for box border
              backgroundColor: "rgba(244, 67, 53, 0.2)", // Lighter transparent redish color for box background
            }}
          >
            <MDTypography color="error">{error}</MDTypography>
          </MDBox>
        )}
        {success && (
          <MDBox
            borderRadius="4px"
            px={3}
            mx={2}
            mb={2}
            style={{
              textAlign: "center",
              border: "1px solid #4caf50",
              backgroundColor: "rgba(76, 175, 80, 0.2)",
            }}
          >
            <MDTypography color="success">{success}</MDTypography>
          </MDBox>
        )}
      </Dialog>
    </div>
  );
};

export default AddAcademicYearPopup;
