import React from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Import the image from the folder
// import submissionImage from "path/to/your/image.png"; // Update the path to your image
import patienceImage from "../../../../../assets/images/patience.png";

const Patience = () => {
  return (
    <MDBox
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "350px",
      }}
    >
      <MDBox
        p={1}
        mt={0}
        height={350}
        width={200}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        textAlign="center"
      >
        <div
          style={{
            width: "250px",
            height: "250px",
            margin: "0",
            padding: "0",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {/* Replace CheckCircleIcon with the image */}
          <img
            src={patienceImage}
            alt="Patience"
            style={{ width: "100%", height: "100%" }}
          />
        </div>
        <MDTypography
          variant="h5"
          sx={{ color: "black", fontWeight: "bold", textAlign: "center" }}
        >
          You are not yet there, PATIENCE!!
        </MDTypography>
      </MDBox>
    </MDBox>
  );
};

export default Patience;
