import React, { useState, useEffect } from "react";
import { useAuthContext } from "hooks/useAuthContext";
import { useProgrammeContext } from "hooks/useProgrammeContext";
import { useStudentContext } from "hooks/useStudentContext";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Icons
import {
  Delete,
  Edit,
  IndeterminateCheckBox,
  MoreVert,
} from "@mui/icons-material";
import { Button } from "@mui/material";
import DeleteProgrammePopup from "../components/deleteProgrammePopup";
import EditProgrammePopup from "../components/editProgrammePopup";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";
import { API_URL } from "../../../../config";

export default function useData() {
  const { user } = useAuthContext();
  const { students, dispatch } = useStudentContext();
  const [loading, setLoading] = useState(true); // Add loading state

  //Fetch students from the backend
  useEffect(() => {
    const fetchStudents = async () => {
      try {
        const response = await fetch(`${API_URL}/students/getstudents`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        });

        const studentsjson = await response.json();

        if (response.ok) {
          dispatch({ type: "GET_STUDENTS", payload: studentsjson });
          console.log(studentsjson);
        }
        if (!response.ok) {
          throw new Error("Failed to fetch files");
        }
      } catch (error) {
        console.error("Error fetching files:", error);
      } finally {
        setLoading(false);
      }
    };

    if (user) {
      fetchStudents();
    }
  }, [user, dispatch]);
  // Students from Backend ends here

  if (loading) {
    return {
      columns: [],
      rows1: [], // or handle loading state differently if you have a loading component
    };
  }

  // Filter and map students
  const rows1 = students
    ? students
        .filter((item) => item.is_approved)
        .map((item) => ({
          name: item.studentname,
          email: item.email,
          regnumber: item.regNumber,
          programme: item.programme,
          phonenumber: item.phoneNumber,
          academicyear: item.academicyear,
          status: (
            <MDBox ml={-1}>
              <MDBadge
                badgeContent="approved"
                color="success"
                variant="gradient"
                size="sm"
              />
            </MDBox>
          ),
          action: (
            <Edit
              sx={{ cursor: "pointer", fontWeight: "bold" }}
              fontSize="small"
              color="warning"
            />
          ),
        }))
    : [];

  return {
    columns: [
      { Header: "name", accessor: "name", align: "center" },
      { Header: "email", accessor: "email", align: "center" },
      { Header: "reg number", accessor: "regnumber", align: "center" },
      { Header: "programme", accessor: "programme", align: "center" },
      {
        Header: "phone number",
        accessor: "phonenumber",
        align: "center",
      },
      { Header: "academic year", accessor: "academicyear", align: "center" },
      { Header: "status", accessor: "status", align: "center" },
      { Header: "action", accessor: "action", align: "center" },
    ],

    rows1: rows1,
  };
}
