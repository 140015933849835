import React, { useState, useEffect } from "react";

import { Task, Visibility, PersonAdd } from "@mui/icons-material";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

//My components
import approvalsTableData from "layouts/departmentCoordinatorLayouts/tables/data/approvalsTableData";
import authorsTableData from "layouts/departmentCoordinatorLayouts/tables/data/authorsTableData";
import MDButton from "components/MDButton";
import AddStudentPopup from "mycomponents/DepartmentCoordinator/departmentcoordinatorDashboard/addStudentPopup";
import { Grid } from "@mui/material";
import MDInput from "components/MDInput";

const ApprovalsTable = () => {
  const { columns, rows } = approvalsTableData();
  // const { columns, rows } = authorsTableData()

  const [menu, setMenu] = useState(null);

  const openMenu = ({ currentTarget }) => setMenu(currentTarget);
  const closeMenu = () => setMenu(null);
  const renderMenu = (
    <Menu
      id="simple-menu"
      anchorEl={menu}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(menu)}
      onClose={closeMenu}
    >
      <MenuItem onClick={closeMenu}>All</MenuItem>
      <MenuItem onClick={closeMenu}>Active</MenuItem>
      <MenuItem onClick={closeMenu}>Completed</MenuItem>
    </Menu>
  );
  return (
    <MDBox mt={3}>
      <Card>
        {/* <MDBox mx={3} mt={2} px={3}>
                    <MDButton variant="gradient" color="info" size="small" startIcon={<PersonAdd />} sx={{ marginRight: '10px' }}>Add Student</MDButton>
                    <MDButton variant="gradient" color="info" size="small" startIcon={<PersonAdd />} sx={{ marginRight: '10px' }}>Add Supervisor</MDButton>
                    <MDButton variant="gradient" color="dark" size="small" startIcon={<Task />}>Bulk Approval</MDButton>
                </MDBox> */}
        <Grid container spacing={2} mx={2} mt={1} px={2}>
          <Grid item>
            <AddStudentPopup />
          </Grid>
          <Grid item>
            <AddStudentPopup />
          </Grid>
        </Grid>
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          px={3}
          pt={3}
        >
          <MDBox>
            <MDTypography variant="h5" gutterBottom>
              Pending Approvals
            </MDTypography>
          </MDBox>
          <MDBox width="12rem" ml="auto">
            <MDInput
              placeholder="Search..."
              //   value={searchQuery}
              //   onChange={handleSearch}
              size="small"
              fullWidth
            />
          </MDBox>
          <MDBox color="text" px={2}>
            <Icon
              sx={{ cursor: "pointer", fontWeight: "bold" }}
              fontSize="small"
              onClick={openMenu}
            >
              more_vert
            </Icon>
          </MDBox>
          {renderMenu}
        </MDBox>
        <DataTable
          table={{ columns, rows }}
          isSorted={false}
          entriesPerPage={false}
          showTotalEntries={false}
          noEndBorder
        />
      </Card>
    </MDBox>
  );
};

export default ApprovalsTable;
