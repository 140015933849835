import { useQuickStatsContext } from "context/QuickStatsContext";

export default function useData() {
  const { dataFromBackend, loading } = useQuickStatsContext();

  if (loading) {
    return {
      columns: [],
      rows1: [], // or handle loading state differently if you have a loading component
    };
  }

  return {
    columns: [
      { Header: "supervisor name", accessor: "supervisor", align: "center" },
      { Header: "no. of students", accessor: "students", align: "center" },
    ],

    rows1: dataFromBackend
      ? dataFromBackend.map((item) => ({
          supervisor: item.supervisorname,
          students: item.studentcount,
        }))
      : [],
  };
}
