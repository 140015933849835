/** 
  All of the routes for the Material Dashboard 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 React layouts
// import Dashboard from "layouts/dashboard";
// import Tables from "layouts/tables";
// import Billing from "layouts/billing";
// import Notifications from "layouts/notifications";
// import Profile from "layouts/profile";

import Dashboard2 from "layouts/coordinatorLayouts/dashboard";
import Tables from "layouts/coordinatorLayouts/tables";
import Billing from "layouts/coordinatorLayouts/billing";
import Notifications from "layouts/coordinatorLayouts/notifications";
import Profile from "layouts/coordinatorLayouts/profile";
import Settings1 from "layouts/coordinatorLayouts/settings";
import Students from "layouts/coordinatorLayouts/students";
// import ProfileSettings from "layouts/coordinatorLayouts/profileSettings";
// import AccountSettings from "layouts/coordinatorLayouts/accountSettings";

// @mui icons
import Icon from "@mui/material/Icon";
import {
  AccountTree,
  AutoGraph,
  Person,
  Person3,
  Settings,
  Dashboard,
} from "@mui/icons-material";
import Projects from "layouts/coordinatorLayouts/projects";
import Supervisors from "layouts/coordinatorLayouts/supervisors";
import PreviewDocument from "layouts/supervisorLayouts/dashboard/components/previewDocument";
import PreviousSubmissions from "layouts/studentLayouts/dashboard/homepage/previousSubmissions";
import Progress from "layouts/coordinatorLayouts/progress";

const CoordinatorRoutes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Dashboard fontSize="small" />,
    route: "/dashboard",
    component: <Dashboard2 />,
  },
  {
    type: "collapse",
    name: "Students",
    key: "students",
    // icon: <Icon fontSize="small">table_view</Icon>,
    icon: <Person fontSize="small" />,
    route: "/students",
    component: <Students />,
  },
  {
    type: "collapse",
    name: "Supervisors",
    key: "supervisors",
    icon: <Person3 fontSize="small" />,
    route: "/supervisors",
    component: <Supervisors />,
  },
  {
    type: "collapse",
    name: "Projects",
    key: "projects",
    icon: <AccountTree fontSize="small" />,
    route: "/projects",
    component: <Projects />,
  },
  {
    type: "collapse",
    name: "Progress",
    key: "progress",
    icon: <AutoGraph fontSize="small" />,
    route: "/progress",
    component: <Progress />,
  },
  {
    type: "collapse",
    name: "Settings",
    key: "settings",
    icon: <Settings fontSize="small" />,
    route: "/settings",
    component: <Settings1 />,
  },
  // {
  //   type: "collapse", //This is a trial
  //   name: "Dashboards",
  //   key: "dashboards",
  //   icon: <Shop size="12px" />,
  //   // route: "/dashboards",
  //   // component: <Supervisors />,
  //   collapse: [
  //     {
  //       type: "collapse",
  //       name: "Analytics",
  //       key: "analytics",
  //       route: "/analytics",
  //       icon: <Person3 fontSize="small" />,
  //       component: <Supervisors />,
  //     },
  //     {
  //       name: "Sales",
  //       key: "sales",
  //       route: "/dashboards/sales",
  //       component: <Settings1 />,
  //     },
  //   ],
  // },
  // {
  //   type: "collapse",
  //   name: "Billing",
  //   key: "billing",
  //   icon: <Icon fontSize="small">receipt_long</Icon>,
  //   route: "/billing",
  //   component: <Billing />,
  // },
  // {
  //   type: "collapse",
  //   name: "Notifications",
  //   key: "notifications",
  //   icon: <Icon fontSize="small">notifications</Icon>,
  //   route: "/notifications",
  //   component: <Notifications />,
  // },
  // {
  //   type: "collapse",
  //   name: "Profile",
  //   key: "profile",
  //   icon: <Icon fontSize="small">person</Icon>,
  //   route: "/profile",
  //   component: <Profile />,
  // },
  // {
  //   type: "collapse",
  //   name: "Tables",
  //   key: "tables",
  //   icon: <Icon fontSize="small">table_view</Icon>,
  //   route: "/tables",
  //   component: <Tables />,
  // },
  // {
  //   type: "collapse",
  //   name: "Sign In",
  //   key: "sign-in",
  //   icon: <Icon fontSize="small">login</Icon>,
  //   route: "/authentication/sign-in",
  //   component: <SignIn />,
  // },
  // {
  //   type: "collapse",
  //   name: "Sign Up",
  //   key: "sign-up",
  //   icon: <Icon fontSize="small">assignment</Icon>,
  //   route: "/authentication/sign-up",
  //   component: <SignUp />,
  // },
];

export default CoordinatorRoutes;
