// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import React, { useState, useEffect } from "react";

//Get Started Page
import GetStarted from "./getstartedpage/getStarted";
import RegisterProject from "./getstartedpage/registerProject";
import SuccessRegistration from "./getstartedpage/successRegistration";

//Body Page
import BodyPage from "./homepage/bodyPage";
import PreviousSubmissions from "./homepage/previousSubmissions";
import AddProgress from "./homepage/submitDocument";
import PreviewDocument from "./homepage/previewDocument";
import { useAuthContext } from "hooks/useAuthContext";
import Abstract from "./homepage/deliverable/abstract";
import { useNavigate } from "react-router-dom";
import { useLogout } from "hooks/useLogout";

function Dashboard() {
  const { user } = useAuthContext();
  const { logout } = useLogout();

  const navigate = useNavigate();

  const [getStarted, setGetStarted] = useState(false);
  const [registerProject, setRegisterProject] = useState(false);
  const [successRegistration, setSuccessRegistration] = useState(false);
  const [bodyPage, setBodyPage] = useState(false);
  const [previousSubmissions, setPreviousSubmissions] = useState(false);
  const [addProgress, setAddProgress] = useState(false);
  const [previewDocument] = useState(false);

  const [submitData, setSubmitData] = useState(null);

  useEffect(() => {
    console.log("We are reaching here");

    if (!user.is_approved) {
      navigate("/confirmsignup", { state: { email: user.email } });
      logout();
    } else if (!user.project) {
      setGetStarted(true);
    } else if (!user.project.is_approved) {
      setSuccessRegistration(true);
    } else {
      setBodyPage(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleGetStarted = () => {
    setGetStarted(false);
    setRegisterProject(true);
  };

  const handleRegisterCancel = () => {
    setGetStarted(true);
    setRegisterProject(false);
    setSuccessRegistration(false);
  };

  const handleSuccessRegistration = () => {
    setRegisterProject(false);
    setSuccessRegistration(true);
  };

  const handleSuccess = () => {
    setSuccessRegistration(false);
    // Add any other logic you need when success is achieved
  };

  const handleSubmitProgress = (data) => {
    setSubmitData(data);
    setPreviousSubmissions(false);
    setBodyPage(false);
    setAddProgress(true);
    // console.log("Submitted Data in Parent:", submitData);
  };

  const handleBackSubmitProgress = () => {
    setPreviousSubmissions(false);
    setBodyPage(true);
  };

  const handleAddProgress = () => {
    setAddProgress(false);
    setBodyPage(true);
  };

  const handleBackAddProgress = () => {
    setAddProgress(false);
    // setPreviousSubmissions(true);
    setBodyPage(true);
  };

  // const handlePreviousSubmission = () => {
  //   setBodyPage(false);
  //   setPreviousSubmissions(true);
  // };

  // //For previewing document: No yet integrated
  // const handlePreviewDocument = () => {
  //   setBodyPage(false);
  //   setPreviousDocument(true);
  // };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <MDBox>
          {getStarted && <GetStarted onClick={handleGetStarted} />}
          {registerProject && (
            <RegisterProject
              onRegister={handleSuccessRegistration}
              onCancel={handleRegisterCancel}
            />
          )}
          {successRegistration && (
            <SuccessRegistration onSuccess={handleSuccess} />
          )}
          {bodyPage && <BodyPage onSubmit={handleSubmitProgress} />}
          {/* {bodyPage && <Abstract />} */}
          {previousSubmissions && (
            <PreviousSubmissions
              onSubmit={handleSubmitProgress}
              onCancel={handleBackSubmitProgress}
            />
          )}
          {addProgress && (
            <AddProgress
              submitData={submitData}
              onSubmit={handleAddProgress}
              onCancel={handleBackAddProgress}
            />
          )}
          {previewDocument && (
            <PreviewDocument
            // onSubmit={handleAddProgress}
            // onCancel={handleBackAddProgress}
            />
          )}
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
