import { useEffect, useState } from "react";

import { FilterAlt, Task } from "@mui/icons-material";
import { Card, CircularProgress, MenuItem } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";

//Data
import progressData from "../data/progressData";
import MDInput from "components/MDInput";
import { API_URL } from "../../../../config";

const AllProgress = () => {
  const { columns, allRows } = progressData();

  const [isFetched, setIsFetched] = useState(false);

  // const [openViewProjectPopup, setOpenViewProjectPopup] = useState(false);
  const [selectedSupervisor, setSelectedSupervisor] = useState("");
  const [selectedAcademicYear, setSelectedAcademicYear] = useState("");
  const [selectedProgramme, setSelectedProgramme] = useState("");
  const [selectedProjectStage, setSelectedProjectStage] = useState("");
  const [selectedProjectProgress, setSelectedProjectProgress] = useState("");

  const [supervisors, setSupervisors] = useState([]);
  const [academicyears, setAcademicYears] = useState([]);
  const [programmes, setProgrammes] = useState([]);
  const [projectStages] = useState(["Concept Paper", "Proposal", "Report"]);
  const [projectProgress] = useState([
    "Less than 11%",
    "Less than 31%",
    "Between 30 and 50%",
    "Above 50%",
    "Above 80%",
  ]);
  const [filteredRows, setFilteredRows] = useState([]);

  // Fetch supervisors, programmes, and academic years from the backend
  useEffect(() => {
    const fetchSupervisors = async () => {
      try {
        const response = await fetch(`${API_URL}/supervisors/getsupervisors`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            // Authorization: `Bearer ${user.token}`,
          },
        });

        const supervisorsjson = await response.json();

        if (response.ok) {
          setSupervisors(supervisorsjson);
          // console.log("Supervisors:", supervisorsjson);
        }
        if (!response.ok) {
          throw new Error("Failed to fetch files");
        }
      } catch (error) {
        console.error("Error fetching files:", error);
      }
    };

    const fetchProgrammes = async () => {
      try {
        const response = await fetch(`${API_URL}/programmes/getprogrammes`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            // Authorization: `Bearer ${user.token}`,
          },
        });

        const programmesjson = await response.json();

        if (response.ok) {
          setProgrammes(programmesjson);
        }
        if (!response.ok) {
          throw new Error("Failed to fetch files");
        }
      } catch (error) {
        console.error("Error fetching files:", error);
      }
    };

    const fetchAcademicYears = async () => {
      try {
        const response = await fetch(`${API_URL}/academicyears/getyears`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            // Authorization: `Bearer ${user.token}`,
          },
        });

        const academicyearsjson = await response.json();

        if (response.ok) {
          setAcademicYears(academicyearsjson);
        }
        if (!response.ok) {
          throw new Error("Failed to fetch files");
        }
      } catch (error) {
        console.error("Error fetching files:", error);
      }
    };

    fetchSupervisors();
    fetchProgrammes();
    fetchAcademicYears();
  }, []);
  // Fetch supervisors, programmes, and academic years from the backend ends here

  // Initial setting of rows
  useEffect(() => {
    const handlePopulate = () => {
      // console.log("Progress Data Rows:", allRows);
      setFilteredRows(allRows);
    };

    if (allRows.length > 0 && !isFetched) {
      handlePopulate();
      setIsFetched(true); // Mark the effect as having run
      // console.log("Fetched:", isFetched);
    }
  }, [allRows, isFetched]);

  //Handling search filter
  useEffect(() => {
    const handleFilter = () => {
      const filtered = allRows.filter((row) => {
        //Filter by supervisor
        const matchesSupervisor = selectedSupervisor
          ? row.supervisor === selectedSupervisor
          : true;

        //Filter by academic year
        const matchesAcademicYear = selectedAcademicYear
          ? row.academicyear === selectedAcademicYear
          : true;

        //Filter by programme
        const matchesProgramme = selectedProgramme
          ? row.programme === selectedProgramme
          : true;

        // Filter by project stage
        const matchesStage = selectedProjectStage
          ? row.stage === selectedProjectStage
          : true;

        // Filter by project progress
        const matchesProgress = selectedProjectProgress
          ? (selectedProjectProgress === "Less than 11%" &&
              row.progressing < 11) ||
            (selectedProjectProgress === "Less than 31%" &&
              row.progressing < 31) ||
            (selectedProjectProgress === "Between 30 and 50%" &&
              row.progressing >= 30 &&
              row.progressing <= 50) ||
            (selectedProjectProgress === "Above 50%" && row.progressing > 50) ||
            (selectedProjectProgress === "Above 80%" && row.progressing > 80)
          : true;

        return (
          matchesSupervisor &&
          matchesAcademicYear &&
          matchesProgramme &&
          matchesStage &&
          matchesProgress
        );
      });
      setFilteredRows(filtered);
    };

    handleFilter();
  }, [
    selectedSupervisor,
    selectedAcademicYear,
    selectedProgramme,
    selectedProjectStage,
    selectedProjectProgress,
  ]);
  // }, [rows1,selectedSupervisor, selectedAcademicYear, selectedProgramme]);

  return (
    <Card>
      <MDBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        px={3}
        pt={3}
      >
        <MDTypography variant="h5" gutterBottom>
          All Progress
        </MDTypography>
      </MDBox>
      <MDBox mx={3} mt={2} px={3}>
        <MDBox display="flex" justifyContent="center" my={1}>
          {/* Filter Label */}
          <MDBox>
            <MDTypography>
              <FilterAlt /> Filter:
            </MDTypography>
          </MDBox>

          {/* Supervisor Filter */}
          <MDBox width="12rem" ml={1}>
            <MDInput
              placeholder="Supervisor"
              label="Supervisor"
              select
              value={selectedSupervisor}
              onChange={(e) => setSelectedSupervisor(e.target.value)}
              size="small"
              fullWidth
              InputProps={{
                style: {
                  height: "35px",
                  padding: "12px",
                },
              }}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {supervisors.map((supervisor) => (
                <MenuItem
                  key={supervisor.id}
                  value={`${supervisor.first_name} ${supervisor.last_name}`}
                >
                  {`${supervisor.first_name} ${supervisor.last_name}`}
                </MenuItem>
              ))}
            </MDInput>{" "}
          </MDBox>

          {/* Academic Year Filter */}
          <MDBox width="12rem" ml={1}>
            <MDInput
              placeholder="Academic Year"
              label="Academic Year"
              select
              value={selectedAcademicYear}
              onChange={(e) => setSelectedAcademicYear(e.target.value)}
              size="small"
              fullWidth
              InputProps={{
                style: {
                  height: "35px",
                  padding: "12px",
                },
              }}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {academicyears.map((academicYear) => (
                <MenuItem key={academicYear.id} value={academicYear.year}>
                  {academicYear.year}
                </MenuItem>
              ))}
            </MDInput>{" "}
          </MDBox>

          {/* Programme Filter */}
          <MDBox width="12rem" ml={1}>
            <MDInput
              placeholder="Programme"
              label="Programme"
              select
              value={selectedProgramme}
              onChange={(e) => setSelectedProgramme(e.target.value)}
              size="small"
              fullWidth
              InputProps={{
                style: {
                  height: "35px",
                  padding: "12px",
                },
              }}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {programmes.map((programme) => (
                <MenuItem key={programme.id} value={programme.programmecode}>
                  {programme.programmecode}
                </MenuItem>
              ))}
            </MDInput>{" "}
          </MDBox>

          {/* Project Stage Filter */}
          <MDBox width="12rem" ml={1}>
            <MDInput
              placeholder="Project Stage"
              label="Project Stage"
              select
              value={selectedProjectStage}
              onChange={(e) => setSelectedProjectStage(e.target.value)}
              size="small"
              fullWidth
              InputProps={{
                style: {
                  height: "35px",
                  padding: "12px",
                },
              }}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {projectStages.map((stage) => (
                <MenuItem key={stage} value={stage}>
                  {stage}
                </MenuItem>
              ))}
            </MDInput>{" "}
          </MDBox>

          {/* Project Progress Filter */}
          <MDBox width="12rem" ml={1}>
            <MDInput
              placeholder="Project Progress"
              label="Project Progress"
              select
              value={selectedProjectProgress}
              onChange={(e) => setSelectedProjectProgress(e.target.value)}
              size="small"
              fullWidth
              InputProps={{
                style: {
                  height: "35px",
                  padding: "12px",
                },
              }}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {projectProgress.map((progress, index) => (
                <MenuItem key={index} value={progress}>
                  {progress}
                </MenuItem>
              ))}
            </MDInput>{" "}
          </MDBox>
        </MDBox>
      </MDBox>
      {isFetched ? (
        <MDBox>
          <DataTable
            table={{ columns, rows: filteredRows }}
            isSorted={false}
            // canSearch={true}
            entriesPerPage={false}
            showTotalEntries={true}
            // noEndBorder
          />
        </MDBox>
      ) : (
        <MDBox
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          height="60vh"
        >
          <CircularProgress color="info" size={100} />
        </MDBox>
      )}
    </Card>
  );
};

export default AllProgress;
