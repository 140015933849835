// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// @mui material components
import Grid from "@mui/material/Grid";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

import React, { useState, useEffect } from "react";

//My components
// import GetStarted from "mycomponents/Student/studentDashboard/studentbody/getStarted";
// import RegisterProject from "mycomponents/Student/studentDashboard/studentbody/registerProject";
// import BodyPage from "mycomponents/Supervisor/supervisorDashboard/bodyPage";
// import SuccessRegistration from "mycomponents/Student/studentDashboard/studentbody/successRegistration";
import Messages from "./messages";
// import ViewDocument from "mycomponents/Supervisor/supervisorDashboard/viewDocument";

function Dashboard() {
  //const [showMessages, setShowMessages] = useState(true);
  //const [documentData, setDocumentData] = useState(null); // State to hold document data

  // const handleToggleComponent = (data) => {
  //   setShowMessages((prevState) => !prevState)
  //   console.log("Hello");
  //   setDocumentData(data); // Update document data
  //   console.log("The document data is", data);
  // }

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={6}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon="leaderboard"
                title="Supervised Students"
                count="23"
                // percentage={{
                //   color: "success",
                //   amount: "+3%",
                //   label: "on schedule",
                // }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="success"
                icon="calendar"
                title="Availability"
                count="🟢 available"
                // percentage={{
                //   // color: "success",
                //   // amount: "+1%",
                //   label: "than yesterday",
                // }}
              />
            </MDBox>
          </Grid>
        </Grid>
        <MDBox mt={3}>
          <Messages />
        </MDBox>
      </MDBox>

      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
