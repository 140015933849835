import { useState } from "react";

import { useAuthContext } from "./useAuthContext";
import { useProjectContext } from "./useProjectContext";
import { useQuickStatsContext } from "context/QuickStatsContext";
import { API_URL } from "../config";

export const useHandleApproveProject = () => {
  const { user } = useAuthContext();
  const { projects, dispatch } = useProjectContext();
  const { fetchQuickStats } = useQuickStatsContext();

  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleapproveproject = async (projectId) => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await fetch(
        `${API_URL}/projects/approveproject/${projectId}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
          body: JSON.stringify({
            is_approved: true,
          }),
        }
      );

      if (!response.ok) {
        const json = await response.json();
        throw new Error(json.error);
      }

      const json = await response.json();
      dispatch({ type: "EDIT_PROJECT", payload: json });
      await fetchQuickStats(); // Refetch quick stats after approving a project
      setSuccess(json.message);
    } catch (error) {
      setError(
        error.message || "An error occurred during registering the project!!"
      );
    } finally {
      setIsLoading(false);
    }
  };

  return {
    handleapproveproject,
    isLoading,
    error,
    success,
    setError,
    setSuccess,
  };
};
