import { useState } from "react";

import { useAuthContext } from "hooks/useAuthContext";

import { AccountCircle, History, MoreVert, Publish } from "@mui/icons-material";
import { Card, Divider, Icon, Menu, MenuItem } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

// Material Dashboard 2 React examples
import DataTable from "examples/Tables/DataTable";

// Data
import data from "../data/proposalpresentationdata";

const ProposalPresentationSection = ({ onClick }) => {
  const { user } = useAuthContext();

  const { columns, rows } = data();

  const handleClick = () => {
    const data = { title: "Proposal Presentation" };
    onClick(data);
  };

  return (
    <MDBox>
      {onClick && (
        <MDBox
          display="flex"
          alignItems="center"
          justifyContent="space-evenly"
          mx={3}
          my={2}
          px={3}
        >
          <MDButton
            variant="gradient"
            color="info"
            size="medium"
            startIcon={<Publish />}
            onClick={handleClick}
          >
            Submit New Progress
          </MDButton>
        </MDBox>
      )}
      <MDBox>
        <DataTable
          table={{ columns, rows }}
          showTotalEntries={false}
          isSorted={false}
          noEndBorder
          entriesPerPage={false}
          // canSearch={true}
        />
      </MDBox>
    </MDBox>
  );
};

export default ProposalPresentationSection;
