import { AcademiYearsContext } from "context/AcademicYearContext";
import { useContext } from "react";

export const useAcademiYearContext = () => {
  const context = useContext(AcademiYearsContext);

  if (!context) {
    throw Error(
      "useAcademiYearContext must be used inside an AcademiYearsContextProvider"
    );
  }

  return context;
};
