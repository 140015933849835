// hooks
import { useState, useEffect } from "react";
import { useSignup } from "hooks/useSignup";

// react-router-dom components
import { Link } from "react-router-dom";
import { CircularProgress, MenuItem } from "@mui/material";

// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Images
import facultyimage from "assets/images/faculty-building.jpg";
import BasicLayout from "../components/BasicLayout";
import { API_URL } from "../../../config";

function Cover() {
  const [selectedProgramme, setSelectedProgramme] = useState(null);
  const [programmes, setProgrammes] = useState([]);
  const [academicYears, setAcademicYears] = useState([]);
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [regNumber, setRegNumber] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [yearOfStudy, setYearOfStudy] = useState("");
  const [academicyear, setAcademicYear] = useState("");

  const { signup, error, isLoading } = useSignup();

  //Fetch programmes from the backend
  useEffect(() => {
    const fetchProgrammes = async () => {
      try {
        const response = await fetch(`${API_URL}/programmes/getprogrammes`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            // Authorization: `Bearer ${user.token}`,
          },
        });

        const programmesjson = await response.json();

        if (response.ok) {
          setProgrammes(programmesjson);
        }
        if (!response.ok) {
          throw new Error("Failed to fetch files");
        }
      } catch (error) {
        console.error("Error fetching files:", error);
      }
    };

    fetchProgrammes();
  }, []);
  // Programmes from Backend ends here

  //Fetch academicyears from the backend
  useEffect(() => {
    const fetchAcademicYears = async () => {
      try {
        const response = await fetch(`${API_URL}/academicyears/getyears`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            // Authorization: `Bearer ${user.token}`,
          },
        });

        const academicyearsjson = await response.json();

        if (response.ok) {
          setAcademicYears(academicyearsjson);
        }
        if (!response.ok) {
          throw new Error("Failed to fetch files");
        }
      } catch (error) {
        console.error("Error fetching files:", error);
      }
    };

    fetchAcademicYears();
  }, []);
  // Academic Years from Backend ends here

  const handleSubmit = async (e) => {
    e.preventDefault();
    const selectedProgrammeCode = selectedProgramme
      ? programmes.find(
          (programme) => programme.programmename === selectedProgramme
        ).programmecode
      : "";

    await signup(
      firstname,
      lastname,
      email,
      selectedProgrammeCode,
      academicyear,
      password,
      confirmPassword,
      regNumber,
      phoneNumber,
      yearOfStudy
    );
  };

  // Generate years of study based on selected programme duration
  const generateYearsOfStudy = (duration) => {
    return Array.from({ length: duration }, (_, i) => duration - i);
  };

  return (
    <BasicLayout image={facultyimage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Start your Research Journey Today
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            Register Here
          </MDTypography>
        </MDBox>
        <MDBox pb={3} px={3}>
          <MDBox component="form" role="form" onSubmit={handleSubmit}>
            <MDBox mb={2} display="flex" justifyContent="space-between">
              <MDBox mr={1} flex={1}>
                <MDInput
                  type="text"
                  label="First Name"
                  value={firstname}
                  onChange={(e) => setFirstname(e.target.value)}
                  variant="standard"
                  fullWidth
                />
              </MDBox>
              <MDBox ml={1} flex={1}>
                <MDInput
                  type="text"
                  label="Last Name"
                  value={lastname}
                  onChange={(e) => setLastname(e.target.value)}
                  variant="standard"
                  fullWidth
                />
              </MDBox>
            </MDBox>
            <MDBox mb={2} display="flex" justifyContent="space-between">
              <MDBox mr={1} flex={1}>
                <MDInput
                  type="email"
                  label="Student Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  variant="standard"
                  fullWidth
                />
              </MDBox>
              <MDBox ml={1} flex={1}>
                <MDInput
                  type="text"
                  label="Registration Number"
                  value={regNumber}
                  onChange={(e) => setRegNumber(e.target.value)}
                  variant="standard"
                  fullWidth
                />
              </MDBox>
            </MDBox>
            <MDBox mb={2} display="flex" justifyContent="space-between">
              <MDBox mr={1} flex={1}>
                <MDInput
                  type="password"
                  label="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  variant="standard"
                  fullWidth
                />
              </MDBox>
              <MDBox ml={1} flex={1}>
                <MDInput
                  type="password"
                  label="Confirm Password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  variant="standard"
                  fullWidth
                />
              </MDBox>
            </MDBox>
            {/* Programme */}
            <MDBox mb={2}>
              <MDInput
                variant="standard"
                label="Select Programme"
                select
                value={selectedProgramme}
                onChange={(e) => setSelectedProgramme(e.target.value)}
                fullWidth
                mb={3}
                sx={{
                  width: "100%",
                  "& .MuiSelect-select": {
                    height: "55px", // Set the height of the select field
                  },
                }} // Set the width of the MDInput
                SelectProps={{
                  MenuProps: { PaperProps: { style: { maxHeight: 150 } } },
                }} // Set max height for the dropdown menu
              >
                {programmes.map((programme) => (
                  <MenuItem key={programme.id} value={programme.programmename}>
                    {programme.programmename}
                  </MenuItem>
                ))}
              </MDInput>
            </MDBox>
            <MDBox mb={2} display="flex" justifyContent="space-between">
              {/* Academic Year */}
              <MDBox mr={1} flex={1} mt={1}>
                <MDInput
                  variant="standard"
                  label="Academic Year"
                  select
                  value={academicyear}
                  onChange={(e) => setAcademicYear(e.target.value)}
                  fullWidth
                >
                  {academicYears.map((academicYear) => (
                    <MenuItem key={academicYear.id} value={academicYear.year}>
                      {academicYear.year}
                    </MenuItem>
                  ))}
                </MDInput>
              </MDBox>
              {/* Year of Study */}
              <MDBox ml={1} flex={1} mt={1}>
                <MDInput
                  variant="standard"
                  label="Year of Study"
                  select
                  value={yearOfStudy}
                  onChange={(e) => setYearOfStudy(e.target.value)}
                  fullWidth
                >
                  {selectedProgramme &&
                    generateYearsOfStudy(
                      programmes.find(
                        (programme) =>
                          programme.programmename === selectedProgramme
                      )?.programmeduration || 0
                    ).map((year) => (
                      <MenuItem key={year} value={year}>
                        {`Year ${year}`}
                      </MenuItem>
                    ))}
                </MDInput>
              </MDBox>
            </MDBox>
            <MDBox mb={2}>
              <MDBox mr={1} flex={1}>
                <MDInput
                  type="text"
                  label="Phone Number"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  variant="standard"
                  fullWidth
                />
              </MDBox>
            </MDBox>
            <MDBox display="flex" alignItems="center" ml={-1}>
              <Checkbox />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;I agree the&nbsp;
              </MDTypography>
              <MDTypography
                component="a"
                href="#"
                variant="button"
                fontWeight="bold"
                color="info"
                textGradient
              >
                Terms and Conditions
              </MDTypography>
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton
                type="submit"
                variant="gradient"
                color="info"
                fullWidth
                disabled={isLoading}
              >
                {isLoading && (
                  <CircularProgress size={24} color="inherit" sx={{ mr: 1 }} />
                )}
                sign up
              </MDButton>
            </MDBox>
            <MDBox mt={3} textAlign="center">
              <MDTypography variant="button" color="text">
                Already have an account?{" "}
                <MDTypography
                  component={Link}
                  to="/signin"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Sign In
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
        <MDBox textAlign="center">
          {error && (
            <MDBox
              borderRadius="4px"
              px={3}
              mx={2}
              mb={2}
              style={{
                border: "1px solid #f44335", // Error redish color for box border
                backgroundColor: "rgba(244, 67, 53, 0.2)", // Lighter transparent redish color for box background
              }}
            >
              <MDTypography color="error">{error}</MDTypography>
            </MDBox>
          )}
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Cover;
