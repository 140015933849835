import { useState } from "react";
import { useAuthContext } from "./useAuthContext";
import { API_URL } from "../config";

export const useLogin = () => {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false); // Initialize isLoading to false
  const { dispatch } = useAuthContext();

  const validateEmail = (email) => {
    const regex = /^[a-zA-Z0-9._%+-]+@(?:std\.)?must\.ac\.ug$/;
    if (!regex.test(email)) {
      setError("Please enter a valid email address");
      return false;
    } else {
      setError(null);
      return true;
    }
  };

  const login = async (email, password) => {
    if (!validateEmail(email)) {
      return; // Exit early if email validation fails
    }

    setIsLoading(true);
    setError(null);

    let url = "";
    if (email.endsWith("@std.must.ac.ug")) {
      url = `${API_URL}/students/login`;
    } else if (email.endsWith("@must.ac.ug")) {
      url = `${API_URL}/supervisors/login`;
    } else {
      setError("Invalid email");
      setIsLoading(false);
      return;
    }

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email, password }),
      });

      if (!response.ok) {
        const json = await response.json();
        throw new Error(json.error);
      }

      const json = await response.json();

      // save the user to local storage
      localStorage.setItem("user", JSON.stringify(json));

      // update the auth context
      dispatch({ type: "LOGIN", payload: json });
    } catch (error) {
      setError(error.message || "An error occurred during login");
    } finally {
      setIsLoading(false);
    }
  };

  return { login, isLoading, error };
};
