import { useState, useEffect } from "react";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import MUSTlogo from "../../../assets/images/logos/must.png";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Custom styles for LandingpageNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
} from "examples/Navbars/DashboardNavbar/styles";

// Material Dashboard 2 React context
import { useMaterialUIController, setTransparentNavbar } from "context";
import MDTypography from "components/MDTypography";

function LandingpageNavbar({ absolute, light, isMini }) {
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const { transparentNavbar, fixedNavbar, darkMode } = controller;

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(
        dispatch,
        (fixedNavbar && window.scrollY === 0) || !fixedNavbar
      );
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  //Ends here

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) => ({
        ...navbar(theme, { transparentNavbar, absolute, light, darkMode }),
        overflowX: "hidden", // Ensure no horizontal overflow
      })}
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <MDBox
          color="inherit"
          mb={{ xs: 1, md: 0 }}
          sx={(theme) => ({
            ...navbarRow(theme, { isMini }),
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexDirection: { xs: "column", md: "row" }, // Set flex direction based on screen size
          })}
        >
          {isMini ? null : (
            <>
              <MDBox
                component="img"
                src={MUSTlogo}
                alt="master card"
                width={{ xs: "30%", md: "3%" }} // Adjusted width based on screen size
              />
              <MDBox
                borderRadius="lg"
                // shadow="lg"
                opacity={1}
                // px={{ xs: 5, sm: 5, md: 5, lg: 5, xl: 5 }} // Adjusted padding for different screen sizes
                ml={2}
                mr={2}
                // py={{ xs: 1, sm: 1, md: 1, lg: 1, xl: 1 }} // Adjusted padding for different screen sizes
                fontSize={{ xs: "sm", sm: "md", md: "md", lg: "lg", xl: "xl" }} // Adjusted font size for different screen sizes
              >
                <MDTypography>
                  <strong>
                    FACULTY OF APPLIED SCIENCES AND TECHNOLOGY - (FAST)
                  </strong>
                </MDTypography>
              </MDBox>
            </>
          )}
        </MDBox>
      </Toolbar>
    </AppBar>
  );
}

// Setting default values for the props of LandingpageNavbar
LandingpageNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the LandingpageNavbar
LandingpageNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default LandingpageNavbar;
