import React, { useEffect, useState } from "react";
import MDBox from "components/MDBox";
import { Divider, IconButton, Typography } from "@mui/material";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useAuthContext } from "hooks/useAuthContext";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { API_URL } from "../../../../../config";

const CommentSection = ({ closeComments, document }) => {
  const [sections, setSections] = useState([]);
  const { user } = useAuthContext();

  useEffect(() => {
    const fetchComments = async () => {
      try {
        const response = await fetch(
          `${API_URL}/files/retrievecomments/${document.id}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const commentsdata = await response.json();
        if (response.ok) {
          setSections(commentsdata);
        }
      } catch (error) {
        console.error("Error fetching comments:", error);
      }
    };

    fetchComments();
  }, [document.id]);

  // console.log("Sections:", sections);

  return (
    <MDBox
      height={450}
      borderRadius="lg"
      boxShadow={1}
      opacity={1}
      shadow="lg"
      p={2}
      overflow="auto"
      position="relative"
    >
      <MDBox
        display="flex"
        alignItems="center"
        justifyContent="center"
        position="sticky"
        top={0}
        zIndex={1}
        // p={1}
        borderRadius="lg"
        shadow="xs"
      >
        <MDButton
          variant="contained"
          color="error"
          sx={{ mb: 1 }}
          onClick={closeComments}
          startIcon={<VisibilityOff />}
        >
          close
        </MDButton>
      </MDBox>

      {sections.map((section, index) => (
        <MDBox
          key={index}
          // mb={2}
          px={2}
          pt={2}
          borderRadius="lg"
          boxShadow={2}
          borderColor="primary.main"
          display="flex"
          flexDirection="column"
        >
          <MDTypography variant="h6" gutterBottom>
            {section.field}
          </MDTypography>
          <MDTypography variant="h6" gutterBottom>
            Focus Area:
          </MDTypography>
          <MDTypography
            variant="body2"
            gutterBottom
            style={{
              maxHeight: "150px", // Adjust height as needed
              overflowY: "auto",
              padding: "2px", // Optional: Add padding for better appearance
              border: "1px solid #ccc", // Optional: Add border for distinction
              borderRadius: "4px", // Optional: Add border radius for rounded corners
            }}
          >
            {section.line}
          </MDTypography>
          <MDTypography variant="h6" fontWeight="bold" mr={1}>
            Comment:
          </MDTypography>
          <MDTypography
            variant="button"
            gutterBottom
            style={{
              maxHeight: "150px", // Adjust height as needed
              overflowY: "auto",
              padding: "2px", // Optional: Add padding for better appearance
              border: "2px solid #bbb", // Optional: Add border for distinction
              borderRadius: "4px", // Optional: Add border radius for rounded corners
            }}
          >
            {section.comment}
          </MDTypography>
          <MDTypography variant="caption" color="secondary">
            {new Date(section.createdAt).toLocaleString()}
          </MDTypography>
          <Divider variant="middle" flexItem />
        </MDBox>
      ))}
    </MDBox>
  );
};

export default CommentSection;
