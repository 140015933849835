//Hooks
import { useEffect, useState } from "react";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import ConceptPaper from "./deliverable/conceptPaper";
import Proposal from "./deliverable/proposal";
import Report from "./deliverable/report";
import { useAuthContext } from "hooks/useAuthContext";
import CongratulationsAnimation from "./components/congratulations";

// Dashboard components
// import Projects from "layouts/studentLayouts/dashboard/components/Projects";
// import OrdersOverview from "layouts/studentLayouts/dashboard/components/OrdersOverview";
// import ProfileSection from "../../../../mycomponents/Student/studentDashboard/studentbody/profileSection";
// import RegistrationSection from "../../../../mycomponents/Student/studentDashboard/studentbody/registrationSection";

const BodyPage = ({ onSubmit }) => {
  const { user } = useAuthContext();
  const [showAnimation, setShowAnimation] = useState(false);

  const handleCompleteStage = () => {
    // Logic to handle stage completion
    setShowAnimation(true);
  };

  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    if (user.project.stage === "Concept Paper") {
      setActiveTab(0);
    } else if (user.project.stage === "Proposal") {
      setActiveTab(1);
    } else {
      setActiveTab(2);
    }
  }, [user]);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    // const tabNames = ["Concept Paper", "Proposal", "Report"];
    // console.log(`Switched to tab: ${tabNames[newValue]}`);
  };
  return (
    <MDBox>
      {/* Welcome [name] */}
      <MDBox mx={3} mb={3}>
        <MDTypography variant="h3">Welcome {user.last_name}!!!</MDTypography>
      </MDBox>

      {/* Nav Tabs Here */}
      <Container>
        <Grid container item justifyContent="center" xs={12} lg={12} mx="auto">
          <AppBar position="static">
            <Tabs
              value={activeTab}
              onChange={handleTabChange}
              // textColor="info"
              sx={{ backgroundColor: "grey" }}
              // textColor="#e91e63"
              // indicatorColor="primary"
            >
              <Tab label="Concept Paper" sx={{ fontWeight: "bold" }} />
              <Tab label="Proposal" sx={{ fontWeight: "bold" }} />
              <Tab label="Final Report" sx={{ fontWeight: "bold" }} />
            </Tabs>
          </AppBar>
        </Grid>
      </Container>
      {/* Nav Tabs end Here */}

      <MDBox mb={2} />
      {/* <button onClick={handleCompleteStage}>Complete Stage</button> */}

      {/* {showAnimation && <CongratulationsAnimation />} */}
      {activeTab === 0 && <ConceptPaper onSubmit={onSubmit} />}
      {activeTab === 1 && <Proposal onSubmit={onSubmit} />}
      {activeTab === 2 && <Report onSubmit={onSubmit} />}
    </MDBox>
  );
};

export default BodyPage;
