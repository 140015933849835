import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Grid from "@mui/material/Grid";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import { MenuItem } from "@mui/material";
import MDTypography from "components/MDTypography";
import { useAddStudent } from "hooks/useAddStudent";
import { PersonAdd } from "@mui/icons-material";
import { API_URL } from "../../../../config";

// { open, handleClose }
const AddStudentPopup = () => {
  const [open, setOpen] = useState(false);
  const [selectedProgramme, setSelectedProgramme] = useState(null);
  const [programmes, setProgrammes] = useState([]);
  const [academicYears, setAcademicYears] = useState([]);
  const [academicyear, setAcademicYear] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [regNumber, setRegNumber] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [yearOfStudy, setYearOfStudy] = useState("");
  const { addstudent, error, isLoading, success, setError, setSuccess } =
    useAddStudent();

  //Fetch programmes and academic years from the backend
  useEffect(() => {
    const fetchProgrammes = async () => {
      try {
        const response = await fetch(`${API_URL}/programmes/getprogrammes`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            // Authorization: `Bearer ${user.token}`,
          },
        });

        const programmesjson = await response.json();

        if (response.ok) {
          setProgrammes(programmesjson);
        }
        if (!response.ok) {
          throw new Error("Failed to fetch files");
        }
      } catch (error) {
        console.error("Error fetching files:", error);
      }
    };

    const fetchAcademicYears = async () => {
      try {
        const response = await fetch(`${API_URL}/academicyears/getyears`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            // Authorization: `Bearer ${user.token}`,
          },
        });

        const academicyearsjson = await response.json();

        if (response.ok) {
          setAcademicYears(academicyearsjson);
        }
        if (!response.ok) {
          throw new Error("Failed to fetch files");
        }
      } catch (error) {
        console.error("Error fetching files:", error);
      }
    };

    fetchAcademicYears();
    fetchProgrammes();
  }, []);
  // Programmes and Academic Years from Backend ends here

  const handleSubmit = async (e) => {
    e.preventDefault();
    const selectedProgrammeCode = selectedProgramme
      ? programmes.find(
          (programme) => programme.programmename === selectedProgramme
        ).programmecode
      : "";

    await addstudent(
      firstname,
      lastname,
      email,
      selectedProgrammeCode,
      academicyear,
      password,
      confirmPassword,
      regNumber,
      phoneNumber,
      yearOfStudy
    );
    // navigate("/confirmsignup");
    // console.log(firstname, lastname, email, selectedProgrammeName, password, confirmPassword, regNumber, phoneNumber, yearOfStudy)
  };

  // Generate years of study based on selected programme duration
  const generateYearsOfStudy = (duration) => {
    // console.log("Selected Prog:", selectedProgramme);
    // console.log(
    //   "Years:",
    //   Array.from({ length: duration }, (_, i) => duration - i)
    // );
    return Array.from({ length: duration }, (_, i) => duration - i);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    //clear all fields
    setSelectedProgramme(null);
    setFirstname("");
    setLastname("");
    setEmail("");
    setPassword("");
    setConfirmPassword("");
    setRegNumber("");
    setPhoneNumber("");
    setYearOfStudy("");
    // Clear error on close
    setError(null);
    setSuccess(null);
  };

  return (
    <div>
      <MDButton
        variant="gradient"
        color="info"
        size="small"
        startIcon={<PersonAdd />}
        onClick={handleClickOpen}
      >
        Add Student
      </MDButton>
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>Add Student</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <MDInput
                fullWidth
                label="First Name"
                name="firstName"
                variant="standard"
                value={firstname}
                onChange={(e) => setFirstname(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <MDInput
                fullWidth
                label="Last Name"
                name="lastName"
                variant="standard"
                value={lastname}
                onChange={(e) => setLastname(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <MDInput
                fullWidth
                label="Student Email"
                name="email"
                variant="standard"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <MDInput
                fullWidth
                label="Registration Number"
                name="regNumber"
                variant="standard"
                value={regNumber}
                onChange={(e) => setRegNumber(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <MDInput
                fullWidth
                label="Password"
                name="password"
                type="password"
                variant="standard"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <MDInput
                fullWidth
                label="Confirm Password"
                name="confirmPassword"
                type="password"
                variant="standard"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <MDInput
                fullWidth
                label="Select Programme"
                select
                name="programme"
                variant="standard"
                value={selectedProgramme}
                onChange={(e) => setSelectedProgramme(e.target.value)}
              >
                {programmes.map((programme) => (
                  <MenuItem key={programme.id} value={programme.programmename}>
                    {programme.programmename}
                  </MenuItem>
                ))}
              </MDInput>
            </Grid>
            <Grid item xs={6} mt={1}>
              <MDInput
                variant="standard"
                label="Academic Year"
                select
                value={academicyear}
                onChange={(e) => setAcademicYear(e.target.value)}
                fullWidth
              >
                {academicYears.map((academicYear) => (
                  <MenuItem key={academicYear.id} value={academicYear.year}>
                    {academicYear.year}
                  </MenuItem>
                ))}
              </MDInput>
            </Grid>
            <Grid item xs={6} mt={1}>
              <MDInput
                fullWidth
                label="Year of Study"
                select
                name="yearOfStudy"
                variant="standard"
                value={yearOfStudy}
                onChange={(e) => setYearOfStudy(e.target.value)}
              >
                {selectedProgramme &&
                  generateYearsOfStudy(
                    programmes.find(
                      (programme) =>
                        programme.programmename === selectedProgramme
                    )?.programmeduration || 0
                  ).map((year) => (
                    <MenuItem key={year} value={year}>
                      {`Year ${year}`}
                    </MenuItem>
                  ))}
              </MDInput>
            </Grid>
            <Grid item xs={12}>
              <MDInput
                fullWidth
                label="Phone Number"
                name="phoneNumber"
                variant="standard"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <MDButton onClick={handleClose}>Cancel</MDButton>
          <MDButton
            onClick={handleSubmit}
            variant="contained"
            color="primary"
            disabled={isLoading}
          >
            Submit
          </MDButton>
        </DialogActions>
        {error && (
          <MDBox
            borderRadius="4px"
            px={3}
            mx={2}
            mb={2}
            style={{
              textAlign: "center",
              border: "1px solid #f44335", // Error redish color for box border
              backgroundColor: "rgba(244, 67, 53, 0.2)", // Lighter transparent redish color for box background
            }}
          >
            <MDTypography color="error">{error}</MDTypography>
          </MDBox>
        )}
        {success && (
          <MDBox
            borderRadius="4px"
            px={3}
            mx={2}
            mb={2}
            style={{
              textAlign: "center",
              border: "1px solid #4caf50",
              backgroundColor: "rgba(76, 175, 80, 0.2)",
            }}
          >
            <MDTypography color="success">{success}</MDTypography>
          </MDBox>
        )}
      </Dialog>
    </div>
  );
};

export default AddStudentPopup;
