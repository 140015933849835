// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MDBox from "components/MDBox";

// Material Kit 2 React examples
// import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
// import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";
// import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";

import project1 from "assets/images/project-images/AI Micro Chip.jpg";
import project3 from "assets/images/project-images/Video Upscaler.webp";
import project2 from "assets/images/project-images/Construction.jpg";
import SimpleBlogCard from "examples/Cards/BlogCards/SimpleBlogCard";
// import TransparentBlogCard from "examples/Cards/BlogCards/TransparentBlogCard";
// import BackgroundBlogCard from "examples/Cards/BlogCards/BackgroundBlogCard";
// import DefaultCounterCard from "examples/Cards/CounterCards/DefaultCounterCard";
// import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
// import HorizontalTeamCard from "examples/Cards/TeamCards/HorizontalTeamCard";

// import team1 from "assets/images/team-1.jpg";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";

const projects = [
  {
    image: project1,
    title: "Research Tracker",
    description:
      "This project aims a building a comprehensive research tracker",
    authors: ["John Doe"],
    startDate: "2021-02-01",
    lastUpdated: "2021-05-15",
    deliverables: [
      { title: "Concept Paper", type: "PDF Document" },
      { title: "Proposal", type: "PDF Document" },
      { title: "Report", type: "PDF Document" },
      { title: "Abstract", type: "Word Document" },
    ],
  },
  {
    image: project2,
    title: "Auto Constructor",
    description:
      "An AI driven auto motors for helping in building and contruction of skyscrappers",
    authors: ["Jason Bourne", "James Bond"],
    startDate: "2022-03-01",
    lastUpdated: "2022-06-11",
    deliverables: [
      { title: "Concept Paper", type: "PDF Document" },
      { title: "Proposal", type: "PDF Document" },
      { title: "Report", type: "PDF Document" },
      { title: "Abstract", type: "Word Document" },
    ],
  },
  {
    image: project3,
    title: "Video Upscaler for resource constrained environments",
    description:
      "An AI driven system for upscaling low resolution videos to high resolution lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla tristique malesuada sapien, eget placerat augue mollis quis. Quisque et vulputate ex, at faucibus mi. Ut iaculis enim eu justo ultrices mattis. Nulla ultricies rhoncus convallis. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Ut ornare eros ut nisi luctus, sit amet accumsan urna varius. Duis condimentum sollicitudin justo sit amet tempor. Nunc cursus cursus efficitur. Interdum et malesuada fames ac ante ipsum primis in faucibus. Pellentesque elementum enim eu sapien aliquam vehicula. Curabitur leo arcu, eleifend a quam a, accumsan ornare elit. Nam in lorem vestibulum, ultricies tortor sit amet, interdum tortor. Ut vitae rutrum purus, eget accumsan dui.",
    authors: ["Johnny English", "Hello"],
    startDate: "2023-01-01",
    lastUpdated: "2023-02-15",
    deliverables: [
      { title: "Concept Paper", type: "PDF Document" },
      { title: "Proposal", type: "PDF Document" },
      { title: "Report", type: "PDF Document" },
      { title: "Abstract", type: "Word Document" },
    ],
  },
];

function Projects() {
  return (
    <MDBox component="section" py={2}>
      <Container maxWidth="lg">
        <Grid
          container
          spacing={3}
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
        >
          <Grid item xs={12} lg={6}>
            <MDBadge
              badgeContent="projects"
              variant="contained"
              color="info"
              container
            />
            <MDTypography variant="h2" mt={2}>
              Explore some of the best Projects
            </MDTypography>
            <MDTypography
              variant="body2"
              align="center"
              color="text"
              gutterBottom
            >
              {/* If you can't decide, the answer is no. If two equally difficult
              paths, choose the one more painful in the short term (pain
              avoidance is creating an illusion of equality). */}
              Learn from the best and smoothly complete your research
            </MDTypography>
          </Grid>
          <Grid
            container
            spacing={3}
            alignItems="center"
            justifyContent="center"
            mt={4}
          >
            {/* Projects */}
            {projects.map((project, index) => (
              <Grid
                key={index}
                item
                xs={12}
                md={6}
                lg={4}
                sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}
                mb={4}
                alignItems="center"
                justifyContent="center"
              >
                <SimpleBlogCard
                  item={project}
                  image={project.image}
                  title={project.title}
                  description={project.description}
                  action={{
                    type: "internal",
                    route: "/viewproject",
                    color: "info",
                    variant: "outlined",
                    label: "view project",
                  }}
                  // authors={[
                  //   // { image: team3, name: "Nick Daniel" },
                  //   // { image: team4, name: "Peterson" },
                  //   { image: team1, name: "Elena Morison" },
                  //   // { image: team2, name: "Ryan Milly" },
                  // ]}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Container>
    </MDBox>
  );
}

export default Projects;
