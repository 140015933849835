import React, { useState, useEffect } from "react";
import { useAuthContext } from "hooks/useAuthContext";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import { Visibility } from "@mui/icons-material";
import MDButton from "components/MDButton";
import MDProgress from "components/MDProgress";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../../../../config";

export default function useData() {
  const { user } = useAuthContext();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true); // Add loading state

  const [projects, setProjects] = useState([]);

  //Fetch Projects and Supervisor from the backend
  useEffect(() => {
    // Fetch Projects
    const fetchProjects = async () => {
      try {
        const response = await fetch(`${API_URL}/projects/getprojects`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        });

        const projectsjson = await response.json();

        if (response.ok) {
          setProjects(projectsjson);
          // console.log("Projects:", projectsjson);
        }
        if (!response.ok) {
          throw new Error("Failed to fetch files");
        }
      } catch (error) {
        console.error("Error fetching files:", error);
      } finally {
        setLoading(false);
      }
    };

    if (user) {
      fetchProjects();
    }
  }, [user]);
  // Projects from Backend ends here

  //FUNCTIONS HERE
  // Function to format the date
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
    const formattedTime = date.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      // second: "2-digit",
      hour12: true,
    });
    return `${formattedDate} ${formattedTime}`;
  };

  const handleProjectClick = (item) => {
    // console.log("View Project:", item);
    navigate("/view-project", { state: { project: item } });
  };

  if (loading) {
    return {
      columns: [],
      rows1: [], // or handle loading state differently if you have a loading component
      allColumns: [],
      allRows: [],
    };
  }

  const allColumns = [
    { Header: "student", accessor: "student", align: "center" },
    { Header: "project", accessor: "project", align: "center" },
    { Header: "supervisor", accessor: "supervisor", align: "center" },
    { Header: "progress", accessor: "progress", align: "center" },
    { Header: "last updated", accessor: "date", align: "center" },
    { Header: "action", accessor: "action", align: "center" },
    { Header: "academic year", accessor: "academicyear", align: "center" }, // Additional Columns start here
    { Header: "year of study", accessor: "yearofstudy", align: "center" },
    { Header: "stage", accessor: "stage", align: "center" },
    { Header: "programme", accessor: "programme", align: "center" },
    { Header: "progressing", accessor: "progressing", align: "center" },
  ];

  const allRows = projects
    ? projects.map((item) => ({
        student: `${item.Student.first_name} ${item.Student.last_name}`,
        project: item.projectTitle,
        supervisor: `${item.Supervisor.first_name} ${item.Supervisor.last_name}`,
        progress: (
          <MDBox width="8rem" textAlign="right">
            <MDProgress
              value={item.progress}
              color={
                item.progress < 33
                  ? "error"
                  : item.progress < 66
                  ? "warning"
                  : item.progress > 80
                  ? "success"
                  : "info"
              }
              variant="gradient"
              // label={false}
              label
            />
          </MDBox>
        ),
        date: formatDate(item.updatedAt),
        action: (
          <MDButton
            variant="outlined"
            color="secondary"
            size="small"
            startIcon={<Visibility />}
            onClick={() => handleProjectClick(item)}
          >
            View
          </MDButton>
        ),
        academicyear: item.Student.academicyear, // Additional rows start here
        yearofstudy: item.Student.yearOfStudy,
        stage: item.stage,
        programme: item.Student.programme,
        progressing: item.progress,
      }))
    : [];

  const displayedColumns = [
    { Header: "student", accessor: "student", align: "center" },
    { Header: "project", accessor: "project", align: "center" },
    { Header: "supervisor", accessor: "supervisor", align: "center" },
    { Header: "progress", accessor: "progress", align: "center" },
    { Header: "Last Updated", accessor: "date", align: "center" },
    { Header: "action", accessor: "action", align: "center" },
  ];

  return {
    columns: displayedColumns,
    // rows1: displayedRows,
    allColumns,
    allRows,
  };
}
