// CongratulationsAnimation.js
import React from "react";
import Lottie from "react-lottie";
import * as animationData from "../../../../../animations/congratulations.json"; // Make sure to place your downloaded JSON file in the same directory

const CongratulationsAnimation = () => {
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div style={{ textAlign: "center" }}>
      <Lottie options={defaultOptions} height={500} width={500} />
      <h2>Congratulations!</h2>
    </div>
  );
};

export default CongratulationsAnimation;
