// Material Dashboard 2 React components
import MDButton from "components/MDButton";

// Images
import { Visibility } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

export default function useData() {
  const navigate = useNavigate();

  const handleProjectClick = (item) => {
    // console.log("View Project:", item);
    navigate("/view-project", { state: { project: item } });
  };

  return {
    columns: [
      { Header: "Title", accessor: "title", align: "center" },
      { Header: "Authors", accessor: "authors", align: "center" },
      { Header: "Last Updated", accessor: "lastupdated", align: "center" },
      { Header: "action", accessor: "action", align: "center" },
    ],

    rows: [
      {
        title: "The Best Project",
        authors: "John Michael",
        lastupdated: "23/04/18",
        action: (
          <MDButton
            variant="outlined"
            color="secondary"
            size="small"
            startIcon={<Visibility />}
          >
            View
          </MDButton>
        ),
      },
      {
        title: "AI Research",
        authors: "Jane Doe",
        lastupdated: "15/06/21",
        action: (
          <MDButton
            variant="outlined"
            color="secondary"
            size="small"
            startIcon={<Visibility />}
          >
            View
          </MDButton>
        ),
      },
      {
        title: "Blockchain Innovations",
        authors: "Alice Smith",
        lastupdated: "09/11/22",
        action: (
          <MDButton
            variant="outlined"
            color="secondary"
            size="small"
            startIcon={<Visibility />}
          >
            View
          </MDButton>
        ),
      },
      {
        title: "Quantum Computing",
        authors: "Bob Johnson",
        lastupdated: "28/02/23",
        action: (
          <MDButton
            variant="outlined"
            color="secondary"
            size="small"
            startIcon={<Visibility />}
          >
            View
          </MDButton>
        ),
      },
      {
        title: "Green Energy Solutions",
        authors: "Emily Davis",
        lastupdated: "01/08/23",
        action: (
          <MDButton
            variant="outlined"
            color="secondary"
            size="small"
            startIcon={<Visibility />}
          >
            View
          </MDButton>
        ),
      },
    ],
  };
}
