import { useState } from "react";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

//My Components
import AcademicYear from "./tables/academicYearTable";
import Programme from "./tables/programmeTable";
import { Card } from "@mui/material";
import MDTypography from "components/MDTypography";

function Settings() {
  // const [activeTab, setActiveTab] = useState(0);

  // const handleTabType = (event, newValue) => setActiveTab(newValue);
  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox
        color="white"
        bgColor="info"
        variant="gradient"
        borderRadius="lg"
        shadow="lg"
        opacity={1}
        p={2}
      >
        <MDTypography fontWeight="bold" color="inherit" variant="h4">
          Programmes and Academic Years
        </MDTypography>
      </MDBox>
      {/* Nav Tabs Here */}
      {/* <Container>
        <Grid container item justifyContent="center" xs={12} lg={12} mx="auto">
          <AppBar position="static">
            <Tabs value={activeTab} onChange={handleTabType}>
              <Tab label="Academic Year" />
              <Tab label="Programmes" />
              <Tab label="Students" />
              <Tab label="Supervisors" />
            </Tabs>
          </AppBar>
        </Grid>
      </Container> */}
      {/* Nav Tabs end Here */}

      <MDBox mb={2} />
      <Grid container spacing={2}>
        <Grid item xs={12} md={8} lg={8}>
          <Programme />
        </Grid>
        <Grid item xs={12} md={4} lg={4}>
          <AcademicYear />
        </Grid>
      </Grid>
      <MDBox mb={2} />

      <Footer />
    </DashboardLayout>
  );
}

export default Settings;
