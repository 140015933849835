import { useState } from "react";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import AllSupervisors from "./tables/allSupervisorsTable";
import AvailableSupervisors from "./tables/availableSupervisorsTable";
import UnAvailableSupervisors from "./tables/unavailableSupervisorsTable";
import ApprovalsTable from "./tables/approvalsTable";

//My Components
// import AllStudents from "./tables/allSupervisorsTable";
// import ApprovedStudents from "./tables/availableSupervisorsTable";
// import PendingStudents from "./tables/SupervisorsTable";

function Supervisors() {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabType = (event, newValue) => {
    setActiveTab(newValue);
    const tabNames = ["Academic Year", "Programmes", "Students", "Supervisors"];
    console.log(`Switched to tab: ${tabNames[newValue]}`);
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />

      {/* Nav Tabs Here */}
      <Container>
        <Grid container item justifyContent="center" xs={12} lg={12} mx="auto">
          <AppBar position="static">
            <Tabs value={activeTab} onChange={handleTabType}>
              <Tab label="All Supervisors" sx={{ fontWeight: "bold" }} />
              <Tab label="Available" sx={{ fontWeight: "bold" }} />
              <Tab label="Unavailable" sx={{ fontWeight: "bold" }} />
              {/* <Tab label="Supervisors" /> */}
            </Tabs>
          </AppBar>
        </Grid>
      </Container>
      {/* Nav Tabs end Here */}

      <MDBox mb={2} />
      {activeTab === 0 && <AllSupervisors />}
      {activeTab === 1 && <AvailableSupervisors />}
      {activeTab === 2 && <UnAvailableSupervisors />}
      {/* {activeTab === 2 && <ApprovalsTable />} */}
      <MDBox mb={2} />

      <Footer />
    </DashboardLayout>
  );
}

export default Supervisors;
