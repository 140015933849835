import React, { useState, useEffect } from "react";
import { useAuthContext } from "hooks/useAuthContext";
import { useAcademiYearContext } from "hooks/useAcademicYearContext";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Icons
import { Delete, Edit, IndeterminateCheckBox } from "@mui/icons-material";
import { Button } from "@mui/material";

import EditAcademicYearPopup from "../components/editAcademicYearPopup";
import DeleteAcademicYearPopup from "../components/deleteAcademicYearPopup";
import { API_URL } from "../../../../config";

export default function useData() {
  const { user } = useAuthContext();
  const { academicyears, dispatch } = useAcademiYearContext();
  const [loading, setLoading] = useState(true); // Add loading state

  //Fetch academicyears from the backend
  useEffect(() => {
    const fetchAcademicYears = async () => {
      try {
        const response = await fetch(`${API_URL}/academicyears/getyears`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        });

        const academicyearsjson = await response.json();

        if (response.ok) {
          dispatch({ type: "GET_ACADEMICYEARS", payload: academicyearsjson });
        }
        if (!response.ok) {
          throw new Error("Failed to fetch files");
        }
      } catch (error) {
        console.error("Error fetching files:", error);
      } finally {
        setLoading(false);
      }
    };

    if (user) {
      fetchAcademicYears();
    }
  }, [user, dispatch]);
  // Academic Years from Backend ends here

  if (loading) {
    return {
      columns: [],
      rows: [], // or handle loading state differently if you have a loading component
    };
  }

  return {
    columns: [
      { Header: "year", accessor: "year", width: "70%" },
      { Header: "edit", accessor: "edit", align: "center" },
      // { Header: "delete", accessor: "delete", align: "center" },
    ],

    rows: academicyears
      ? academicyears.map((item) => ({
          year: item.year,
          edit: <EditAcademicYearPopup academicyear={item} />,
          // delete: <DeleteAcademicYearPopup academicyear={item} />,
        }))
      : [],
  };
}
