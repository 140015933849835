import React, { useState } from "react";
import { Grid, IconButton } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import PDFViewer from "./pdfViewer";
import { ArrowBack, AttachFile, Visibility } from "@mui/icons-material";
import MDButton from "components/MDButton";
import CommentSection from "./commentSection";

const PreviewDocument = ({ document, onClose }) => {
  const [file, setFile] = useState(null);
  const [filename, setFileName] = useState(null);
  const [viewPdf, setViewPdf] = useState(null);
  const [showComments, setShowComments] = useState(false);

  const fileType = ["application/pdf"];
  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFileName(selectedFile);
    if (selectedFile) {
      if (selectedFile && fileType.includes(selectedFile.type)) {
        let reader = new FileReader();
        reader.readAsDataURL(selectedFile);
        reader.onload = (e) => {
          setFile(e.target.result);
        };
      } else {
        setFile(null);
      }
    } else {
      console.log("Please select a file");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (file !== null) {
      setViewPdf(file);
    } else {
      setViewPdf(null);
    }
  };

  const toggleComments = () => {
    setShowComments(!showComments);
  };

  const closeComments = () => {
    setShowComments(false);
  };

  return (
    <MDBox mb={2}>
      <MDBox display="flex" alignItems="center" mb={1}>
        <IconButton
          onClick={() => {
            onClose();
          }}
        >
          <ArrowBack />
        </IconButton>
        <MDTypography variant="h5" ml={1}>
          Go Back
        </MDTypography>
      </MDBox>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          md={showComments ? 8 : 10}
          lg={showComments ? 8 : 10}
          sx={{
            transition: "all 0.3s ease",
          }}
        >
          <MDBox height={450} borderRadius="lg" shadow="lg" opacity={1}>
            {/* <PDFViewer viewPdf={viewPdf} /> */}
            <PDFViewer document={document} />
          </MDBox>
        </Grid>
        <Grid
          item
          xs={12}
          md={showComments ? 4 : 2}
          lg={showComments ? 4 : 2}
          sx={{
            transition: "all 0.3s ease",
          }}
        >
          {!showComments ? (
            <MDBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100%"
            >
              <MDButton
                variant="contained"
                color="dark"
                onClick={toggleComments}
                startIcon={<Visibility />}
              >
                Show Comments
              </MDButton>
            </MDBox>
          ) : (
            <CommentSection closeComments={closeComments} document={document} />
          )}
        </Grid>
      </Grid>
    </MDBox>
  );
};

export default PreviewDocument;
