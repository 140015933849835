import { Grid } from "@mui/material";
import MDBox from "components/MDBox";

import DetailSection from "../components/detailSection";
import ConceptDocumentSection from "../components/conceptDocumentSection";
import { useAuthContext } from "hooks/useAuthContext";

const ConceptPaper = ({ onSubmit }) => {
  const { user } = useAuthContext();
  const stage = user.project.stage;
  return stage === "Concept Paper" ? (
    <MDBox>
      <Grid container spacing={3}>
        <Grid item xs={12} md={8} lg={8}>
          <ConceptDocumentSection onClick={onSubmit} />
        </Grid>
        <Grid item xs={12} md={4} lg={4}>
          <DetailSection />
        </Grid>
      </Grid>
    </MDBox>
  ) : (
    <ConceptDocumentSection />
  );

  // return (
  //   <MDBox>
  //     <Grid container spacing={3}>
  //       <Grid item xs={12} md={8} lg={8}>
  //         <ConceptDocumentSection onClick={onSubmit} />
  //       </Grid>
  //       <Grid item xs={12} md={4} lg={4}>
  //         <DetailSection />
  //       </Grid>
  //     </Grid>
  //   </MDBox>
  // );
};

export default ConceptPaper;
