import { useState } from "react";
import { useParams } from "react-router-dom";
import { API_URL } from "../config";

export const useResetPassword = () => {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const [success, setSuccess] = useState(null);
  const { token } = useParams();

  const resetpassword = async (newPassword, newConfirmPassword) => {
    if (!newPassword) {
      setIsLoading(false);
      setError("New Password Missing!");
      return;
    }

    if (!newConfirmPassword) {
      setIsLoading(false);
      setError("Confirm Password Missing!");
      return;
    }

    if (newPassword !== newConfirmPassword) {
      setIsLoading(false);
      setError("Passwords do not match!");
      return;
    }

    setIsLoading(true);
    setError(false);
    setSuccess(false);

    const response = await fetch(`${API_URL}/students/resetpassword/${token}`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ newPassword }),
    });

    const json = await response.json();

    if (!response.ok) {
      setIsLoading(false);
      setError(json.error);
    }

    if (response.ok) {
      setSuccess(json.message);
      setIsLoading(false);
      console.log(json);
    }
  };

  return { resetpassword, isLoading, error, success };
};
