import { PersonAdd, Task } from "@mui/icons-material";
import { Card } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";

//Data
import academicYearData from "../data/academicYearData";
import AddAcademicYearPopup from "../components/addAcademicYearPopup";

const AcademicYear = () => {
  const { columns, rows } = academicYearData();

  return (
    <Card>
      <MDBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        px={3}
        pt={3}
      >
        <MDTypography variant="h5" gutterBottom>
          Academic Years
        </MDTypography>
      </MDBox>
      <MDBox mx={3} mt={2} px={3}>
        <AddAcademicYearPopup />
      </MDBox>
      <MDBox pt={2}>
        <DataTable
          table={{ columns, rows }}
          isSorted={true}
          entriesPerPage={false}
          showTotalEntries={true}
          noEndBorder
          // canSearch={true}
        />
      </MDBox>
    </Card>
  );
};

export default AcademicYear;
