import { useAuthContext } from "hooks/useAuthContext";

import { AccountCircle } from "@mui/icons-material";
import { Card, Divider, Tooltip } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

const ProfileSection = () => {
  const { user } = useAuthContext();

  return (
    <Card sx={{ height: "100%" }}>
      <MDBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        <MDBox
          display="flex"
          flexDirection="column"
          alignItems="center"
          p={3}
          variant="gradient"
          borderRadius="lg"
          shadow="lg"
        >
          <MDTypography fontWeight="bold" gutterBottom>
            Profile
          </MDTypography>
          <MDBox sx={{ fontSize: 80 }}>
            <AccountCircle />
          </MDBox>
          <MDTypography variant="h5" gutterBottom>
            {user.first_name} {user.last_name}
          </MDTypography>
          <Divider sx={{ width: "100%", marginBottom: 2 }} />
          <MDTypography variant="body2" gutterBottom>
            <b>Registration Number:</b> {user.regNumber}
          </MDTypography>
          <Divider sx={{ width: "100%", marginBottom: 2 }} />
          <MDTypography variant="body2" gutterBottom mb={2}>
            <b>Programme:</b> {user.programmename}
          </MDTypography>
          <Tooltip
            title="Feature Coming Soon!"
            arrow
            slotProps={{
              popper: {
                modifiers: [
                  {
                    name: "offset",
                    options: {
                      offset: [0, -8],
                    },
                  },
                ],
              },
            }}
          >
            <span>
              <MDButton variant="outlined" color="info" disabled>
                Edit Profile
              </MDButton>
            </span>
          </Tooltip>
        </MDBox>
      </MDBox>
    </Card>
  );
};

export default ProfileSection;
