import { useState } from "react";
import { API_URL } from "../config";

export const useForgotPassword = () => {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const [success, setSuccess] = useState(null);

  const forgotpassword = async (email) => {
    const validateEmail = (email) => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    };

    setIsLoading(true);
    setError(false);
    setSuccess(false);

    if (!email) {
      setIsLoading(false);
      setError("Email is required!");
      return;
    }

    if (!validateEmail(email)) {
      setIsLoading(false);
      setError("Please enter a valid email address.");
      return;
    }

    const response = await fetch(`${API_URL}/students/forgotpassword`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email }),
    });

    const json = await response.json();

    if (!response.ok) {
      setIsLoading(false);
      setError(json.message);
    }

    if (response.ok) {
      setSuccess(json.message);
      setIsLoading(false);
      console.log(json);
    }
  };

  return { forgotpassword, isLoading, error, success };
};
