import { useEffect, useState } from "react";

import { FilterAlt } from "@mui/icons-material";
import { Card, MenuItem } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";

//Data
import AddStudentPopup from "../components/addStudentPopup";
import studentsData from "../data/studentsData";
import MDInput from "components/MDInput";
import { API_URL } from "../../../../config";

const AllStudents = () => {
  const { columns, rows1 } = studentsData();
  // const { columns, rows } = studentsData();

  const [selectedProgramme, setSelectedProgramme] = useState("");
  const [programmes, setProgrammes] = useState([]);
  const [academicYears, setAcademicYears] = useState([]);
  const [academicyear, setAcademicYear] = useState("");
  const [filteredRows, setFilteredRows] = useState([]);

  //Fetch programmes and academic years from the backend
  useEffect(() => {
    const fetchProgrammes = async () => {
      try {
        const response = await fetch(`${API_URL}/programmes/getprogrammes`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            // Authorization: `Bearer ${user.token}`,
          },
        });

        const programmesjson = await response.json();

        if (response.ok) {
          setProgrammes(programmesjson);
        }
        if (!response.ok) {
          throw new Error("Failed to fetch files");
        }
      } catch (error) {
        console.error("Error fetching files:", error);
      }
    };

    const fetchAcademicYears = async () => {
      try {
        const response = await fetch(`${API_URL}/academicyears/getyears`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            // Authorization: `Bearer ${user.token}`,
          },
        });

        const academicyearsjson = await response.json();

        if (response.ok) {
          setAcademicYears(academicyearsjson);
        }
        if (!response.ok) {
          throw new Error("Failed to fetch files");
        }
      } catch (error) {
        console.error("Error fetching files:", error);
      }
    };

    fetchProgrammes();
    fetchAcademicYears();
  }, []);
  // Programmes and Academic Years from Backend ends here

  // Initial setting of rows and handling search filter
  useEffect(() => {
    const handleSearch = () => {
      const filtered = rows1.filter((row) => {
        const matchesProgramme = selectedProgramme
          ? row.programme === selectedProgramme
          : true;
        const matchesYear = academicyear
          ? row.academicyear === academicyear
          : true;
        return matchesProgramme && matchesYear;
      });
      setFilteredRows(filtered);
    };

    handleSearch();
  }, [rows1, selectedProgramme, academicyear]);

  return (
    <Card>
      <MDBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        px={3}
        pt={3}
      >
        <MDTypography variant="h5" gutterBottom>
          All Students
        </MDTypography>
      </MDBox>
      <MDBox mx={3} mt={2} px={3}>
        <AddStudentPopup />
        <MDBox display="flex" justifyContent="center" my={1}>
          {/* Filter Label */}
          <MDBox>
            <MDTypography>
              <FilterAlt /> Filter:
            </MDTypography>
          </MDBox>

          {/* Programme Filter */}
          <MDBox ml={1} flex={0.2}>
            <MDInput
              placeholder="Programme"
              label="Programme"
              select
              value={selectedProgramme}
              onChange={(e) => setSelectedProgramme(e.target.value)}
              size="small"
              fullWidth
              InputProps={{
                style: {
                  height: "35px",
                  padding: "12px",
                },
              }}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {programmes.map((programme) => (
                <MenuItem key={programme.id} value={programme.programmecode}>
                  {programme.programmecode}
                </MenuItem>
              ))}
            </MDInput>{" "}
          </MDBox>

          {/* Academic Year Filter */}
          <MDBox ml={1} flex={0.2} mr={1}>
            <MDInput
              placeholder="Academic Year"
              label="Academic Year"
              select
              value={academicyear}
              onChange={(e) => setAcademicYear(e.target.value)}
              size="small"
              fullWidth
              InputProps={{
                style: {
                  height: "35px",
                  padding: "12px",
                },
              }}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {academicYears.map((academicYear) => (
                <MenuItem key={academicYear.id} value={academicYear.year}>
                  {academicYear.year}
                </MenuItem>
              ))}
            </MDInput>
          </MDBox>

          {/* Search Filter */}
          {/* <MDButton
            sx={{ height: "20px" }}
            variant="contained"
            color="primary"
            size="small"
            // onClick={handleSearch}
            onClick={() => setFilteredRows(filteredRows)}
            startIcon={<Search />}
          >
            Search
          </MDButton> */}
        </MDBox>
      </MDBox>
      <MDBox>
        <DataTable
          table={{ columns, rows: filteredRows }}
          isSorted={false}
          // canSearch={true}
          entriesPerPage={false}
          showTotalEntries={true}
          // noEndBorder
        />
      </MDBox>
    </Card>
  );
};

export default AllStudents;
