import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import MDInput from 'components/MDInput';
import MDTypography from 'components/MDTypography';
import { useState } from 'react';
import io from 'socket.io-client';
import ChatRoom from './chatRoom';

const socket = io.connect("http://localhost:4000")

const Chatting = () => {
    const [username, setUsername] = useState("")
    const [room, setRoom] = useState("")
    const [showChat, setShowChat] = useState(false);

    const joinRoom = () => {
        if (username !== "" && room !== "") {
            socket.emit('join_room', room)
            setShowChat(true);

        }
    }

    return (

        <MDBox
            p={3}
            width={300}
            bgColor="white"
        >
            {!showChat ? (
                <MDBox>
                    <MDTypography variant="h4">Join Chat</MDTypography>
                    <MDBox mb={2}>
                        <MDInput
                            type="text"
                            label="Name"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            variant="standard"
                            fullWidth
                        />
                    </MDBox>
                    <MDBox mb={2}>
                        <MDInput
                            type="text"
                            label="Room"
                            value={room}
                            onChange={(e) => setRoom(e.target.value)}
                            variant="standard"
                            fullWidth
                        />
                    </MDBox>
                    <MDButton color="info" mt={2} onClick={joinRoom}>Join Room</MDButton>
                </MDBox>

            ) : (<ChatRoom socket={socket} username={username} room={room} />)}
        </MDBox>
    );
}

export default Chatting;


