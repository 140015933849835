import { SupervisorsContext } from "context/SupervisorContext";
import { useContext } from "react";

export const useSupervisorContext = () => {
  const context = useContext(SupervisorsContext);

  if (!context) {
    throw Error(
      "useSupervisorContext must be used inside an SupervisorsContextProvider"
    );
  }

  return context;
};
