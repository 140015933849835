// Hooks
import { useConfirmSignup } from "hooks/useConfirmSignup";
import { useResendEmail } from "hooks/useResendEmail";
import { useState } from "react";
import { useLocation } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import bgImage from "assets/images/faculty-building.jpg";
import { Link } from "react-router-dom";
import { ArrowBack, Loop } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";

import styled, { keyframes } from "styled-components";
import { pink } from "@mui/material/colors";

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const RotatingLoop = styled(Loop)`
  animation: ${spin} 1s linear infinite;
`;

function Cover() {
  const location = useLocation();
  const { email } = location.state || {};
  const [passcode, setPasscode] = useState("");
  const {
    confirmsignup,
    error,
    isLoading,
    success,
    resetState: resetConfirmSignupState,
  } = useConfirmSignup();
  const {
    resendemail,
    error1,
    isLoading1,
    success1,
    resetState: resetResendEmailState,
  } = useResendEmail();

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Reset states before starting a new action
    resetConfirmSignupState();
    resetResendEmailState();

    await confirmsignup(passcode);
  };
  const handleResend = async (e) => {
    e.preventDefault();

    // Reset states before starting a new action
    resetConfirmSignupState();
    resetResendEmailState();

    await resendemail(email);
  };

  return (
    <CoverLayout coverHeight="10vh" image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          py={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Complete Registration!!
          </MDTypography>
        </MDBox>
        <MDBox pt={1} pb={2} px={3}>
          <MDTypography variant="h6" mt={1}>
            Enter the 6-digit code sent on the email you provided
          </MDTypography>
          <MDBox component="form" role="form" mb={2}>
            <MDBox mb={2}>
              <MDInput
                type="text"
                label="6-digit code"
                variant="standard"
                onChange={(e) => setPasscode(e.target.value)}
                fullWidth
              />
            </MDBox>
            <MDBox
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-evenly",
                alignItems: "center",
              }}
            >
              <MDButton
                variant="gradient"
                color="info"
                onClick={handleSubmit}
                disabled={isLoading || isLoading1}
              >
                {isLoading && (
                  <CircularProgress size={24} color="inherit" sx={{ mr: 1 }} />
                )}
                Submit
              </MDButton>
              <MDButton
                variant="outlined"
                color="info"
                onClick={handleResend}
                disabled={isLoading || isLoading1}
                startIcon={isLoading1 ? <RotatingLoop /> : <Loop />}
              >
                Resend OTP
              </MDButton>
            </MDBox>
            {(error || error1) && (
              <MDBox
                borderRadius="4px"
                px={3}
                mx={2}
                mt={2}
                style={{
                  textAlign: "center",
                  border: "1px solid #f44335", // Error redish color for box border
                  backgroundColor: "rgba(244, 67, 53, 0.2)", // Lighter transparent redish color for box background
                }}
              >
                <MDTypography color="error">{error || error1}</MDTypography>
              </MDBox>
            )}
            {(success || success1) && (
              <MDBox display="flex" flexDirection="column">
                <MDBox
                  borderRadius="4px"
                  px={3}
                  mx={2}
                  mt={2}
                  style={{
                    textAlign: "center",
                    border: "1px solid #4caf50",
                    backgroundColor: "rgba(76, 175, 80, 0.2)",
                  }}
                >
                  <MDTypography color="success">
                    {success1 || success}
                  </MDTypography>
                </MDBox>
                {/* <MDButton
                  component={Link}
                  to="/signin"
                  variant="text"
                  color="info"
                  startIcon={<ArrowBack />}
                >
                  Go to Login
                </MDButton> */}
              </MDBox>
            )}
          </MDBox>
          <MDBox>
            <MDBox>
              {" "}
              <MDTypography variant="button" fontWeight="regular" color="text">
                The code expires 1 hour after it has been sent.
              </MDTypography>
            </MDBox>
            <MDBox>
              {" "}
              <MDTypography variant="button" fontWeight="regular" color="text">
                If you didn't receive it, click RESEND OTP Button.
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </CoverLayout>
  );
}

export default Cover;
