import { useState } from "react";

import { useProgrammeContext } from "hooks/useProgrammeContext";
import { useAuthContext } from "hooks/useAuthContext";
import { API_URL } from "../../config";

export const useAddProgramme = () => {
  const { user } = useAuthContext();
  const { programmes, dispatch } = useProgrammeContext();

  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const addprogramme = async (
    programmecode,
    programmename,
    programmeduration
  ) => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await fetch(`${API_URL}/programmes/createprogramme`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
        body: JSON.stringify({
          programmecode,
          programmename,
          programmeduration,
        }),
      });

      if (!response.ok) {
        const json = await response.json();
        throw new Error(json.error);
      }

      const json = await response.json();
      dispatch({ type: "ADD_PROGRAMME", payload: json });
      setSuccess("Programme created successfully");
    } catch (error) {
      setError(error.message || "An error occurred when creating programme");
    } finally {
      setIsLoading(false);
    }
  };

  return { addprogramme, isLoading, error, success, setError, setSuccess };
};
