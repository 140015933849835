import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Grid from '@mui/material/Grid';
import MDButton from 'components/MDButton';
import MDInput from 'components/MDInput';
import MDBox from 'components/MDBox';
import { MenuItem } from '@mui/material';
import MDTypography from 'components/MDTypography';
import { useAddStudent } from 'hooks/useAddStudent';
import { PersonAdd } from '@mui/icons-material';

// { open, handleClose }
const AddStudentPopup = () => {
    const [courses, setCourses] = useState([]);
    const [open, setOpen] = useState(false);
    const [selectedCourse, setSelectedCourse] = useState(null);
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [regNumber, setRegNumber] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [yearOfStudy, setYearOfStudy] = useState('');
    const { addstudent, error, isLoading, success, setError, setSuccess } = useAddStudent()

    // Generate dummy array of courses
    useEffect(() => {
        const dummyCourses = [
            { id: 1, name: "Software Engineering" },
            { id: 2, name: "Information Technology" },
            { id: 3, name: "Computer Science" },
            { id: 4, name: "Biomedical Engineering" },
            { id: 5, name: "Petroleum Engineering" },
            { id: 6, name: "Food and Nutrition" },
            { id: 7, name: "Gender and Women Health" },

            // Add more courses as needed
        ];
        setCourses(dummyCourses);
    }, []);


    const handleSubmit = async (e) => {
        e.preventDefault()
        console.log("We are in handle submit");
        const selectedCourseName = selectedCourse ? courses.find(course => course.id === selectedCourse).name : '';

        console.log(firstname, lastname, email, selectedCourseName, password, confirmPassword, regNumber, phoneNumber, yearOfStudy)
        // console.log("Trying before", trying);
        // setTrying(true)
        await addstudent(firstname, lastname, email, selectedCourseName, password, confirmPassword, regNumber, phoneNumber, yearOfStudy)

        // clear all fields
        // setSelectedCourse(null)
        // setFirstname('')
        // setLastname('')
        // setEmail('')
        // setPassword('')
        // setConfirmPassword('')
        // setRegNumber('')
        // setPhoneNumber('')
        // setYearOfStudy('')

        // Close the modal if success
        // console.log("Trying after", trying);
        // trying && setOpen(false)
        // if (success) {
        //     console.log("We are in handle submit - if success");
        //     setOpen(false);
        //     //clear all fields
        //     // Clear error on close
        //     setError(null);
        //     setSuccess(null);
        // }
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        //clear all fields
        setSelectedCourse(null)
        setFirstname('')
        setLastname('')
        setEmail('')
        setPassword('')
        setConfirmPassword('')
        setRegNumber('')
        setPhoneNumber('')
        setYearOfStudy('')
        // Clear error on close
        setError(null);
        setSuccess(null);
    };

    return (
        <div>
            <MDButton variant="gradient" color="info" size="small" startIcon={<PersonAdd />} onClick={handleClickOpen}>
                Add Student
            </MDButton>
            <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
                <DialogTitle>Add Student</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <MDInput
                                fullWidth
                                label="First Name"
                                name="firstName"
                                variant="standard"
                                value={firstname}
                                onChange={(e) => setFirstname(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <MDInput
                                fullWidth
                                label="Last Name"
                                name="lastName"
                                variant="standard"
                                value={lastname}
                                onChange={(e) => setLastname(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <MDInput
                                fullWidth
                                label="Student Email"
                                name="email"
                                variant="standard"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <MDInput
                                fullWidth
                                label="Registration Number"
                                name="regNumber"
                                variant="standard"
                                value={regNumber}
                                onChange={(e) => setRegNumber(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <MDInput
                                fullWidth
                                label="Password"
                                name="password"
                                type="password"
                                variant="standard"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <MDInput
                                fullWidth
                                label="Confirm Password"
                                name="confirmPassword"
                                type="password"
                                variant="standard"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <MDInput
                                fullWidth
                                label="Select Course"
                                select
                                name="course"
                                variant="standard"
                                value={selectedCourse}
                                onChange={(e) => setSelectedCourse(e.target.value)}
                            >
                                {courses.map((course) => (
                                    <MenuItem key={course.id} value={course.id}>
                                        {course.name}
                                    </MenuItem>
                                ))}
                            </MDInput>
                        </Grid>
                        <Grid item xs={6}>
                            <MDInput
                                fullWidth
                                label="Phone Number"
                                name="phoneNumber"
                                variant="standard"
                                value={phoneNumber}
                                onChange={(e) => setPhoneNumber(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={6} mt={1}>
                            <MDInput
                                fullWidth
                                label="Year of Study"
                                select
                                name="yearOfStudy"
                                variant="standard"
                                value={yearOfStudy}
                                onChange={(e) => setYearOfStudy(e.target.value)}
                            >
                                {[1, 2, 3, 4, 5].map((year) => (
                                    <MenuItem key={year} value={year}>
                                        {`Year ${year}`}
                                    </MenuItem>
                                ))}
                            </MDInput>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <MDButton onClick={handleClose}>Cancel</MDButton>
                    <MDButton onClick={handleSubmit} variant="contained" color="primary" disabled={isLoading}>
                        Submit
                    </MDButton>
                </DialogActions>
                {error && <MDBox
                    borderRadius="4px"
                    px={3}
                    mx={2}
                    mb={2}
                    style={{
                        textAlign: "center",
                        border: "1px solid #f44335", // Error redish color for box border
                        backgroundColor: "rgba(244, 67, 53, 0.2)" // Lighter transparent redish color for box background
                    }}
                >
                    <MDTypography color="error">{error}</MDTypography>
                </MDBox>}
                {success && (
                    <MDBox
                        borderRadius="4px"
                        px={3}
                        mx={2}
                        mb={2}
                        style={{
                            textAlign: "center",
                            border: "1px solid #4caf50",
                            backgroundColor: "rgba(76, 175, 80, 0.2)"
                        }}
                    >
                        <MDTypography color="success">{success}</MDTypography>
                    </MDBox>
                )}
            </Dialog>
        </div>

    );
};

export default AddStudentPopup;
