import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "App";

// Material Dashboard 2 React Context Provider
import { MaterialUIControllerProvider } from "context";
import { AuthContextProvider } from "context/AuthContext";
import { ReadStatusProvider } from "context/ReadStatusContext";
import { ProgrammesContextProvider } from "context/ProgrammeContext";
import { AcademicYearsContextProvider } from "context/AcademicYearContext";
import { StudentsContextProvider } from "context/StudentContext";
import { ProjectsContextProvider } from "context/ProjectContext";
import { SupervisorsContextProvider } from "context/SupervisorContext";
import { QuickStatsProvider } from "context/QuickStatsContext";

const container = document.getElementById("app");
const root = createRoot(container);

root.render(
  <BrowserRouter>
    <AuthContextProvider>
      <ReadStatusProvider>
        <StudentsContextProvider>
          <SupervisorsContextProvider>
            <ProgrammesContextProvider>
              <AcademicYearsContextProvider>
                <ProjectsContextProvider>
                  <QuickStatsProvider>
                    <MaterialUIControllerProvider>
                      <App />
                    </MaterialUIControllerProvider>
                  </QuickStatsProvider>
                </ProjectsContextProvider>
              </AcademicYearsContextProvider>
            </ProgrammesContextProvider>
          </SupervisorsContextProvider>
        </StudentsContextProvider>
      </ReadStatusProvider>
    </AuthContextProvider>
  </BrowserRouter>
);
