import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useReadStatus } from "context/ReadStatusContext";
import { useAuthContext } from "hooks/useAuthContext";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Icon from "@mui/material/Icon";
import { Tooltip } from "@mui/material";
import { API_URL } from "../../../../config";

// Appropriate Extension Icons
const extensionIcons = {
  pdf: { icon: "picture_as_pdf", color: "#F40F02" },
  doc: { icon: "description", color: "#2196F3" },
  docx: { icon: "description", color: "#2196F3" },
  xls: { icon: "description", color: "#4CAF50" },
  xlsx: { icon: "description", color: "#4CAF50" },
  ppt: { icon: "slideshow", color: "#FF9800" },
  pptx: { icon: "slideshow", color: "#FF9800" },
  jpg: { icon: "image", color: "#FFC107" },
  jpeg: { icon: "image", color: "#FFC107" },
  png: { icon: "image", color: "#FFC107" },
  gif: { icon: "image", color: "#FFC107" },
  txt: { icon: "description", color: "#9E9E9E" },
};

export default function useData() {
  const navigate = useNavigate(); // Using useNavigate hook
  const { user } = useAuthContext();

  // Function to format the date
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
    const formattedTime = date.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      // second: "2-digit",
      hour12: true,
    });
    return `${formattedDate} ${formattedTime}`;
  };

  const [dataFromBackend, setDataFromBackend] = useState([]);

  useEffect(() => {
    const fetchFiles = async () => {
      try {
        const response = await fetch(
          `${API_URL}/files/getfiles/${user.email}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${user.token}`,
            },
          }
        );

        const files = await response.json();
        console.log("Files:", files);

        if (response.ok) {
          if (files.files.length > 0) {
            setDataFromBackend(files.files);
          } else {
            setDataFromBackend([]);
          }
        }
        if (!response.ok) {
          throw new Error("Failed to fetch files");
        }
      } catch (error) {
        console.error("Error fetching files:", error);
        setDataFromBackend([]); // Set an empty array or handle the error as needed
      }
    };

    if (user) {
      fetchFiles();
    }
  }, [user]);

  const Sender = ({ sender, isViewed }) => {
    return (
      <MDBox display="flex" alignItems="center" lineHeight={1}>
        <Icon
          sx={{
            fontWeight: "bold",
            color: isViewed ? "#808080" : "#4CAF50",
            mt: -0.5,
          }}
        >
          circle
        </Icon>
        <MDTypography
          variant="h6"
          // fontWeight="medium"
          ml={1}
          lineHeight={1}
          // sx={{ color: "#6B6B6B" }}
        >
          {sender}
        </MDTypography>
      </MDBox>
    );
  };

  const Message = ({ item }) => {
    const { title, description } = item;

    const documentAttached = item.filename;

    const getFileExtension = (fileName) => {
      const parts = fileName.split(".");
      return parts[parts.length - 1].toLowerCase();
    };

    const fileExtension = getFileExtension(documentAttached);
    const { icon, color } = extensionIcons[fileExtension] || {
      icon: "attachment",
      color: "#9E9E9E",
    };

    const handleDocumentClick = () => {
      //   toggleReadStatus(index);
      // alert("The item is " + JSON.stringify(item)); // Corrected alert message
      navigate("/view-document", { state: { document: item } });
    };

    return (
      <MDBox>
        <Tooltip title="Click to View document">
          <MDBox
            textAlign="center"
            lineHeight={1}
            onClick={handleDocumentClick}
            sx={{
              ":hover": {
                textDecoration: "underline",
                cursor: "pointer",
              },
            }}
          >
            <MDTypography
              variant="button"
              fontWeight="medium"
              // sx={{
              //   color: "#6B6B6B",
              // }}
            >
              {title.toUpperCase()}
            </MDTypography>
          </MDBox>
        </Tooltip>

        {documentAttached && (
          <MDBox display="flex" alignItems="center" lineHeight={1} mt={1}>
            <Icon sx={{ color: color }}>{icon}</Icon>
            <MDTypography
              variant="body"
              fontWeight="bold"
              ml={1}
              lineHeight={1}
              // sx={{ color: "#6B6B6B" }}
            >
              {documentAttached}
            </MDTypography>
          </MDBox>
        )}
      </MDBox>
    );
  };

  const Date1 = ({ date }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDTypography
        variant="body"
        fontWeight="bold"
        ml={1}
        lineHeight={1}
        sx={{ color: "#6B6B6B" }}
      >
        {formatDate(date)}
      </MDTypography>
    </MDBox>
  );

  return {
    columns: [
      { Header: "sender", accessor: "sender", width: "10%", align: "left" },
      {
        Header: "reg number",
        accessor: "regno",
        width: "25%",
        align: "center",
      },
      { Header: "message", accessor: "message", align: "center" },
      { Header: "date", accessor: "date", width: "15%", align: "center" },
    ],

    rows: dataFromBackend.map((item, index) => ({
      sender: (
        <Sender key={index} sender={item.senderName} isViewed={item.isViewed} />
      ),
      regno: item.regNumber,
      message: <Message key={index} item={item} />,
      // date: <Date1 key={index} date={item.createdAt} />,
      date: formatDate(item.createdAt),
    })),
  };
}
