import MDBox from "components/MDBox";

import { useEffect, useState } from "react";

import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";

import { toolbarPlugin } from "@react-pdf-viewer/toolbar";

import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

import { Document, Page } from "react-pdf";
import MDTypography from "components/MDTypography";
import { useAuthContext } from "hooks/useAuthContext";
import { API_URL } from "../../../../../config";

// const PDFViewer = ({ viewPdf }) => {
//   // Create new plugin instance
//   //   const bookmarkPluginInstance = bookmarkPlugin();
//   //   const searchPluginInstance = searchPlugin();
//   //   const zoomPluginInstance = zoomPlugin();

//   //   const { Search } = searchPluginInstance;
//   //   const toolbarPluginInstance = toolbarPlugin();
//   const newplugin = defaultLayoutPlugin();
//   return (
//     <MDBox>
//       <MDBox
//         width="100%"
//         height={400}
//         // overflow-y:auto
//         display="flex"
//         justifyContent="center"
//         alignItems="center"
//       >
//         <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
//           {viewPdf && (
//             <>
//               <Viewer fileUrl={viewPdf} />
//               {/* <Viewer fileUrl={viewPdf} plugins={[newplugin]} /> */}
//             </>
//           )}
//           {!viewPdf && <>No PDF</>}
//         </Worker>
//       </MDBox>
//     </MDBox>
//   );
// };

const PDFViewer = ({ document }) => {
  // console.log("Document(PDFViewer):", document);
  const { user } = useAuthContext();

  const [pdfData, setPdfData] = useState(null);
  const [loading, setLoading] = useState(true); // Add loading state

  useEffect(() => {
    const fetchfile = async () => {
      try {
        const response = await fetch(
          `${API_URL}/files/getfile/${document.id}`,
          {
            Authorization: `Bearer ${user.token}`,
            Accept: "application/pdf",
            "Content-Type": "application/pdf", // Indicating the type of content
          }
        );

        if (response.ok) {
          // console.log(response);
          const blob = await response.blob();
          // console.log("Blob:", blob);
          // create a url for the blob
          const url = URL.createObjectURL(blob);
          setPdfData(url);
        } else {
          throw new Error("Failed to fetch document");
        }
      } catch (error) {
        console.error("Error fetching files:", error);
      } finally {
        setLoading(false);
      }
    };

    if (user) {
      fetchfile();
      return () => URL.revokeObjectURL(pdfData); // Cleanup the Object URL on unmount
    }
  }, [user, document]);

  const newplugin = defaultLayoutPlugin();
  return (
    <MDBox>
      <MDBox
        width="100%"
        height={450}
        // overflow-y:auto
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
          {pdfData ? (
            <>
              <Viewer fileUrl={pdfData} />
              {/* <Viewer fileUrl={viewPdf} plugins={[newplugin]} /> */}
            </>
          ) : (
            <>No PDF</>
          )}
        </Worker>
      </MDBox>
    </MDBox>
  );
};

export default PDFViewer;
