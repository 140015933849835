import MDBox from "components/MDBox";

import { useEffect, useState } from "react";

import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";

import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

// import { zoomPlugin } from "@react-pdf-viewer/zoom";
// import { ZoomInIcon, ZoomOutIcon } from "@react-pdf-viewer/zoom";

// Import styles
import "@react-pdf-viewer/zoom/lib/styles/index.css";

import MDTypography from "components/MDTypography";
import { IconButton } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { API_URL } from "../../../../../config";

const PDFViewer = ({ document, onClose }) => {
  // console.log("Document(PDFViewer):", document);

  const [pdfData, setPdfData] = useState(null);
  const [loading, setLoading] = useState(true); // Add loading state

  const newplugin = defaultLayoutPlugin();
  // const zoomPluginInstance = zoomPlugin();

  // console.log("Hello");
  useEffect(() => {
    const fetchfile = async () => {
      try {
        const response = await fetch(
          // `${API_URL}/files/getfile/${document.id}`,
          `${API_URL}/files/getfile/14`,
          {
            // Authorization: `Bearer ${user.token}`,
            Accept: "application/pdf",
            "Content-Type": "application/pdf", // Indicating the type of content
          }
        );

        if (response.ok) {
          const blob = await response.blob();
          const url = URL.createObjectURL(blob);
          setPdfData(url);
        } else {
          throw new Error("Failed to fetch document");
        }
      } catch (error) {
        console.error("Error fetching files:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchfile();
    return () => URL.revokeObjectURL(pdfData); // Cleanup the Object URL on unmount
  }, []);

  return (
    <MDBox
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "550px", // Full viewport height
        width: "100%", // Full width
      }}
    >
      <MDBox display="flex" alignItems="center" mb={1}>
        <IconButton
          onClick={() => {
            onClose();
          }}
        >
          <ArrowBack />
        </IconButton>
        <MDTypography variant="h5" ml={1}>
          Go Back
        </MDTypography>
      </MDBox>
      <MDBox
        // width={"100%"}
        // height={480}
        // // overflow-y:auto
        // display="flex"
        // justifyContent="center"
        // alignItems="center"
        sx={{
          flex: 1, // Takes up remaining space
          overflow: "auto",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
          {pdfData ? (
            <>
              <Viewer fileUrl={pdfData} />
              {/* <Viewer fileUrl={pdfData} plugins={[newplugin]} /> */}
              {/* <Viewer fileUrl={pdfData} plugins={[zoomPluginInstance]} /> */}
              {/* <div>
                <ZoomInIcon onClick={zoomPluginInstance.ZoomIn} />
                <ZoomOutIcon />
              </div> */}
            </>
          ) : (
            <>No PDF</>
          )}
        </Worker>
      </MDBox>
    </MDBox>
  );
};

export default PDFViewer;
