import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { IconButton, Divider } from "@mui/material";
import { ArrowBack, Visibility, CloudDownload } from "@mui/icons-material";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Header from "layouts/supervisorLayouts/profile/components/Header";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDButton from "components/MDButton";
import PreviewDocument from "../previewDocument";
import { useAuthContext } from "hooks/useAuthContext";

// Appropriate Extension Icons
const extensionIcons = {
  pdf: { icon: "picture_as_pdf", color: "#FF5722" },
  doc: { icon: "description", color: "#2196F3" },
  docx: { icon: "description", color: "#2196F3" },
  xls: { icon: "description", color: "#4CAF50" },
  xlsx: { icon: "description", color: "#4CAF50" },
  ppt: { icon: "slideshow", color: "#FF9800" },
  pptx: { icon: "slideshow", color: "#FF9800" },
  jpg: { icon: "image", color: "#FFC107" },
  jpeg: { icon: "image", color: "#FFC107" },
  png: { icon: "image", color: "#FFC107" },
  gif: { icon: "image", color: "#FFC107" },
  txt: { icon: "description", color: "#9E9E9E" },
};

// Function to format the date
const formatDate = (dateString) => {
  const date = new Date(dateString);
  const formattedDate = date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
  const formattedTime = date.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });
  return `${formattedDate} ${formattedTime}`;
};

const ViewDocument = () => {
  const { user } = useAuthContext();
  const location = useLocation();
  const navigate = useNavigate();
  const document = location.state?.document;

  const [showPreview, setShowPreview] = useState(false);

  const getFileExtension = (fileName) => {
    const parts = fileName.split(".");
    return parts[parts.length - 1].toLowerCase();
  };

  const fileExtension = getFileExtension(document.filename);
  const { icon, color } = extensionIcons[fileExtension] || {
    icon: "attachment",
    color: "#9E9E9E",
  };

  const handlePreviewClick = () => {
    setShowPreview(true);
  };

  const handleBackClick = () => {
    setShowPreview(false);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {!showPreview ? (
        <Header>
          <MDBox p={3}>
            {/* Back Button and Title */}
            <MDBox display="flex" alignItems="center" mb={3}>
              <IconButton
                onClick={() => {
                  navigate(-1);
                }}
              >
                <ArrowBack />
              </IconButton>
              <MDTypography variant="h5" ml={1}>
                {document.title}
              </MDTypography>
            </MDBox>
            <Divider />

            {/* To */}
            <MDBox mt={3}>
              <MDTypography variant="h6" mb={1}>
                To:{" "}
                <span style={{ fontWeight: "normal" }}>
                  {user.project.Supervisor.first_name}{" "}
                  {user.project.Supervisor.last_name}
                </span>
              </MDTypography>
            </MDBox>
            <Divider />

            {/* From */}
            <MDBox mt={3}>
              <MDTypography variant="h6" mb={1}>
                From:{" "}
                <span style={{ fontWeight: "normal" }}>
                  {user.first_name} {user.last_name}
                </span>
              </MDTypography>
            </MDBox>
            <Divider />

            {/* Date */}
            <MDBox mt={3}>
              <MDTypography variant="h6" mb={1}>
                Date:{" "}
                <span style={{ fontWeight: "normal" }}>
                  {formatDate(document.createdAt)}
                </span>
              </MDTypography>
            </MDBox>
            <Divider />

            {/* Description */}
            <MDBox mt={3}>
              <MDTypography variant="h6" mb={1}>
                Description:
              </MDTypography>
              <MDTypography variant="body2" mb={3}>
                {document.description}
              </MDTypography>
            </MDBox>
            <Divider />

            {/* Attached Document */}
            <MDBox mt={3}>
              <MDTypography variant="h6" mb={1}>
                Attached Document:
              </MDTypography>
              <MDBox>
                <Icon sx={{ color: color }}>{icon}</Icon>
                <MDTypography variant="body4" ml={1}>
                  {document.filename}
                </MDTypography>
              </MDBox>
            </MDBox>

            {/* Icons */}
            <MDBox mt={3} display="flex" justifyContent="center">
              <MDButton
                variant="contained"
                color="info"
                startIcon={<Visibility />}
                sx={{ marginRight: "10px" }}
                onClick={handlePreviewClick}
              >
                Preview
              </MDButton>
              <MDButton
                variant="contained"
                color="secondary"
                startIcon={<CloudDownload />}
                onClick={() => {
                  console.log("Download Clicked");
                }}
              >
                Download
              </MDButton>
            </MDBox>
          </MDBox>
        </Header>
      ) : (
        <PreviewDocument document={document} onClose={handleBackClick} />
      )}
      <Footer />
    </DashboardLayout>
  );
};

export default ViewDocument;
