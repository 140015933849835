import { useState, useEffect } from "react";
import { useAuthContext } from "hooks/useAuthContext";

// react-router components
import { useLocation, Link } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";
import MDAvatar from "components/MDAvatar";
import MUSTlogo from "../../../assets/images/logos/must.png";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";

// Material Dashboard 2 React example components
import NotificationItem from "examples/Items/NotificationItem";

// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarMobileMenu,
} from "examples/Navbars/DashboardNavbar/styles";

// Material Dashboard 2 React context
import {
  useMaterialUIController,
  setTransparentNavbar,
  setMiniSidenav,
  setOpenConfigurator,
} from "context";
import MDTypography from "components/MDTypography";
import { Avatar, Popover, Button, Divider } from "@mui/material";
import {
  AccessTimeFilled,
  AccountCircle,
  Email,
  ExitToApp,
  HourglassEmpty,
  MenuOpen,
  MenuSharp,
  Notifications,
  Podcasts,
  Settings,
  ShoppingCart,
} from "@mui/icons-material";
import MDButton from "components/MDButton";

function DashboardNavbar({ absolute, light, isMini }) {
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    transparentNavbar,
    fixedNavbar,
    openConfigurator,
    darkMode,
  } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const route = useLocation().pathname.split("/").slice(1);
  const { user } = useAuthContext();

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(
        dispatch,
        (fixedNavbar && window.scrollY === 0) || !fixedNavbar
      );
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleConfiguratorOpen = () =>
    setOpenConfigurator(dispatch, !openConfigurator);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);

  // Render the notifications menu
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      <NotificationItem
        icon={<AccessTimeFilled />}
        title="Feature coming soon!"
      />
      {/* <NotificationItem icon={<Podcasts />} title="Manage Podcast sessions" /> */}
      {/* <NotificationItem
        icon={<ShoppingCart />}
        title="Payment successfully completed"
      /> */}
    </Menu>
  );

  // Styles for the navbar icons
  const iconsStyle = ({
    palette: { dark, white, text },
    functions: { rgba },
  }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });

  //Working on popover for profile
  const [anchorEl, setAnchorEl] = useState(null);

  const handleProfileIconClick = (event) => {
    setAnchorEl(event.currentTarget); // Set the anchor element for the popover
  };

  const handleClosePopover = () => {
    setAnchorEl(null); // Close the popover
  };

  const open = Boolean(anchorEl);

  const ProfileComponent = () => {
    // Your profile information goes here
    const name = `${user.first_name} ${user.last_name}`;

    return (
      <MDBox
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "16px",
        }}
      >
        <MDTypography variant="h6" mb={2}>
          Profile
        </MDTypography>
        {/* Avatar */}
        <Avatar
          style={{ width: "50px", height: "50px", marginBottom: "8px" }}
        />

        {/* Username */}
        <MDTypography variant="h6">{name}</MDTypography>

        {/* Divider */}
        <Divider style={{ width: "100%", margin: "10px 0" }} />

        {/* Role */}
        <MDTypography
          variant="body2"
          style={{ color: "rgba(0, 0, 0, 0.54)" }}
          mb={8}
        >
          {user.role.charAt(0).toUpperCase() + user.role.slice(1)}
        </MDTypography>

        {/* Additional Features */}
        {/* Add any additional features here */}

        {/* Logout Button */}
        <MDButton
          // variant="outlined"
          color="primary"
          startIcon={<ExitToApp />}
          // onClick={onLogout}
        >
          Logout
        </MDButton>
      </MDBox>
    );
  };

  //Ends here

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) => ({
        ...navbar(theme, { transparentNavbar, absolute, light, darkMode }),
        overflowX: "hidden", // Ensure no horizontal overflow
      })}
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <MDBox
          color="inherit"
          mb={{ xs: 1, md: 0 }}
          sx={(theme) => ({
            ...navbarRow(theme, { isMini }),
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexDirection: { xs: "column", md: "row" }, // Set flex direction based on screen size
          })}
        >
          {isMini ? null : (
            <>
              <MDBox
                component="img"
                src={MUSTlogo}
                alt="master card"
                width={{ xs: "30%", md: "3%" }} // Adjusted width based on screen size
              />
              <MDBox
                borderRadius="lg"
                // shadow="lg"
                opacity={1}
                // px={{ xs: 5, sm: 5, md: 5, lg: 5, xl: 5 }} // Adjusted padding for different screen sizes
                ml={2}
                mr={2}
                // py={{ xs: 1, sm: 1, md: 1, lg: 1, xl: 1 }} // Adjusted padding for different screen sizes
                fontSize={{ xs: "sm", sm: "md", md: "md", lg: "lg", xl: "xl" }} // Adjusted font size for different screen sizes
              >
                <MDTypography>
                  <strong>
                    FACULTY OF APPLIED SCIENCES AND TECHNOLOGY - (FAST)
                  </strong>
                </MDTypography>
              </MDBox>
            </>
          )}
          <MDBox
            width={{ xs: 50, sm: 50, md: 0, lg: 100, xl: 100, xxl: 250 }}
          ></MDBox>
          {isMini ? null : (
            <MDBox sx={(theme) => navbarRow(theme, { isMini })}>
              {/* <MDBox ml={2} width={300}>
                <MDTypography variant="h6" fontWeight="medium">
                  {user.email} - {user.role.charAt(0).toUpperCase() + user.role.slice(1)}
                </MDTypography>
              </MDBox> */}
              <MDBox color={light ? "white" : "inherit"}>
                <IconButton
                  size="small"
                  sx={navbarIconButton}
                  onClick={handleProfileIconClick}
                >
                  <AccountCircle sx={iconsStyle} />
                </IconButton>
                {/* Profile Popover */}
                <Popover
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClosePopover}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  PaperProps={{
                    style: { backgroundColor: "#fff", padding: "10px" }, // Apply styles directly to the root element of the popover
                  }}
                >
                  <MDBox p={2}>
                    <ProfileComponent />
                  </MDBox>
                </Popover>
                <IconButton
                  size="small"
                  disableRipple
                  color="inherit"
                  sx={navbarMobileMenu}
                  onClick={handleMiniSidenav}
                >
                  {miniSidenav ? (
                    <MenuOpen sx={iconsStyle} fontSize="medium" />
                  ) : (
                    <MenuSharp sx={iconsStyle} fontSize="medium" />
                  )}
                </IconButton>
                <IconButton
                  size="small"
                  disableRipple
                  color="inherit"
                  sx={navbarIconButton}
                  onClick={handleConfiguratorOpen}
                >
                  <Settings sx={iconsStyle} />
                </IconButton>
                <IconButton
                  size="small"
                  disableRipple
                  color="inherit"
                  sx={navbarIconButton}
                  aria-controls="notification-menu"
                  aria-haspopup="true"
                  variant="contained"
                  onClick={handleOpenMenu}
                >
                  <Notifications sx={iconsStyle} />
                </IconButton>
                {renderMenu()}
              </MDBox>
            </MDBox>
          )}
        </MDBox>
      </Toolbar>
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
