import { useState } from "react";

import { useProgrammeContext } from "hooks/useProgrammeContext";
import { useAuthContext } from "hooks/useAuthContext";
import { API_URL } from "../../config";

export const useEditProgramme = () => {
  const { programmes, dispatch } = useProgrammeContext();
  const { user } = useAuthContext();

  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const editprogramme = async (
    id,
    programmename,
    programmecode,
    programmeduration
  ) => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await fetch(
        `${API_URL}/programmes/editprogramme/${id}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
          body: JSON.stringify({
            programmename,
            programmecode,
            programmeduration,
          }),
        }
      );

      if (!response.ok) {
        const json = await response.json();
        throw new Error(json.error);
      }

      const json = await response.json();
      dispatch({ type: "EDIT_PROGRAMME", payload: json });
      setSuccess("Programme edited successfully");
    } catch (error) {
      setError(error.message || "An error occurred when editing programme");
    } finally {
      setIsLoading(false);
    }
  };

  return { editprogramme, isLoading, error, success, setError, setSuccess };
};
