import { createContext, useReducer } from "react";

/**
 * Use Context and Use Reducer
 *
 * The Context allows us to create something global that can be accessed all over the application or the component
 * The contect also gives use the provider which provides the data needed
 *
 * The useReducer is like a useState but with a dispatch(setState) and the Reducer function
 *
 * Inside the dispatch, we pass {type:'ACTION to be done', payload:[Data to be set]}
 *
 * The reducer function takes a state(The initial state) and the action which is the dispatch(type,payload)
 *
 * Finally we pass the state and disptach to the Provider as a value object
 */
export const StudentsContext = createContext();

export const studentsReducer = (state, action) => {
  switch (action.type) {
    case "GET_STUDENTS":
      return {
        students: action.payload,
      };
    case "ADD_STUDENT":
      return {
        /**
         * action.payload => New student
         * state.students => Contains the previous students
         * ...state.proggrammes => Spread the previous students
         */
        students: [action.payload, ...state.students],
      };
    case "DELETE_STUDENT":
      return {
        /**
         * action.payload => New student
         * state.students => Contains the previous students
         * ...state.students => Spread the previous students
         *
         * Since payload for this has only the id, then we use the id to filter
         */
        students: state.students.filter(
          (student) => student.id !== action.payload
        ),
      };
    case "EDIT_STUDENT":
      return {
        // ...state,
        students: state.students.map((student) =>
          student.id === action.payload.id ? action.payload : student
        ),
      };

    default:
      return state;
  }
};

export const StudentsContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(studentsReducer, {
    students: null,
  });

  return (
    <StudentsContext.Provider value={{ ...state, dispatch }}>
      {children}
    </StudentsContext.Provider>
  );
};
