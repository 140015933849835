import { Link } from "react-router-dom";
import {
  IconButton,
  Divider,
  List,
  ListItem,
  ListItemText,
  Tooltip,
} from "@mui/material";
import { ArrowBack, GetApp, Visibility } from "@mui/icons-material";
import {
  Description,
  PictureAsPdf,
  InsertDriveFile,
} from "@mui/icons-material"; // Import icons for different document types
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

const ViewProject = ({ project, onClick }) => {
  // Dummy project data for testing
  const dummyProject = {
    title: "Sample Project",
    authors: ["John Doe", "Jane Smith"],
    startDate: "2023-01-01",
    lastUpdated: "2023-02-15",
    description:
      "This is a sample project description. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed id nulla non urna aliquet venenatis. Nullam vehicula dolor in risus scelerisque commodo. Nam sed purus eu nunc tristique tincidunt. Nam commodo purus sit amet justo maximus, vel malesuada nibh tincidunt. Nulla facilisi. Nam at nisl et urna tristique laoreet vel ut ex. Donec at leo felis.",
    deliverables: [
      { title: "Concept Paper", type: "PDF Document" },
      { title: "Proposal", type: "PDF Document" },
      { title: "Report", type: "PDF Document" },
      { title: "Abstract", type: "Word Document" },
    ],
  };

  // Use project data if provided, otherwise use dummy data
  project = project || dummyProject;

  return (
    <MDBox p={3}>
      <MDBox display="flex" alignItems="center" mb={3}>
        <IconButton onClick={onClick}>
          <ArrowBack />
        </IconButton>
        <MDTypography variant="h5" ml={1}>
          {project.title}
        </MDTypography>
      </MDBox>

      <Divider />

      {/* Authors */}
      <MDBox mt={3}>
        <MDTypography variant="h6" mb={1}>
          Authors:{" "}
          <span style={{ fontWeight: "normal" }}>
            {project.authors.join(", ")}
          </span>
        </MDTypography>
      </MDBox>

      <Divider />

      {/* Dates */}
      <MDBox mt={3}>
        <MDTypography variant="h6" mb={1}>
          Dates:
        </MDTypography>
        <MDTypography variant="body2" mb={1}>
          <strong>Start Date:</strong> {project.startDate}
        </MDTypography>
        <MDTypography variant="body2">
          <strong>Last Updated:</strong> {project.lastUpdated}
        </MDTypography>
      </MDBox>

      <Divider />

      {/* Description */}
      <MDBox mt={3}>
        <MDTypography variant="h6" mb={1}>
          Description:
        </MDTypography>
        <MDTypography variant="body2" mb={3}>
          {project.description}
        </MDTypography>
      </MDBox>

      <Divider />

      {/* Deliverables */}
      <MDBox mt={3}>
        <MDTypography variant="h6" mb={1}>
          Deliverables:
        </MDTypography>
        {project.deliverables.map((deliverable, index) => (
          <MDBox key={index} display="flex" alignItems="center" mb={1}>
            {/* Render appropriate icon based on document type */}
            <MDBox display="flex" alignItems="center">
              {deliverable.type === "Word Document" && (
                <Description color="info" />
              )}
              {deliverable.type === "PDF Document" && (
                <PictureAsPdf color="error" />
              )}
              {deliverable.type === "Excel File" && (
                <InsertDriveFile color="success" />
              )}
            </MDBox>
            <MDTypography variant="body2" ml={1}>
              {deliverable.title}
            </MDTypography>
            <MDBox display="relative" ml={3}>
              <Tooltip title="Preview">
                <IconButton size="small">
                  <Visibility />
                </IconButton>
              </Tooltip>
              <Tooltip title="Download">
                <IconButton size="small">
                  <GetApp />
                </IconButton>
              </Tooltip>
            </MDBox>
          </MDBox>
        ))}
      </MDBox>
    </MDBox>
  );
};

export default ViewProject;
