import React, { useEffect, useState } from "react";
import MDBox from "components/MDBox";
import { MenuItem, Select, FormControl, InputLabel } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { Publish, VisibilityOff } from "@mui/icons-material";
import { useAuthContext } from "hooks/useAuthContext";
import MDSnackbar from "components/MDSnackbar";
import ApproveDocument from "./approveDocument";
import { API_URL } from "../../../../config";

const CommentSection = ({ closeComments, document }) => {
  const [successSB, setSuccessSB] = useState(false);
  const closeSuccessSB = () => setSuccessSB(false);
  const [sections, setSections] = useState([]);
  const [fields, setFields] = useState([]);
  const { user } = useAuthContext();

  // console.log("Document:", document.title);
  useEffect(() => {
    const fetchComments = async () => {
      try {
        const response = await fetch(
          `${API_URL}/files/retrievecomments/${document.id}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const commentsdata = await response.json();
        if (response.ok) {
          const processedComments = commentsdata.map((comment) => ({
            ...comment,
            newField: false,
          }));
          setSections(processedComments);
        }
      } catch (error) {
        console.error("Error fetching comments:", error);
      }
    };

    const fetchSections = async () => {
      try {
        const response = await fetch(
          `${API_URL}/sections/getsections/${document.title}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${user.token}`,
            },
          }
        );
        const fieldsdata = await response.json();
        if (response.ok) {
          // console.log("Fields:", fieldsdata);
          setFields(fieldsdata);
        }
      } catch (error) {
        console.error("Error fetching comments:", error);
      }
    };

    fetchComments();
    fetchSections();
  }, [document.id]);

  const addSection = () => {
    setSections([
      ...sections,
      { field: "", comment: "", line: "", newField: true },
    ]);
  };

  const handleFieldChange = (index, event) => {
    const newSections = [...sections];
    newSections[index].field = event.target.value;
    setSections(newSections);
  };

  const handleLineChange = (index, event) => {
    const newSections = [...sections];
    newSections[index].line = event.target.value;
    setSections(newSections);
  };

  const handleCommentChange = (index, event) => {
    const newSections = [...sections];
    newSections[index].comment = event.target.value;
    setSections(newSections);
  };

  const removeSection = async (index) => {
    const { id } = sections[index];
    if (id) {
      try {
        const response = await fetch(`${API_URL}/files/deletecomment/${id}`, {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        });
        if (response.ok) {
          const newSections = [...sections];
          newSections.splice(index, 1);
          setSections(newSections);
        } else {
          console.error("Failed to delete comment");
        }
      } catch (error) {
        console.error("Error deleting comment:", error);
      }
    } else {
      const newSections = [...sections];
      newSections.splice(index, 1);
      setSections(newSections);
    }
  };

  const saveComment = async (index) => {
    const { field, comment, line, newField } = sections[index];
    // console.log("Section:", sections[index]);
    if (field && comment && newField) {
      try {
        const response = await fetch(`${API_URL}/files/addcomment`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
          body: JSON.stringify({
            fileId: document.id,
            field: field,
            line: line,
            comment: comment,
          }),
        });
        const data = await response.json();
        if (response.ok) {
          const newSections = [...sections];
          newSections[index] = { ...data, newField: false }; // Assume the backend returns the saved comment with its ID
          setSections(newSections);
        } else {
          console.error("Failed to save comment");
        }
      } catch (error) {
        console.error("Error saving comment:", error);
      }
    }
  };

  const saveAllComments = async () => {
    await Promise.all(
      sections.map(async (section, index) => {
        if (section.newField) {
          await saveComment(index);
        }
      })
    );

    // Change document status to reviewed
    try {
      const response = await fetch(`${API_URL}/files/reviewed/${document.id}`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      });
      const message = await response.json();
      if (response.ok) {
        console.log("Message:", message);
        console.log("Document status updated");
      } else {
        throw new Error("Failed to update document status");
      }
    } catch (error) {
      console.error("Error updating document status:", error);
    }
    setSuccessSB(true);
  };

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 150,
      },
    },
  };

  return (
    <MDBox
      height={450}
      borderRadius="lg"
      boxShadow={1}
      opacity={1}
      shadow="lg"
      p={2}
      overflow="auto"
      position="relative"
    >
      <MDSnackbar
        color="info"
        icon="notifications"
        title="Research Tracker"
        content="All Comments have been saved!!"
        dateTime="Just now"
        open={successSB}
        onClose={closeSuccessSB}
        close={closeSuccessSB}
        vertical="bottom"
        horizontal="right"
        // bgWhite
      />
      <MDBox
        display="flex"
        alignItems="center"
        justifyContent="center"
        position="sticky"
        top={0}
        zIndex={1}
        p={1}
        borderRadius="lg"
        shadow="xs"
      >
        <MDButton
          variant="contained"
          color="dark"
          startIcon={<AddIcon />}
          onClick={addSection}
          sx={{ mb: 2, marginRight: "10px" }}
        >
          Comment
        </MDButton>
        <MDButton
          variant="contained"
          color="info"
          startIcon={<Publish />}
          onClick={saveAllComments}
          sx={{ mb: 2, marginRight: "10px" }}
        >
          Submit
        </MDButton>
        <MDButton
          variant="contained"
          color="error"
          sx={{ mb: 2 }}
          onClick={closeComments}
          iconOnly
        >
          <VisibilityOff />
        </MDButton>
      </MDBox>

      {/* <MDBox>
        <ApproveDocument />
      </MDBox> */}

      {sections.length > 0 ? (
        sections.map((section, index) => (
          <MDBox
            key={index}
            mb={2}
            p={2}
            borderRadius="lg"
            display="flex"
            flexDirection="column"
            alignItems="center"
            sx={{ boxShadow: 2, borderColor: "primary.main" }}
          >
            <FormControl
              variant="outlined"
              fullWidth
              sx={{ minWidth: 200, mr: 2 }}
            >
              <InputLabel id={`field-label-${index}`}>Field</InputLabel>
              <Select
                labelId={`field-label-${index}`}
                value={section.field}
                onChange={(event) => handleFieldChange(index, event)}
                label="Field"
                sx={{ minHeight: 35 }}
                MenuProps={MenuProps}
              >
                <MenuItem value="Other">
                  <em>Other</em>
                </MenuItem>
                {fields.map((field) => (
                  <MenuItem key={field.id} value={field.section}>
                    {field.section}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {/* Specific Line Focus */}
            <MDInput
              label="Focus Line"
              value={section.line}
              onChange={(event) => handleLineChange(index, event)}
              fullWidth
              multiline
              rows={2}
              style={{
                marginTop: "8px",
                marginBottom: "16px",
                marginRight: "14px",
              }}
            />

            {/* Coment Here */}
            <MDInput
              label="Comment"
              value={section.comment}
              onChange={(event) => handleCommentChange(index, event)}
              fullWidth
              multiline
              rows={4}
              style={{
                marginTop: "8px",
                marginBottom: "16px",
                marginRight: "14px",
              }}
            />
            <MDBox display="flex" justifyContent="space-between" width="100%">
              <MDButton
                variant="outlined"
                color="error"
                size="small"
                onClick={() => removeSection(index)}
              >
                Remove
              </MDButton>
              <MDButton
                variant="outlined"
                color="success"
                size="small"
                onClick={() => saveComment(index)}
              >
                Save
              </MDButton>
            </MDBox>
          </MDBox>
        ))
      ) : (
        <ApproveDocument document={document} />
      )}
    </MDBox>
  );
};

export default CommentSection;
