// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React Examples
import TimelineList from "examples/Timeline/TimelineList";
import TimelineItem from "examples/Timeline/TimelineItem";

function OrdersOverview() {
  return (
    <Card sx={{ height: "100%" }}>
      <MDBox pt={3} px={3}>
        <MDTypography variant="h6" fontWeight="medium">
          Progress
        </MDTypography>
        <MDBox mt={0} mb={2}>
          <MDTypography variant="button" color="text" fontWeight="regular">
            <MDTypography
              display="inline"
              variant="body2"
              verticalAlign="middle"
            >
              <Icon sx={{ color: ({ palette: { success } }) => success.main }}>
                arrow_upward
              </Icon>
            </MDTypography>
            &nbsp;
            <MDTypography variant="button" color="text" fontWeight="medium">
              24%
            </MDTypography>{" "}
            this month
          </MDTypography>
        </MDBox>
      </MDBox>
      <MDBox p={2}>
        {/* <TimelineItem
          color="warning"
          icon="notifications"
          title="Data Analysis"
          dateTime="26 FEB 5:00 PM"
          description="Awaiting confrimation from Supervisor"
          // badges={["design"]}
        />
        <TimelineItem
          color="success"
          icon="inventory"
          title="Data Collection"
          dateTime="20 JAN 4:00 PM"
          description="Congratulations on completing this stage"
          // badges={["order", "#1832412"]}
        />
        <TimelineItem
          color="success"
          icon="inventory"
          title="Research Proposal"
          dateTime="20 JAN 4:00 PM"
          description="Congratulations on completing this stage"
          // badges={["order", "#1832412"]}
        />
        <TimelineItem
          color="success"
          icon="inventory"
          title="Literature Review"
          dateTime="20 JAN 4:00 PM"
          description="Congratulations on completing this stage"
          // badges={["order", "#1832412"]}
        />
        <TimelineItem
          color="success"
          icon="inventory"
          title="Topic Selection"
          dateTime="21 DEC 9:34 PM"
          description="Congratulations on completing this stage"
          // badges={["server", "payments"]}
          lastItem
        /> */}
      </MDBox>
    </Card>
  );
}

export default OrdersOverview;
