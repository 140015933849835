import React from "react";
import Slider from "react-slick";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import ArrowRight from "@mui/icons-material/ArrowRight";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import bgImage1 from "assets/images/landingpage/research-tracker-3.jpg";
import bgImage2 from "assets/images/landingpage/research-tracker-2.jpg";
import bgImage3 from "assets/images/landingpage/research-tracker-1.jpg";
import { Link } from "react-router-dom";

// Define your slides
const slides = [
  {
    bgImage: bgImage1,
    title: "Streamline Your Research Projects",
    text: "Effortlessly manage your research tasks and milestones in one unified platform.",
  },
  {
    bgImage: bgImage2,
    title: "Collaborate with Ease",
    text: "Connect with your supervisor, upload documents, share documents, and get feedback.",
  },
  {
    bgImage: bgImage3,
    title: "Track Progress and Achievements",
    text: "Stay on top of your research with clear progress tracking and milestone visualization.",
  },
  // Add more slides as needed
];

function SlidingComponent() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: false,
  };

  return (
    <Slider {...settings}>
      {slides.map((slide, index) => (
        <MDBox
          key={index}
          minHeight="75vh"
          width="100%"
          sx={{
            backgroundImage: `linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(${slide.bgImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "grid",
            placeItems: "center",
            overflow: "hidden",
          }}
        >
          <Container>
            <Grid
              container
              item
              xs={12}
              lg={8}
              justifyContent="left"
              alignItems="center"
              flexDirection="row"
              sx={{ mt: "11rem", mx: "10px", textAlign: "left" }}
            >
              <MDTypography
                variant="h1"
                color="white"
                sx={({ breakpoints, typography: { size } }) => ({
                  [breakpoints.down("md")]: {
                    fontSize: size["3xl"],
                  },
                })}
              >
                {slide.title}
              </MDTypography>
              <MDTypography
                variant="body1"
                color="white"
                opacity={0.8}
                mt={1}
                mb={3}
              >
                {slide.text}
              </MDTypography>
              <MDButton
                color="info"
                variant="gradient"
                component={Link}
                to="/signin"
                size="large"
              >
                get started
                <ArrowRight sx={{ marginLeft: 1 }} />
              </MDButton>
            </Grid>
          </Container>
        </MDBox>
      ))}
    </Slider>
  );
}

export default SlidingComponent;
