// @mui material components
import Grid from "@mui/material/Grid";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

const StatisticsSection = () => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6} lg={3}>
        <MDBox mb={1.5}>
          <ComplexStatisticsCard
            color="dark"
            icon="group"
            title="Total Users"
            count={300}
            percentage={{
              // color: "success",
              // amount: "+55%",
              label: "Overall",
            }}
          />
        </MDBox>
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        <MDBox mb={1.5}>
          <ComplexStatisticsCard
            icon="leaderboard"
            title="Today's Users"
            count="20"
            percentage={{
              color: "success",
              amount: "+3%",
              label: "than yesterday",
            }}
          />
        </MDBox>
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        <MDBox mb={1.5}>
          <ComplexStatisticsCard
            color="success"
            icon="supervisor"
            title="Active Supervisors"
            count="15"
            percentage={{
              color: "success",
              amount: "",
              label: "Overall",
            }}
          />
        </MDBox>
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        <MDBox mb={1.5}>
          <ComplexStatisticsCard
            color="primary"
            icon="person_add"
            title="Pending Approvals"
            count="91"
            percentage={{
              color: "success",
              amount: "",
              label: "Just updated",
            }}
          />
        </MDBox>
      </Grid>
    </Grid>
  );
};

export default StatisticsSection;
