import { useEffect, useState } from "react";

import { FilterAlt, Task } from "@mui/icons-material";
import { Card, MenuItem } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import DataTable from "examples/Tables/DataTable";

//Data
import AddStudentPopup from "../components/addStudentPopup";
import projectsData from "../data/projectsData";
import MDInput from "components/MDInput";
import BulkApprovalsPopup from "../components/bulkApprovalPopup";
import { useAuthContext } from "hooks/useAuthContext";
import { API_URL } from "../../../../config";

const AllProjects = () => {
  const { columns, rows1 } = projectsData();
  const { user } = useAuthContext();
  // const { columns, rows } = studentsData();

  const [openBulkApprovalPopup, setOpenBulkApprovalPopup] = useState(false);
  const [selectedSupervisor, setSelectedSupervisor] = useState("");
  const [supervisors, setSupervisors] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  //Populate data from backend
  const [students, setStudents] = useState([]);

  //Fetch supervisors from the backend
  useEffect(() => {
    const fetchSupervisors = async () => {
      try {
        const response = await fetch(`${API_URL}/supervisors/getsupervisors`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            // Authorization: `Bearer ${user.token}`,
          },
        });

        const supervisorsjson = await response.json();

        if (response.ok) {
          setSupervisors(supervisorsjson);
          // console.log("Supervisors:", supervisorsjson);
        }
        if (!response.ok) {
          throw new Error("Failed to fetch files");
        }
      } catch (error) {
        console.error("Error fetching files:", error);
      }
    };

    fetchSupervisors();
  }, []);
  // Supervisors from Backend ends here

  // Initial setting of rows and handling search filter
  useEffect(() => {
    const handleSearch = () => {
      const filtered = rows1.filter((row) => {
        // const fullName= `${item.Student.first_name} ${item.Student.last_name}`
        const matchesSupervisor = selectedSupervisor
          ? row.supervisor === selectedSupervisor
          : true;
        return matchesSupervisor;
      });
      setFilteredRows(filtered);
    };

    handleSearch();
  }, [rows1, selectedSupervisor]);

  //Fetch projects from the backend which are not yet approved
  const fetchFiles = async () => {
    try {
      const response = await fetch(
        `${API_URL}/projects/getunapprovedprojects`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      const projects = await response.json();
      console.log(projects);

      if (response.ok) {
        if (projects.length > 0) {
          // console.log("Projects:", projects);
          setStudents(projects);
        } else {
          // console.log("Nothing");
          setStudents([]);
        }
      }
      if (!response.ok) {
        throw new Error("Failed to fetch files");
      }
    } catch (error) {
      console.error("Error fetching files:", error);
      setStudents([]); // Set an empty array or handle the error as needed
    }
  };

  const handleOpenBulkApprovalPopup = () => {
    fetchFiles();
    setOpenBulkApprovalPopup(true);
    // console.log("Students in initial:", students);
  };

  const handleCloseBulkApprovalPopup = () => {
    setOpenBulkApprovalPopup(false);
  };
  return (
    <Card>
      <MDBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        px={3}
        pt={3}
      >
        <MDTypography variant="h5" gutterBottom>
          All Projects
        </MDTypography>
      </MDBox>
      <MDBox mx={3} mt={2} px={3}>
        {/* <AddStudentPopup /> */}
        <MDButton
          variant="gradient"
          color="dark"
          size="small"
          startIcon={<Task />}
          onClick={handleOpenBulkApprovalPopup}
        >
          Bulk Approval
        </MDButton>
        <BulkApprovalsPopup
          open={openBulkApprovalPopup}
          onClose={handleCloseBulkApprovalPopup}
          data={students}
        />
        <MDBox display="flex" justifyContent="center" my={1}>
          {/* Filter Label */}
          <MDBox>
            <MDTypography>
              <FilterAlt /> Filter:
            </MDTypography>
          </MDBox>

          {/* Supervisor Filter */}
          <MDBox width="12rem" ml={1}>
            <MDInput
              placeholder="Supervisor"
              label="Supervisor"
              select
              value={selectedSupervisor}
              onChange={(e) => setSelectedSupervisor(e.target.value)}
              size="small"
              fullWidth
              InputProps={{
                style: {
                  height: "30px",
                  padding: "0 12px",
                },
              }}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {supervisors.map((supervisor) => (
                <MenuItem
                  key={supervisor.id}
                  value={`${supervisor.first_name} ${supervisor.last_name}`}
                >
                  {`${supervisor.first_name} ${supervisor.last_name}`}
                </MenuItem>
              ))}
            </MDInput>{" "}
          </MDBox>
        </MDBox>
      </MDBox>
      <MDBox>
        <DataTable
          table={{ columns, rows: filteredRows }}
          isSorted={false}
          // canSearch={true}
          entriesPerPage={false}
          showTotalEntries={true}
          // noEndBorder
        />
      </MDBox>
    </Card>
  );
};

export default AllProjects;
