import React, { useState, useEffect } from 'react';

// @mui material components
import Icon from "@mui/material/Icon";
import { Person, Visibility, CheckCircle, HighlightOff } from "@mui/icons-material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDProgress from "components/MDProgress";
import MDButton from "components/MDButton";

export default function useApprovalData() {
  const [menu, setMenu] = useState(null);

  const openMenu = ({ currentTarget }) => setMenu(currentTarget);
  const closeMenu = () => setMenu(null);
  const renderMenu = (
    <Menu
      id="simple-menu"
      anchorEl={menu}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(menu)}
      onClose={closeMenu}
    >
      <MenuItem onClick={closeMenu}>
        <CheckCircle color="success" />
        <MDTypography variant="inherit" color="text" style={{ marginLeft: '4px' }}>Approve</MDTypography>
      </MenuItem>
      <MenuItem onClick={closeMenu}>
        <HighlightOff color="primary" />
        <MDTypography variant="inherit" color="text" style={{ marginLeft: '4px' }}>Decline</MDTypography>
      </MenuItem>
      <MenuItem onClick={closeMenu}>
        <Visibility color="dark" />
        <MDTypography variant="inherit" color="text" style={{ marginLeft: '4px' }}>Decline</MDTypography>
      </MenuItem>
    </Menu>
  );


  const FirstName = ({ name }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDBox style={{ fontSize: 30 }}><Person /></MDBox>
      <MDTypography display="block" variant="button" color="text" fontWeight="medium" ml={1} lineHeight={1}>
        {name}
      </MDTypography>
    </MDBox>
  );
  const LastName = ({ name }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDTypography display="block" variant="button" color="text" fontWeight="medium" ml={1} lineHeight={1}>
        {name}
      </MDTypography>
    </MDBox>
  );

  return {
    columns: [
      { Header: "first name", accessor: "firstname", align: "left" },
      { Header: "last name", accessor: "lastname", align: "left" },
      { Header: "student email", accessor: "studentemail", align: "center" },
      { Header: "course", accessor: "course", align: "center" },
      { Header: "action", accessor: "action", align: "center" },
    ],

    rows: [
      {
        firstname: <FirstName name="Asana" />,
        lastname: <LastName name="Joseph" />,
        studentemail: <LastName name="mwesigye@gmail.com" />,
        course: <LastName name="SWE" />,
        action: (
          <MDBox color="text" px={2}>
            <Icon sx={{ cursor: "pointer", fontWeight: "bold" }} fontSize="small" onClick={openMenu}>
              more_vert
            </Icon>
            {renderMenu}
          </MDBox>
        ),
      },
      {
        firstname: <FirstName name="Asana" />,
        lastname: <LastName name="Joseph" />,
        studentemail: <LastName name="mwesigye@gmail.com" />,
        course: <LastName name="SWE" />,
        action: (
          <MDBox color="text" px={2}>
            <Icon sx={{ cursor: "pointer", fontWeight: "bold" }} fontSize="small" onClick={openMenu}>
              more_vert
            </Icon>
            {renderMenu}
          </MDBox>
        ),
      },
    ],
  };
}
