import { createContext, useReducer } from "react";

/**
 * Use Context and Use Reducer
 *
 * The Context allows us to create something global that can be accessed all over the application or the component
 * The contect also gives use the provider which provides the data needed
 *
 * The useReducer is like a useState but with a dispatch(setState) and the Reducer function
 *
 * Inside the dispatch, we pass {type:'ACTION to be done', payload:[Data to be set]}
 *
 * The reducer function takes a state(The initial state) and the action which is the dispatch(type,payload)
 *
 * Finally we pass the state and disptach to the Provider as a value object
 */
export const AcademiYearsContext = createContext();

export const academicyearsReducer = (state, action) => {
  switch (action.type) {
    case "GET_ACADEMICYEARS":
      return {
        academicyears: action.payload,
      };
    case "ADD_ACADEMICYEAR":
      return {
        academicyears: [action.payload, ...state.academicyears],
      };
    case "DELETE_ACADEMICYEAR":
      return {
        academicyears: state.academicyears.filter(
          (academicyear) => academicyear.id !== action.payload
        ),
      };
    case "EDIT_ACADEMICYEAR":
      return {
        // ...state,
        academicyears: state.academicyears.map((academicyear) =>
          academicyear.id === action.payload.id ? action.payload : academicyear
        ),
      };

    default:
      return state;
  }
};

export const AcademicYearsContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(academicyearsReducer, {
    academicyears: null,
  });

  return (
    <AcademiYearsContext.Provider value={{ ...state, dispatch }}>
      {children}
    </AcademiYearsContext.Provider>
  );
};
