import React, { useState, useEffect } from "react";
import { useAuthContext } from "hooks/useAuthContext";
import { useProgrammeContext } from "hooks/useProgrammeContext";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Icons
import { Delete, Edit, IndeterminateCheckBox } from "@mui/icons-material";
import { Button, duration } from "@mui/material";
import DeleteProgrammePopup from "../components/deleteProgrammePopup";
import EditProgrammePopup from "../components/editProgrammePopup";
import { API_URL } from "../../../../config";

export default function useData() {
  const { user } = useAuthContext();
  const { programmes, dispatch } = useProgrammeContext();
  const [loading, setLoading] = useState(true); // Add loading state

  //Fetch programmes from the backend
  useEffect(() => {
    const fetchProgrammes = async () => {
      try {
        const response = await fetch(`${API_URL}/programmes/getprogrammes`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        });

        const programmesjson = await response.json();

        if (response.ok) {
          dispatch({ type: "GET_PROGRAMMES", payload: programmesjson });
        }
        if (!response.ok) {
          throw new Error("Failed to fetch files");
        }
      } catch (error) {
        console.error("Error fetching files:", error);
      } finally {
        setLoading(false);
      }
    };

    if (user) {
      fetchProgrammes();
    }
  }, [user, dispatch]);
  // Programmes from Backend ends here

  if (loading) {
    return {
      columns: [],
      rows: [], // or handle loading state differently if you have a loading component
    };
  }

  return {
    columns: [
      { Header: "name", accessor: "name", width: "50%" },
      { Header: "code", accessor: "code", align: "center" },
      { Header: "duration", accessor: "duration", align: "center" },
      { Header: "edit", accessor: "edit", align: "center" },
      // { Header: "delete", accessor: "delete", align: "center" },
    ],

    rows: programmes
      ? programmes.map((item) => ({
          name: item.programmename,
          code: item.programmecode,
          duration: item.programmeduration,
          edit: <EditProgrammePopup programme={item} />,
          // delete: <DeleteProgrammePopup programme={item} />,
        }))
      : [],
  };
}
