import { createContext, useReducer } from "react";

/**
 * Use Context and Use Reducer
 *
 * The Context allows us to create something global that can be accessed all over the application or the component
 * The contect also gives use the provider which provides the data needed
 *
 * The useReducer is like a useState but with a dispatch(setState) and the Reducer function
 *
 * Inside the dispatch, we pass {type:'ACTION to be done', payload:[Data to be set]}
 *
 * The reducer function takes a state(The initial state) and the action which is the dispatch(type,payload)
 *
 * Finally we pass the state and disptach to the Provider as a value object
 */
export const SupervisorsContext = createContext();

export const supervisorsReducer = (state, action) => {
  switch (action.type) {
    case "GET_SUPERVISORS":
      return {
        supervisors: action.payload,
      };
    case "ADD_SUPERVISOR":
      return {
        /**
         * action.payload => New supervisor
         * state.supervisors => Contains the previous supervisors
         * ...state.proggrammes => Spread the previous supervisors
         */
        supervisors: [action.payload, ...state.supervisors],
      };
    case "DELETE_SUPERVISOR":
      return {
        /**
         * action.payload => New supervisor
         * state.supervisors => Contains the previous supervisors
         * ...state.supervisors => Spread the previous supervisors
         *
         * Since payload for this has only the id, then we use the id to filter
         */
        supervisors: state.supervisors.filter(
          (supervisor) => supervisor.id !== action.payload
        ),
      };
    case "EDIT_SUPERVISOR":
      return {
        // ...state,
        supervisors: state.supervisors.map((supervisor) =>
          supervisor.id === action.payload.id ? action.payload : supervisor
        ),
      };

    default:
      return state;
  }
};

export const SupervisorsContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(supervisorsReducer, {
    supervisors: null,
  });

  return (
    <SupervisorsContext.Provider value={{ ...state, dispatch }}>
      {children}
    </SupervisorsContext.Provider>
  );
};
