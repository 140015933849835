// @mui material components
import Grid from "@mui/material/Grid";
// import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import DataTable from "examples/Tables/DataTable";

import projectTableData from "../data/previousProjectData";
import MDInput from "components/MDInput";
import { useEffect, useState } from "react";

const ProjectsTable = () => {
  const { columns, rows } = projectTableData();

  // const { columns, initialRows } = authorsTableData();
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredRows, setFilteredRows] = useState([]);

  const [isFetched, setIsFetched] = useState(false);

  // Initial setting of rows
  useEffect(() => {
    const handlePopulate = () => {
      // console.log("Progress Data Rows:", allRows);
      setFilteredRows(rows);
    };

    if (rows.length > 0 && !isFetched) {
      handlePopulate();
      setIsFetched(true); // Mark the effect as having run
      // console.log("Fetched:", isFetched);
    }
  }, [rows, isFetched]);

  // Function to filter rows based on a search query
  const handleSearch = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);

    // Filter rows based on the search query
    const filtered = rows.filter((row) => {
      // Check if any of the row's values match the search query
      return Object.values(row).some((value) =>
        value.toString().toLowerCase().includes(query)
      );
    });

    // Update the filteredRows state with the filtered results
    setFilteredRows(filtered);
  };

  return (
    <Grid item xs={12}>
      <MDBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        px={3}
        pt={3}
        mb={2}
      >
        <MDBox>
          <MDTypography variant="h6" fontWeight="large">
            All Projects
          </MDTypography>
        </MDBox>
        <MDBox width="12rem" ml="auto">
          <MDInput
            placeholder="Search..."
            value={searchQuery}
            onChange={handleSearch}
            size="small"
            fullWidth
          />
        </MDBox>
      </MDBox>

      <MDBox>
        <DataTable
          table={{ columns, rows: filteredRows }}
          isSorted={true}
          entriesPerPage={false}
          showTotalEntries={true}
          noEndBorder
          // canSearch={true}
        />
      </MDBox>
    </Grid>
  );
};

export default ProjectsTable;
