import { useState } from "react";
import { useAuthContext } from "./useAuthContext";
import { API_URL } from "../config";

export const useHandleBulkApprove = () => {
  const { user } = useAuthContext();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const handleBulkApprove = async (selectedRows) => {
    const projectIds = selectedRows.map((student) => student.id);

    setIsLoading(true);
    setError(null);
    setSuccess(false);

    try {
      const response = await fetch(`${API_URL}/projects/bulkapprove`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
        body: JSON.stringify({ projectIds }),
      });

      if (!response.ok) {
        throw new Error("Failed to approve projects");
      }

      setSuccess(true);
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return { handleBulkApprove, isLoading, error, success, setError, setSuccess };
};
