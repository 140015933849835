import { createContext, useReducer } from "react";

/**
 * Use Context and Use Reducer
 *
 * The Context allows us to create something global that can be accessed all over the application or the component
 * The contect also gives use the provider which provides the data needed
 *
 * The useReducer is like a useState but with a dispatch(setState) and the Reducer function
 *
 * Inside the dispatch, we pass {type:'ACTION to be done', payload:[Data to be set]}
 *
 * The reducer function takes a state(The initial state) and the action which is the dispatch(type,payload)
 *
 * Finally we pass the state and disptach to the Provider as a value object
 */
export const ProgrammesContext = createContext();

export const programmesReducer = (state, action) => {
  switch (action.type) {
    case "GET_PROGRAMMES":
      return {
        programmes: action.payload,
      };
    case "ADD_PROGRAMME":
      return {
        /**
         * action.payload => New Programme
         * state.programmes => Contains the previous programmes
         * ...state.proggrammes => Spread the previous programmes
         */
        programmes: [action.payload, ...state.programmes],
      };
    case "DELETE_PROGRAMME":
      return {
        /**
         * action.payload => New Programme
         * state.programmes => Contains the previous programmes
         * ...state.programmes => Spread the previous programmes
         *
         * Since payload for this has only the id, then we use the id to filter
         */
        programmes: state.programmes.filter(
          (programme) => programme.id !== action.payload
        ),
      };
    case "EDIT_PROGRAMME":
      return {
        // ...state,
        programmes: state.programmes.map((programme) =>
          programme.id === action.payload.id ? action.payload : programme
        ),
      };

    default:
      return state;
  }
};

export const ProgrammesContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(programmesReducer, {
    programmes: null,
  });

  return (
    <ProgrammesContext.Provider value={{ ...state, dispatch }}>
      {children}
    </ProgrammesContext.Provider>
  );
};
