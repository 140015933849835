import { useState } from "react";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import AllProgress from "./tables/allProgressTable";
import ViewProject from "./components/viewProject";
import ReportTrial from "./components/reportsTrial";

function Progress() {
  const [activeTab, setActiveTab] = useState(0);

  // const [allProjects, setAllProjects] = useState(true);
  // const [viewProject, setViewProject] = useState(false);

  // const handleViewProject = () => {
  //   setViewProject(true);
  //   setAllProjects(false);
  // };

  // const handleBackViewProject = () => {
  //   setViewProject(false);
  //   setAllProjects(true);
  // };

  const handleTabType = (event, newValue) => {
    setActiveTab(newValue);
    const tabNames = ["Academic Year", "Programmes", "Students", "Supervisors"];
    console.log(`Switched to tab: ${tabNames[newValue]}`);
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />

      {/* Nav Tabs Here */}
      <Container>
        <Grid container item justifyContent="center" xs={12} lg={12} mx="auto">
          <AppBar position="static">
            <Tabs
              value={activeTab}
              onChange={handleTabType}
              // sx={{ backgroundColor: "grey" }}
              // textColor="primary"
              // indicatorColor="primary"
            >
              <Tab label="All Projects" sx={{ fontWeight: "bold" }} />
              <Tab label="Project Progress" sx={{ fontWeight: "bold" }} />
              <Tab label="Approved" sx={{ fontWeight: "bold" }} />
            </Tabs>
          </AppBar>
        </Grid>
      </Container>
      {/* Nav Tabs end Here */}

      <MDBox mb={2} />

      {activeTab === 0 && <AllProgress />}
      {activeTab === 1 && <ReportTrial />}

      {/* <ViewProject /> */}

      <MDBox mb={2} />

      <Footer />
    </DashboardLayout>
  );
}

export default Progress;
