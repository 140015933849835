import React, { useState } from "react";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import approveImage from "../../../../assets/images/approved.png";
import { Check } from "@mui/icons-material";
import { useAuthContext } from "hooks/useAuthContext";
import MDSnackbar from "components/MDSnackbar";
import { API_URL } from "../../../../config";

const ApproveDocument = ({ document }) => {
  const { user } = useAuthContext();
  const [successSB, setSuccessSB] = useState(false);
  const closeSuccessSB = () => setSuccessSB(false);
  // console.log("Document:", document);
  // console.log("Project Id:", document.ProjectId);
  // console.log("Project Stage:", document.Project.stage);

  // update project progress (33%)
  const updateProgress = async () => {
    try {
      const response = await fetch(
        `${API_URL}/projects/progress/${document.ProjectId}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const progress = await response.json();
      if (response.ok) {
        console.log("Progress:", progress);
        console.log("Progress successfully Updated!!");
      } else {
        throw new Error("Failed to update progress");
      }
    } catch (error) {
      console.error("Error updating progress:", error);
    }
  };

  // update document status (Reviewed/Approved)
  const updateDocumentStatus = async () => {
    try {
      const response = await fetch(`${API_URL}/files/approve/${document.id}`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      });
      const message = await response.json();
      if (response.ok) {
        console.log("Message:", message);
        console.log("Document status updated");
      } else {
        throw new Error("Failed to update document status");
      }
    } catch (error) {
      console.error("Error updating document status:", error);
    }
  };

  // change project stage (Concept Paper/ Proposal)
  const changeProjectStage = async () => {
    try {
      // Get appropriate url
      const getUrl = () => {
        if (document.Project.stage === "Concept Paper") {
          return `${API_URL}/projects/proposal/${document.ProjectId}`;
        } else if (document.Project.stage === "Proposal") {
          return `${API_URL}/projects/report/${document.ProjectId}`;
        } else if (document.Project.stage === "Report") {
          return `${API_URL}/projects/complete/${document.ProjectId}`;
        } else {
          return null;
        }
      };

      const url = getUrl();
      console.log("The url is ", url);

      if (!url) throw new Error("Invalid project stage");

      //Update the project stage
      const response = await fetch(url, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      });
      const message = await response.json();
      if (response.ok) {
        console.log("Message:", message);
        console.log("Project stage updated");
      } else {
        throw new Error("Failed to update project stage");
      }
    } catch (error) {
      console.error("Error updating project stage:", error);
    }
  };

  const handleApprove = async () => {
    console.log("Approved here.");

    // update document status (Reviewed/Approved)
    await updateDocumentStatus();

    // update project progress (33%)
    await updateProgress();

    // change project stage (Concept Paper/ Proposal)
    await changeProjectStage();

    // Send noification in SnackBar
    setSuccessSB(true);

    console.log("Approval done.");
  };
  return (
    <MDBox display="flex" justifyContent="center" mt={2}>
      <MDSnackbar
        color="success"
        icon="check"
        title="Research Tracker"
        content="Document Approved, Student will proceed to the next stage!!"
        dateTime="Just now"
        open={successSB}
        onClose={closeSuccessSB}
        close={closeSuccessSB}
        vertical="bottom"
        horizontal="right"
        // bgWhite
      />
      <MDBox display="flex" flexDirection="column" justifyContent="center">
        <MDTypography
          variant="h5"
          sx={{ color: "black", fontWeight: "bold", textAlign: "center" }}
        >
          If Impressed,
        </MDTypography>
        <MDButton
          variant="contained"
          color="success"
          startIcon={<Check />}
          onClick={handleApprove}
          mt={2}
        >
          Approve
        </MDButton>
      </MDBox>
      <div
        style={{
          width: "250px",
          height: "250px",
          margin: "0",
          padding: "0",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {/* Replace CheckCircleIcon with the image */}
        <img
          src={approveImage}
          alt="Submission Sent"
          style={{ width: "100%", height: "100%" }}
        />
      </div>
    </MDBox>
  );
};

export default ApproveDocument;
