import { useState } from "react";
// import { useParams } from "react-router-dom";
import { useAuthContext } from "./useAuthContext";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../config";

export const useConfirmSignup = () => {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const [success, setSuccess] = useState(null);
  const { dispatch } = useAuthContext();
  const navigate = useNavigate();

  const confirmsignup = async (passcode) => {
    if (!passcode) {
      setIsLoading(false);
      setError("Passcode Missing!");
      return;
    } else if (passcode.toString().length !== 6 || isNaN(passcode)) {
      setIsLoading(false);
      setError("Invalid Code!!");
      return;
    }

    setIsLoading(true);
    setError(false);
    setSuccess(false);

    try {
      const response = await fetch(`${API_URL}/students/confirmsignup`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ passcode }),
      });

      const json = await response.json();

      if (!response.ok) {
        throw new Error(json.error);
      }

      if (response.ok) {
        setSuccess(json.message);
        setIsLoading(false);
        console.log("JSON Response:", json);
      }
      // save the user to local storage
      localStorage.setItem("user", JSON.stringify(json));

      // update the auth context
      dispatch({ type: "LOGIN", payload: json });

      navigate("/", { state: { email: json.studentemail } });
    } catch (error) {
      setError(error.message || "An error occurred during signup");
    } finally {
      setIsLoading(false);
    }
  };

  const resetState = () => {
    setError(null);
    setSuccess(null);
    setIsLoading(false);
  };

  return { confirmsignup, isLoading, error, success, resetState };
};
