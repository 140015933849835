/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MDBox from "components/MDBox";

// Material Kit 2 React examples
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";
import MDBadge from "components/MDBadge";

import image1 from "../../../../assets/images/landingpage/medium-shot-woman-with-laptop-home.jpg";
// import image2 from "../../../../assets/images/landingpage/student-wearing-face-masks-library.jpg";
// import image3 from "../../../../assets/images/landingpage/young-student-learning-library.jpg";

function Information() {
  return (
    <MDBox component="section" py={4}>
      <Container>
        <MDBox
          displaye="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          mb={8}
        >
          <MDBadge
            badgeContent="features"
            variant="contained"
            color="info"
            container
          />
        </MDBox>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} lg={6}>
            <Grid container justifyContent="flex-start">
              {/* Secure Data Storage */}
              <Grid item xs={12} md={6}>
                <MDBox mb={5}>
                  <DefaultInfoCard
                    icon="shield"
                    title="Secure Data Storage"
                    description="Ensure your research data is safe and secure with robust encryption and backup solutions. Protect your work with state-of-the-art security measures."
                  />
                </MDBox>
              </Grid>
              {/* Data Analytics and Reporting */}
              <Grid item xs={12} md={6}>
                <MDBox mb={5}>
                  <DefaultInfoCard
                    icon="barchart"
                    title="Data Analytics and Reporting"
                    description="Generate insightful reports and analytics to understand your project’s progress and performance. Make data-driven decisions to enhance your research outcomes."
                  />
                </MDBox>
              </Grid>
              {/* Version Control */}
              <Grid item xs={12} md={6}>
                <MDBox mb={{ xs: 5, md: 0 }}>
                  <DefaultInfoCard
                    icon="mediation"
                    title="Version Control"
                    description="Track changes and maintain a history of document versions. Ensure you always have access to previous versions and can easily access them if needed."
                  />
                </MDBox>
              </Grid>
              {/* Document Managament and Approval */}
              <Grid item xs={12} md={6}>
                <MDBox mb={{ xs: 5, md: 0 }}>
                  <DefaultInfoCard
                    icon="task"
                    title="Document Management and Approval"
                    description="Upload and manage multiple documents for each project stage. Get approvals from supervisors to move forward with your research seamlessly."
                  />
                </MDBox>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={4} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>
            <CenteredBlogCard
              image={image1}
              title="Simplicity and Ease to use "
              description="A user friendly interface so as to quickly access all the tools and information you need with intuitive and straightforward design. Let's make your research process easy"
              action={{
                type: "internal",
                route: "/signin",
                color: "info",
                label: "get started",
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </MDBox>
  );
}

export default Information;
