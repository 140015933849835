/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MDBox from "components/MDBox";
// import MDTypography from "components/MDTypography";
// import MDButton from "components/MDButton";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// About Us page sections
import Information from "./sections/Information";
// import Team from "./sections/Team";
// import Featuring from "./sections/Featuring";
// import Newsletter from "./sections/Newsletter";

// Routes
// import routes from "routes/routes";
import footerRoutes from "routes/footer.routes";

// Images
// import bgImage from "assets/images/landingpage/bg-about-us.jpg";
// import bgImage from "assets/images/landingpage/research-tracker-3.jpg";
// import { ArrowLeft, ArrowRight } from "@mui/icons-material";
import SlidingComponent from "./sections/slidingComponent";
import Projects from "./sections/Projects";
import FAQ from "./sections/FAQ";
import Footer from "examples/Footer";
import SimpleFooter from "examples/Footers/SimpleFooter";
import CenteredFooter from "examples/Footers/CenteredFooter";

function AboutUs() {
  return (
    <>
      <DefaultNavbar
        // routes={routes}
        // action={{
        //   type: "external",
        //   route: "https://www.creative-tim.com/product/material-kit-react",
        //   label: "free download",
        //   color: "info",
        // }}
        transparent
        light
      />
      <SlidingComponent />
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <section id="features">
          <Information />
        </section>
        <section id="projects">
          <Projects />
        </section>
        <section id="faqs">
          <FAQ />
        </section>

        {/* <Team /> */}
        {/* <Featuring /> */}
        {/* <Newsletter /> */}
      </Card>
      <MDBox px={1}>
        {/* <DefaultFooter content={footerRoutes} /> */}
        <CenteredFooter />
        {/* <Footer /> */}
      </MDBox>
    </>
  );
}

export default AboutUs;
