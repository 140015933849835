import React, { createContext, useContext, useState, useEffect } from 'react';

const ReadStatusContext = createContext();

export const ReadStatusProvider = ({ children }) => {
    const [readStatus, setReadStatus] = useState({});

    const toggleReadStatus = (index) => {
        setReadStatus((prevStatus) => ({
            ...prevStatus,
            [index]: true
        }));

        console.log("Toggle Read Status executed");
        // You may also want to update the backend here to persist the read status
    };


    console.log('Read Status:', readStatus);

    return (
        <ReadStatusContext.Provider value={{ readStatus, toggleReadStatus }}>
            {children}
        </ReadStatusContext.Provider>
    );
};

export const useReadStatus = () => {
    const context = useContext(ReadStatusContext);
    if (context === undefined) {
        throw new Error('useReadStatus must be used within a ReadStatusProvider');
    }
    return context;
};
