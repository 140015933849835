import { useState } from "react";
// import { useParams } from "react-router-dom";
import { useAuthContext } from "./useAuthContext";
import { API_URL } from "../config";

export const useResendEmail = () => {
  const [error1, setError] = useState(null);
  const [isLoading1, setIsLoading] = useState(null);
  const [success1, setSuccess] = useState(null);
  const { dispatch } = useAuthContext();

  const resendemail = async (email) => {
    if (!email) {
      setIsLoading(false);
      setError("Email Missing!");
      return;
    }

    setIsLoading(true);
    setError(false);
    setSuccess(false);

    try {
      const response = await fetch(`${API_URL}/students/resendotpemail`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email }),
      });

      const json = await response.json();

      console.log("JSON response is ", json.message);

      if (!response.ok) {
        throw new Error(json.error);
      }

      if (response.ok) {
        setSuccess(json.message);
        setIsLoading(false);
        console.log(json);
      }
      // // save the user to local storage
      // localStorage.setItem("user", JSON.stringify(json));

      // // update the auth context
      // dispatch({ type: "LOGIN", payload: json });
    } catch (error) {
      setError(error.message || "An error occurred during signup");
    } finally {
      setIsLoading(false);
    }
  };

  const resetState = () => {
    setError(null);
    setSuccess(null);
    setIsLoading(false);
  };

  return { resendemail, isLoading1, error1, success1, resetState };
};
