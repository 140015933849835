import { useState } from "react";

import { useAuthContext } from "./useAuthContext";

import { syncLocalStorageWithBackend } from "utils/localStorageUtils";
import { API_URL } from "../config";

export const useHandleProjectSubmission = () => {
  const { user } = useAuthContext();
  const studentEmail = user.email;

  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleprojectsubmission = async (
    projectTitle,
    projectDescription,
    supervisorEmail
  ) => {
    if (!projectTitle || !projectDescription || !supervisorEmail) {
      setError("All fields are required!!!");
      return; // Exit early if validation fails
    }

    setIsLoading(true);
    setError(null);

    try {
      const response = await fetch(`${API_URL}/projects/registerproject`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
        body: JSON.stringify({
          projectTitle,
          projectDescription,
          supervisorEmail,
          studentEmail,
        }),
      });

      if (!response.ok) {
        const json = await response.json();
        throw new Error(json.error);
      }

      const json = await response.json();
      setSuccess(json.message);

      // save the user to local storage
      // localStorage.setItem('user', JSON.stringify(json));
      await syncLocalStorageWithBackend(user.email);

      // update the auth context
      // dispatch({ type: 'LOGIN', payload: json });
    } catch (error) {
      setError(
        error.message || "An error occurred during registering the project!!"
      );
    } finally {
      setIsLoading(false);
    }
  };

  return {
    handleprojectsubmission,
    isLoading,
    error,
    success,
    setError,
    setSuccess,
  };
};
