import React from "react";
import PropTypes from "prop-types";
import Collapse from "@mui/material/Collapse";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

function FaqCollapse({ title, open, onClick, children }) {
  return (
    <MDBox my={2}>
      <MDBox
        display="flex"
        alignItems="center"
        onClick={onClick}
        style={{ cursor: "pointer" }}
      >
        <MDTypography variant="h4" gutterBottom color="text" flex={1}>
          {title}
        </MDTypography>
        <IconButton size="large">
          {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      </MDBox>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <MDBox mt={2}>
          <MDTypography variant="body2" fontWeight="regular" color="text">
            {children}
          </MDTypography>
        </MDBox>
      </Collapse>
      <Divider
        style={{ marginTop: 8, borderColor: "#adb5bd", borderWidth: 0.5 }}
      />
    </MDBox>
  );
}

FaqCollapse.propTypes = {
  title: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default FaqCollapse;
