import React, { useState, useEffect } from "react";
import { useHandleBulkApprove } from "hooks/useHandleBulkApprove";
import { useAuthContext } from "hooks/useAuthContext";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  Button,
} from "@mui/material";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

const BulkApprovalsPopup = ({ open, onClose, data }) => {
  const { user } = useAuthContext();
  const [selectedRows, setSelectedRows] = useState([]);
  // const [selectedProjectIds, setSelectedProjectIds] = useState([]);

  //Populate data from backend
  const [students, setStudents] = useState([]);

  //Fetch projects from the backend which are not yet approved
  useEffect(() => {
    // const fetchFiles = async () => {
    //   try {
    //     const response = await fetch(
    //       `${API_URL}/projects/getunapprovedprojects`,
    //       {
    //         method: "GET",
    //         headers: {
    //           "Content-Type": "application/json",
    //           Authorization: `Bearer ${user.token}`,
    //         },
    //       }
    //     );

    //     const projects = await response.json();
    //     console.log(projects);

    //     if (response.ok) {
    //       if (projects.length > 0) {
    //         // console.log("Projects:", projects);
    //         setStudents(projects);
    //       } else {
    //         // console.log("Nothing");
    //         setStudents([]);
    //       }
    //     }
    //     if (!response.ok) {
    //       throw new Error("Failed to fetch files");
    //     }
    //   } catch (error) {
    //     console.error("Error fetching files:", error);
    //     setStudents([]); // Set an empty array or handle the error as needed
    //   }
    // };

    // if (user) {
    //   fetchFiles();
    // }
    setStudents(data);
    // console.log("Students in other", students);
  }, [data]);
  // Data from Backend ends here

  const { handleBulkApprove, isLoading, error, success, setError, setSuccess } =
    useHandleBulkApprove();

  const handleCheckboxChange = (event, student) => {
    if (event.target.checked) {
      setSelectedRows([...selectedRows, student]);
    } else {
      setSelectedRows(selectedRows.filter((row) => row !== student));
    }
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      setSelectedRows([...students]); // Select all students
    } else {
      setSelectedRows([]);
    }
  };

  const handleApprove = async () => {
    await handleBulkApprove(selectedRows);
    console.log("Approved:", selectedRows);
    // Reload the whole page
    window.location.reload();
    handleClose();
  };

  const handleClose = () => {
    setSelectedRows([]);
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="md"
      fullWidth
      align="center"
    >
      <DialogTitle>Bulk Approvals</DialogTitle>
      <DialogContent>
        <TableContainer>
          <Table>
            {/* <TableHead> */}
            <TableRow>
              <TableCell>
                <Checkbox onChange={handleSelectAllClick} />
              </TableCell>
              <TableCell>
                <MDTypography variant="h6">Student</MDTypography>
              </TableCell>
              <TableCell>
                <MDTypography variant="h6">Reg Number</MDTypography>
              </TableCell>
              <TableCell>
                <MDTypography variant="h6">Programme</MDTypography>
              </TableCell>
              <TableCell>
                <MDTypography variant="h6">Project</MDTypography>
              </TableCell>
              <TableCell>
                <MDTypography variant="h6">Supervisor</MDTypography>
              </TableCell>
            </TableRow>
            {/* </TableHead> */}
            <TableBody>
              {students.map((student) => (
                <TableRow key={student.id}>
                  <TableCell>
                    <Checkbox
                      checked={selectedRows.includes(student)}
                      onChange={(event) => handleCheckboxChange(event, student)}
                    />
                  </TableCell>
                  <TableCell>
                    <MDTypography variant="body2">
                      {student.studentname}
                    </MDTypography>
                  </TableCell>
                  <TableCell>
                    <MDTypography variant="body2">
                      {student.regnumber}
                    </MDTypography>
                  </TableCell>
                  <TableCell>
                    <MDTypography variant="body2">
                      {student.programme}
                    </MDTypography>
                  </TableCell>
                  <TableCell>
                    <MDTypography variant="body2">
                      {student.projectTitle}
                    </MDTypography>
                  </TableCell>
                  <TableCell>
                    <MDTypography variant="body2">
                      {student.supervisorname}
                    </MDTypography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <MDButton onClick={handleClose} variant="outlined" color="info">
          Cancel
        </MDButton>
        <MDButton
          onClick={handleApprove}
          variant="gradient"
          color="info"
          disabled={isLoading}
        >
          Approve
        </MDButton>
      </DialogActions>
    </Dialog>
  );
};

export default BulkApprovalsPopup;
