import { useState } from "react";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Preview Route page components
import AllPreviousProjects from "./components/allPreviousProjects";
import Header from "./components/Header";
import ViewProject from "./components/viewProject";

function PreviousProjects() {
  const [showAllPreviousProjects, setShowAllPreviousProjects] = useState(true);

  const handleToggleComponent = () => {
    setShowAllPreviousProjects((prevState) => !prevState);
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      {/* <MDBox mb={2} /> */}
      <Header>
        {showAllPreviousProjects ? (
          <AllPreviousProjects onClick={handleToggleComponent} />
        ) : (
          <ViewProject onClick={handleToggleComponent} />
          // <PreviousSubmissions />
        )}
        {/* <ProjectDetails /> */}
        {/* <AllPreviousProjects /> */}
      </Header>
      <Footer />
    </DashboardLayout>
  );
}

export default PreviousProjects;
