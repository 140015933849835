import { useEffect, useState } from "react";
import { useAuthContext } from "hooks/useAuthContext";
import { useSupervisorContext } from "hooks/useSupervisorContext";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";

// Icons
import { Delete, Edit } from "@mui/icons-material";
import MDBadge from "components/MDBadge";
import EditSupervisorPopup from "../components/editSupervisorPopup";
import { API_URL } from "../../../../config";

export default function useData() {
  const { user } = useAuthContext();
  const { supervisors, dispatch } = useSupervisorContext();
  const [loading, setLoading] = useState(true);

  //Fetch supervisors from the backend
  useEffect(() => {
    const fetchSupervisors = async () => {
      try {
        const response = await fetch(`${API_URL}/supervisors/getsupervisors`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        });

        const supervisorsjson = await response.json();

        if (response.ok) {
          dispatch({ type: "GET_SUPERVISORS", payload: supervisorsjson });
          console.log(supervisorsjson);
        }
        if (!response.ok) {
          throw new Error("Failed to fetch files");
        }
      } catch (error) {
        console.error("Error fetching files:", error);
      } finally {
        setLoading(false);
      }
    };

    if (user) {
      fetchSupervisors();
    }
  }, [user, dispatch]);
  // Supervisors from Backend ends here

  if (loading) {
    return {
      columns: [],
      rows1: [], // or handle loading state differently if you have a loading component
    };
  }

  const rows1 = supervisors
    ? supervisors.map((item) => {
        // Determine availability status for the specific academic year
        const availability = item.Availabilities.find(
          (availability) => availability.academic_year === "2019/2020"
        );

        const isAvailable = availability ? availability.available : false;

        return {
          name: `${item.first_name} ${item.last_name}`,
          email: item.email,
          status: (
            <MDBox ml={-1}>
              <MDBadge
                badgeContent={isAvailable ? "Available" : "Not Available"}
                color={isAvailable ? "success" : "dark"}
                variant="gradient"
                size="sm"
              />
            </MDBox>
          ),
          edit: (
            // <Edit
            //   sx={{ cursor: "pointer", fontWeight: "bold" }}
            //   fontSize="small"
            //   color="warning"
            // />
            <EditSupervisorPopup supervisor={item} />
          ),
        };
      })
    : [];

  return {
    columns: [
      { Header: "name", accessor: "name", align: "center" },
      { Header: "email", accessor: "email", align: "center" },
      { Header: "status", accessor: "status", align: "center" },
      { Header: "edit", accessor: "edit", align: "center" },
    ],

    rows1: rows1,
  };
}
