import React, { useState, useEffect } from "react";
import { useAuthContext } from "hooks/useAuthContext";
import { useProjectContext } from "hooks/useProjectContext";
import { useQuickStatsContext } from "context/QuickStatsContext";

import { useHandleApproveProject } from "hooks/useHandleApprove";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Icons
import {
  Assignment,
  CheckCircle,
  MoreVert,
  Publish,
  Save,
  Visibility,
} from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Menu,
  MenuItem,
} from "@mui/material";

import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import SimpleModal from "components/MDModal";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import Divider from "@mui/material/Divider";
import Slide from "@mui/material/Slide";

// @mui icons
import CloseIcon from "@mui/icons-material/Close";
import { API_URL } from "../../../../config";

export default function useData() {
  const { user } = useAuthContext();
  const { projects, dispatch } = useProjectContext();
  const { fetchQuickStats } = useQuickStatsContext();
  const [loading, setLoading] = useState(true); // Add loading state

  const [openDialog, setOpenDialog] = useState(false);
  const [newSupervisorId, setNewSupervisorId] = useState("");
  const [supervisors, setSupervisors] = useState([]);

  //For More Vert
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedProject, setSelectedProject] = useState("");

  //View Project
  const [show, setShow] = useState(false);
  const toggleModal = () => {
    setShow(!show);
    console.log(selectedProject);
  };

  const {
    handleapproveproject,
    isLoading,
    error,
    success,
    setError,
    setSuccess,
  } = useHandleApproveProject();

  //Fetch Projects and Supervisor from the backend
  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await fetch(`${API_URL}/projects/getprojects`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        });

        const projectsjson = await response.json();

        if (response.ok) {
          dispatch({ type: "GET_PROJECTS", payload: projectsjson });
          // console.log("Projects:", projectsjson);
        }
        if (!response.ok) {
          throw new Error("Failed to fetch files");
        }
      } catch (error) {
        console.error("Error fetching files:", error);
      } finally {
        setLoading(false);
      }
    };

    const fetchSupervisors = async () => {
      try {
        const response = await fetch(`${API_URL}/supervisors/getsupervisors`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            // Authorization: `Bearer ${user.token}`,
          },
        });

        const supervisorsjson = await response.json();

        if (response.ok) {
          setSupervisors(supervisorsjson);
          // console.log("Supervisors:", supervisorsjson);
        }
        if (!response.ok) {
          throw new Error("Failed to fetch files");
        }
      } catch (error) {
        console.error("Error fetching files:", error);
      }
    };

    if (user) {
      fetchProjects();
      fetchSupervisors();
    }
  }, [user, dispatch]);
  // Projects from Backend ends here

  //All About the More Vert
  const handleMenuOpen = (event, project) => {
    setAnchorEl(event.currentTarget);
    setSelectedProject(project);
    // console.log("Project:", project);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedProject("");
    setNewSupervisorId("");
  };

  const handleApprove = async () => {
    // Handle approve action
    await handleapproveproject(selectedProject.id);
    handleMenuClose();
  };

  const handleViewDetails = () => {
    console.log("View Details:", selectedProject);
    toggleModal();
    // Handle view details action
    handleMenuClose();
  };

  const handleReassign = async () => {
    // Handle reassign supervisor action
    try {
      const response = await fetch(
        `${API_URL}/projects/reassignsupervisor/${selectedProject.id}/${newSupervisorId}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      if (response.ok) {
        const updatedProject = await response.json();
        dispatch({ type: "EDIT_PROJECT", payload: updatedProject });
        await fetchQuickStats(); // Refetch quick stats after reassigning a supervisor
        setOpenDialog(false); // Close the dialog
      } else {
        throw new Error("Failed to reassign supervisor");
      }
    } catch (error) {
      console.error("Error reassigning supervisor:", error);
    } finally {
      handleMenuClose();
    }
    setOpenDialog(false);
    handleMenuClose();
  };
  //All About the More Vert ends here

  if (loading) {
    return {
      columns: [],
      rows1: [], // or handle loading state differently if you have a loading component
    };
  }

  return {
    columns: [
      { Header: "student", accessor: "student", align: "center" },
      { Header: "project", accessor: "project", align: "center" },
      { Header: "supervisor", accessor: "supervisor", align: "center" },
      { Header: "status", accessor: "status", align: "center" },
      { Header: "action", accessor: "action", align: "center" },
    ],

    rows1: projects
      ? projects.map((item) => ({
          student: `${item.Student.first_name} ${item.Student.last_name}`,
          project: item.projectTitle,
          supervisor: `${item.Supervisor.first_name} ${item.Supervisor.last_name}`,
          status: item.is_approved ? (
            <MDBox ml={-1}>
              <MDBadge
                badgeContent="approved"
                color="success"
                variant="gradient"
                size="sm"
              />
            </MDBox>
          ) : (
            <MDBox ml={-1}>
              <MDBadge
                badgeContent="pending"
                color="dark"
                variant="gradient"
                size="sm"
              />
            </MDBox>
          ),
          action: (
            <div>
              <MoreVert
                sx={{ cursor: "pointer", fontWeight: "bold" }}
                fontSize="small"
                // color="warning"
                onClick={(event) => handleMenuOpen(event, item)}
              />
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
              >
                <MenuItem onClick={handleApprove}>
                  <CheckCircle color="success" /> Approve
                </MenuItem>
                <MenuItem onClick={() => setOpenDialog(true)}>
                  <Assignment color="warning" />
                  Reassign Supervisor
                </MenuItem>
                <MenuItem onClick={handleViewDetails}>
                  <Visibility color="dark" /> View Details
                </MenuItem>
              </Menu>
              <div>
                <Dialog
                  open={openDialog}
                  onClose={() => setOpenDialog(false)}
                  BackdropProps={{
                    style: {
                      backgroundColor: "rgba(0, 0, 0, 0.2)", // Adjust the opacity to make it lighter
                    },
                  }}
                  maxWidth="sm"
                  fullWidth
                >
                  <DialogTitle>Reassign Supervisor</DialogTitle>
                  <DialogContent>
                    <MDBox display="flex" flexDirection="column" gap={2}>
                      <MDBox>
                        <MDTypography>
                          <strong>Current Supervisor:</strong>{" "}
                          {selectedProject &&
                            selectedProject.Supervisor.first_name}{" "}
                          {selectedProject &&
                            selectedProject.Supervisor.last_name}
                        </MDTypography>
                      </MDBox>
                      <MDBox width={500}>
                        <MDInput
                          fullWidth
                          label="Select Supervisor"
                          select
                          placeholder="Supervisor"
                          value={newSupervisorId}
                          onChange={(e) => setNewSupervisorId(e.target.value)}
                          InputProps={{
                            style: {
                              height: "50px",
                              padding: "0 20px",
                            },
                          }}
                        >
                          {selectedProject &&
                            supervisors
                              .filter(
                                (sup) =>
                                  sup.email !== selectedProject.supervisorEmail
                              ) // Filter out the current supervisor
                              .map((sup) => (
                                <MenuItem key={sup.id} value={sup.id}>
                                  {`${sup.first_name} ${sup.last_name}`}
                                </MenuItem>
                              ))}
                        </MDInput>
                      </MDBox>
                    </MDBox>
                  </DialogContent>
                  <DialogActions>
                    <MDButton
                      onClick={() => setOpenDialog(false)}
                      color="info"
                      variant="outlined"
                    >
                      Cancel
                    </MDButton>
                    <MDButton
                      onClick={handleReassign}
                      color="info"
                      startIcon={<Save />}
                    >
                      Reassign
                    </MDButton>
                  </DialogActions>
                </Dialog>
              </div>
              <div>
                <Modal
                  open={show}
                  onClose={toggleModal}
                  sx={{ display: "grid", placeItems: "center" }}
                  BackdropProps={{
                    style: {
                      backgroundColor: "rgba(0, 0, 0, 0.2)", // Adjust the opacity to make it lighter
                    },
                  }}
                >
                  <Slide direction="down" in={show} timeout={500}>
                    <MDBox
                      position="relative"
                      width="500px"
                      display="flex"
                      flexDirection="column"
                      borderRadius="xl"
                      bgColor="white"
                      shadow="xl"
                    >
                      <MDBox
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        p={2}
                      >
                        <MDTypography variant="h5">
                          {selectedProject ? selectedProject : "Loading1..."}
                        </MDTypography>
                        <CloseIcon
                          fontSize="medium"
                          sx={{ cursor: "pointer" }}
                          onClick={toggleModal}
                        />
                      </MDBox>
                      <Divider sx={{ my: 0 }} />
                      <MDBox p={2}>
                        <MDTypography
                          variant="body2"
                          color="secondary"
                          fontWeight="regular"
                        >
                          Society has put up so many boundaries, so many
                          limitations on what&apos;s right and wrong that
                          it&apos;s almost impossible to get a pure thought out.
                          <br />
                          <br />
                          It&apos;s like a little kid, a little boy, looking at
                          colors, and no one told him what colors are good,
                          before somebody tells you you shouldn&apos;t like pink
                          because that&apos;s for girls, or you&apos;d instantly
                          become a gay two-year-old.
                        </MDTypography>
                      </MDBox>
                      <Divider sx={{ my: 0 }} />
                      <MDBox
                        display="flex"
                        justifyContent="space-between"
                        p={1.5}
                      >
                        <MDButton
                          variant="gradient"
                          color="dark"
                          onClick={toggleModal}
                        >
                          close
                        </MDButton>
                        <MDButton variant="gradient" color="info">
                          save changes
                        </MDButton>
                      </MDBox>
                    </MDBox>
                  </Slide>
                </Modal>
              </div>
            </div>
          ),
        }))
      : [],
  };
}
