import {
  ArrowBack,
  Description,
  GetApp,
  InsertDriveFile,
  PictureAsPdf,
  Visibility,
} from "@mui/icons-material";
import { Card, Divider, IconButton, Tooltip } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import LandingpageNavbar from "examples/Navbars/LandingpageNavBar";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PDFViewer from "./pdfViewer";
import { API_URL } from "../../../../../config";

const downloadFile = async (id) => {
  try {
    const response = await fetch(`${API_URL}/files/download/${id}`, {
      method: "GET",
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const contentDisposition = response.headers.get("Content-Disposition");
    let filename = "downloaded-file"; // Default filename
    if (contentDisposition) {
      const match = contentDisposition.match(/filename="?([^"]+)"?/);
      if (match && match[1]) {
        filename = match[1];
      }
    }

    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename; // You can set the filename here
    document.body.appendChild(a);
    a.click();
    a.remove();
  } catch (error) {
    console.error("There was an error downloading the file:", error);
  }
};

const ViewProjectAll = () => {
  const location = useLocation();
  const navigate = useNavigate();
  var project = location.state?.project;

  const [showPreview, setShowPreview] = useState(false);

  const handlePreviewClick = () => {
    setShowPreview(true);
  };

  const handleBackClick = () => {
    setShowPreview(false);
  };

  // console.log("Project:", project);

  // Dummy project data for testing
  const dummyProject = {
    title: "Sample Project",
    authors: ["John Doe", "Jane Smith"],
    startDate: "2023-01-01",
    lastUpdated: "2023-02-15",
    description:
      "This is a sample project description. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed id nulla non urna aliquet venenatis. Nullam vehicula dolor in risus scelerisque commodo. Nam sed purus eu nunc tristique tincidunt. Nam commodo purus sit amet justo maximus, vel malesuada nibh tincidunt. Nulla facilisi. Nam at nisl et urna tristique laoreet vel ut ex. Donec at leo felis.",
    deliverables: [
      { title: "Concept Paper", type: "PDF Document" },
      { title: "Proposal", type: "PDF Document" },
      { title: "Report", type: "PDF Document" },
      { title: "Abstract", type: "Word Document" },
    ],
  };

  // Use project data if provided, otherwise use dummy data
  project = project || dummyProject;
  // project = dummyProject;

  const handleDownloadClick = (fileId) => {
    console.log("File ID:", fileId);
    const id = 14;
    downloadFile(id);
  };

  return (
    <MDBox
      sx={({ breakpoints, transitions, functions: { pxToRem } }) => ({
        p: 3,
        position: "relative",

        [breakpoints.up("xl")]: {
          // marginLeft: miniSidenav ? pxToRem(120) : pxToRem(274),
          marginLeft: pxToRem(50),
          transition: transitions.create(["margin-left", "margin-right"], {
            easing: transitions.easing.easeInOut,
            duration: transitions.duration.standard,
          }),
        },
      })}
    >
      <LandingpageNavbar />
      {!showPreview ? (
        <Card>
          <MDBox p={3}>
            <MDBox display="flex" alignItems="center" mb={3}>
              <IconButton
                onClick={() => {
                  navigate(-1);
                }}
              >
                <ArrowBack />
              </IconButton>
              <MDTypography variant="h5" ml={1}>
                {project.title}
              </MDTypography>
            </MDBox>

            <Divider />

            {/* Authors */}
            <MDBox mt={3}>
              <MDTypography variant="h6" mb={1}>
                Authors:{" "}
                <span style={{ fontWeight: "normal" }}>
                  {project.authors.join(", ")}
                </span>
              </MDTypography>
            </MDBox>

            <Divider />

            {/* Dates */}
            <MDBox mt={3}>
              <MDTypography variant="h6" mb={1}>
                Dates:
              </MDTypography>
              <MDTypography variant="body2" mb={1}>
                <strong>Start Date:</strong> {project.startDate}
              </MDTypography>
              <MDTypography variant="body2">
                <strong>Last Updated:</strong> {project.lastUpdated}
              </MDTypography>
            </MDBox>

            <Divider />

            {/* Description */}
            <MDBox mt={3}>
              <MDTypography variant="h6" mb={1}>
                Description:
              </MDTypography>
              <MDTypography variant="body2" mb={3}>
                {project.description}
              </MDTypography>
            </MDBox>

            <Divider />

            {/* Deliverables */}
            <MDBox mt={3}>
              <MDTypography variant="h6" mb={1}>
                Deliverables:
              </MDTypography>
              {project.deliverables.map((deliverable, index) => (
                <MDBox key={index} display="flex" alignItems="center" mb={1}>
                  {/* Render appropriate icon based on document type */}
                  <MDBox display="flex" alignItems="center">
                    {deliverable.type === "Word Document" && (
                      <Description color="info" />
                    )}
                    {deliverable.type === "PDF Document" && (
                      <PictureAsPdf color="error" />
                    )}
                    {deliverable.type === "Excel File" && (
                      <InsertDriveFile color="success" />
                    )}
                  </MDBox>
                  <MDTypography variant="body2" ml={1}>
                    {deliverable.title}
                  </MDTypography>

                  {/* Icons */}
                  <MDBox display="relative" ml={3}>
                    <Tooltip title="Preview">
                      <IconButton size="small" onClick={handlePreviewClick}>
                        <Visibility />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Download">
                      <IconButton
                        size="small"
                        onClick={() => {
                          handleDownloadClick(index);
                        }}
                      >
                        <GetApp />
                      </IconButton>
                    </Tooltip>
                  </MDBox>
                </MDBox>
              ))}
            </MDBox>
          </MDBox>
        </Card>
      ) : (
        <PDFViewer document={project} onClose={handleBackClick} />
      )}
    </MDBox>
  );
};

export default ViewProjectAll;
