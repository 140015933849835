// Hooks
import { useForgotPassword } from "hooks/useForgotPassword";
import { useState } from "react";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import bgImage from "assets/images/faculty-building.jpg";
import { Link } from "react-router-dom";
import { ArrowBack } from "@mui/icons-material";

function Cover() {
  const [email, setEmail] = useState("");
  const { forgotpassword, error, isLoading, success } = useForgotPassword();

  const handleSubmit = async (e) => {
    e.preventDefault();

    await forgotpassword(email);
  };

  return (
    <CoverLayout coverHeight="50vh" image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          py={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h3" fontWeight="medium" color="white" mt={1}>
            Reset Password
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            You will receive an e-mail in maximum 60 seconds
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={4}>
              <MDInput
                type="email"
                label="Email"
                variant="standard"
                onChange={(e) => setEmail(e.target.value)}
                fullWidth
              />
            </MDBox>
            <MDBox
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <MDButton
                component={Link}
                to="/signin"
                variant="text"
                color="info"
                textGradient
                startIcon={<ArrowBack />}
              >
                Back to Login
              </MDButton>
              <MDButton
                variant="gradient"
                color="info"
                onClick={handleSubmit}
                disabled={isLoading}
              >
                reset
              </MDButton>
            </MDBox>
            <MDBox mt={2} textAlign="center">
              <MDTypography variant="button" color="text">
                Don&apos;t have an account?{" "}
                <MDTypography
                  component={Link}
                  to="/signup"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Sign up
                </MDTypography>
              </MDTypography>
            </MDBox>
            {error && (
              <MDBox
                borderRadius="4px"
                mt={2}
                style={{
                  textAlign: "center",
                  border: "1px solid #f44335", // Error redish color for box border
                  backgroundColor: "rgba(244, 67, 53, 0.2)", // Lighter transparent redish color for box background
                }}
              >
                <MDTypography color="error">{error}</MDTypography>
              </MDBox>
            )}
            {success && (
              <MDBox
                borderRadius="4px"
                px={3}
                mx={2}
                mt={2}
                style={{
                  textAlign: "center",
                  border: "1px solid #4caf50",
                  backgroundColor: "rgba(76, 175, 80, 0.2)",
                }}
              >
                <MDTypography color="success">{success}</MDTypography>
              </MDBox>
            )}
          </MDBox>
        </MDBox>
      </Card>
    </CoverLayout>
  );
}

export default Cover;
