import React, { useState, useEffect } from "react";
import { useAuthContext } from "hooks/useAuthContext";
import { useProgrammeContext } from "hooks/useProgrammeContext";
import { useStudentContext } from "hooks/useStudentContext";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Icons
import {
  Delete,
  Edit,
  IndeterminateCheckBox,
  MoreVert,
} from "@mui/icons-material";
import { Button } from "@mui/material";
import DeleteProgrammePopup from "../components/deleteProgrammePopup";
import EditProgrammePopup from "../components/editProgrammePopup";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";
import { API_URL } from "../../../../config";

export default function useData() {
  const { user } = useAuthContext();
  const { students, dispatch } = useStudentContext();
  const [loading, setLoading] = useState(true); // Add loading state

  //Fetch students from the backend
  useEffect(() => {
    const fetchStudents = async () => {
      try {
        const response = await fetch(`${API_URL}/students/getstudents`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        });

        const studentsjson = await response.json();

        if (response.ok) {
          dispatch({ type: "GET_STUDENTS", payload: studentsjson });
          console.log(studentsjson);
        }
        if (!response.ok) {
          throw new Error("Failed to fetch files");
        }
      } catch (error) {
        console.error("Error fetching files:", error);
      } finally {
        setLoading(false);
      }
    };

    if (user) {
      fetchStudents();
    }
  }, [user, dispatch]);
  // Students from Backend ends here

  if (loading) {
    return {
      columns: [],
      rows1: [], // or handle loading state differently if you have a loading component
    };
  }

  return {
    columns: [
      { Header: "name", accessor: "name", align: "center" },
      { Header: "email", accessor: "email", align: "center" },
      { Header: "reg number", accessor: "regnumber", align: "center" },
      { Header: "programme", accessor: "programme", align: "center" },
      {
        Header: "phone number",
        accessor: "phonenumber",
        align: "center",
      },
      { Header: "academic year", accessor: "academicyear", align: "center" },
      { Header: "status", accessor: "status", align: "center" },
      { Header: "action", accessor: "action", align: "center" },
    ],

    rows1: students
      ? students.map((item) => ({
          name: item.studentname,
          email: item.email,
          regnumber: item.regNumber,
          programme: item.programme,
          phonenumber: item.phoneNumber,
          academicyear: item.academicyear,
          status: item.is_approved ? (
            <MDBox ml={-1}>
              <MDBadge
                badgeContent="approved"
                color="success"
                variant="gradient"
                size="sm"
              />
            </MDBox>
          ) : (
            <MDBox ml={-1}>
              <MDBadge
                badgeContent="pending"
                color="dark"
                variant="gradient"
                size="sm"
              />
            </MDBox>
          ),
          action: (
            <Edit
              sx={{ cursor: "pointer", fontWeight: "bold" }}
              fontSize="small"
              color="warning"
            />
          ),
        }))
      : [],

    // rows: [
    //   {
    //     name: "Mwesigye Joseph",
    //     email: "2020bse@std.must.ac.ug",
    //     regnumber: "2020/BSE/043/PS",
    //     programme: "Bachelor of Science in Software Engineering",
    //     phonenumber: "0708460967",
    //     academicyear: (
    //       <MDTypography
    //         display="block"
    //         variant="button"
    //         color="text"
    //         fontWeight="medium"
    //       >
    //         2020/2021
    //       </MDTypography>
    //     ),
    //     action: (
    //       <Edit
    //         sx={{ cursor: "pointer", fontWeight: "bold" }}
    //         fontSize="small"
    //         color="warning"
    //       />
    //     ),
    //   },
    // ],
  };
}
