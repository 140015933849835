import { Card } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

const RegistrationSection = ({ onClick }) => {
  const handleClick = () => {
    // Additional functionality
    // Call other functions here if needed
    onClick();
  };
  return (
    <Card sx={{ height: "100%" }}>
      <MDBox mt={3} display="flex" justifyContent="center" textAlign="center">
        <MDTypography variant="h5" fontWeight="medium" gutterBottom>
          Get Started With Your Research Journey
        </MDTypography>
      </MDBox>
      <MDBox
        pt={3}
        px={3}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="space-evenly"
        height="80%"
      >
        <MDTypography variant="body2" fontWeight="medium" gutterBottom>
          <b>Status:</b>{" "}
          <span style={{ color: "#f44335" }}>No Project Registered</span>
        </MDTypography>
        <MDButton
          variant="gradient"
          color="info"
          sx={{ marginTop: 2, marginBottom: 2 }}
          onClick={handleClick}
        >
          Register
        </MDButton>
      </MDBox>
    </Card>
  );
};

export default RegistrationSection;
