import React, { useRef } from "react";
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Grid,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { AccountCircle, Print } from "@mui/icons-material";
import MUSTlogo from "../../../../assets/images/logos/must.png";
import MDButton from "components/MDButton";

import { useReactToPrint } from "react-to-print";

// import "../css/printStyle.css";

const PrintComponent = React.forwardRef((props, ref) => {
  const studentInfo = {
    name: "John Doe",
    regNumber: "123456",
    programme: "Computer Science",
    academicYear: "2020/2021",
    email: "john.doe@example.com",
    phoneNumber: "123-456-7890",
    projectName: "Video Upscaler for resource-constrained environments",
    projectStage: "Concept Paper",
    supervisorName: "Dr. Smith",
    supervisorEmail: "dr.smith@example.com",
  };

  const conceptpapers = [
    {
      name: "Document 1",
      version: "1.0",
      status: "Approved",
      submissionDate: "2024-01-01",
      reviewDate: "2024-01-05",
    },
    {
      name: "Document 2",
      version: "2.0",
      status: "Pending",
      submissionDate: "2024-02-01",
      reviewDate: "N/A",
    },
  ];

  const proposals = [
    {
      name: "The Ultimate Proposal",
      version: "1.0",
      status: "Under Review",
      submissionDate: "2024-01-01",
      reviewDate: "2024-01-05",
    },
    {
      name: "The Ultimate Proposal",
      version: "2.0",
      status: "Pending",
      submissionDate: "2024-02-01",
      reviewDate: null,
    },
  ];

  const reports = [];

  const renderTable = (documents) => (
    <TableContainer component={Paper}>
      <Table>
        <TableRow>
          <TableCell>
            <MDTypography
              variant="h6"
              textTransform="uppercase"
              sx={{ fontSize: "12px" }}
            >
              Document Name
            </MDTypography>
          </TableCell>
          <TableCell align="center">
            <MDTypography
              variant="h6"
              textTransform="uppercase"
              sx={{ fontSize: "12px" }}
            >
              Version
            </MDTypography>
          </TableCell>
          <TableCell align="center">
            <MDTypography
              variant="h6"
              textTransform="uppercase"
              sx={{ fontSize: "12px" }}
            >
              Status
            </MDTypography>
          </TableCell>
          <TableCell align="center">
            <MDTypography
              variant="h6"
              textTransform="uppercase"
              sx={{ fontSize: "12px" }}
            >
              Submission Date
            </MDTypography>
          </TableCell>
          <TableCell align="center">
            <MDTypography
              variant="h6"
              textTransform="uppercase"
              sx={{ fontSize: "12px" }}
            >
              Review Date
            </MDTypography>
          </TableCell>
        </TableRow>
        <TableBody>
          {documents.map((doc, index) => (
            <TableRow key={index}>
              <TableCell>
                <MDTypography variant="body2">
                  {doc.name ? doc.name : "N/A"}
                </MDTypography>
              </TableCell>
              <TableCell align="center">
                <MDTypography variant="body2">
                  {doc.version ? doc.version : "N/A"}
                </MDTypography>
              </TableCell>
              <TableCell align="center">
                <MDTypography variant="body2">
                  {doc.status ? doc.status : "N/A"}
                </MDTypography>
              </TableCell>
              <TableCell align="center">
                <MDTypography variant="body2">
                  {doc.submissionDate ? doc.submissionDate : "N/A"}
                </MDTypography>
              </TableCell>
              <TableCell align="center">
                <MDTypography variant="body2">
                  {doc.reviewDate ? doc.reviewDate : "N/A"}
                </MDTypography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const renderStudentInfo = (label, value, isEmail = false) => (
    <Grid container>
      <Grid item xs={6} md={4}>
        <MDTypography
          variant="h6"
          fontWeight="regular"
          sx={{ fontFamily: "Times New Roman", fontSize: "16px" }}
        >
          {label}:
        </MDTypography>
      </Grid>
      <Grid item xs={6} md={8}>
        <MDTypography
          variant="h6"
          fontWeight="bold"
          sx={{
            fontSize: "14px",
            textTransform: isEmail ? "none" : "uppercase",
          }}
        >
          {value}
        </MDTypography>
      </Grid>
    </Grid>
  );

  return (
    <MDBox bgColor="inherit">
      <MDBox p={2}>
        <MDButton
          variant="gradient"
          color="info"
          startIcon={<Print />}
          onClick={props.handlePrint}
        >
          Print
        </MDButton>
      </MDBox>
      <Card ref={ref}>
        <MDBox
          ml={4}
          mt={4}
          color="inherit"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "left",
          }}
        >
          <MDBox
            component="img"
            src={MUSTlogo}
            alt="master card"
            width={{ xs: "30%", md: "8%" }}
          />
          <MDBox borderRadius="lg" ml={2} mr={2}>
            <MDTypography>
              <strong>
                FACULTY OF APPLIED SCIENCES AND TECHNOLOGY - (FAST)
              </strong>
            </MDTypography>
          </MDBox>
        </MDBox>
        <MDBox p={3}>
          <MDTypography variant="h5" align="center" gutterBottom>
            Student Info
          </MDTypography>
          <MDBox p={2} border={1} borderRadius={2} mb={5}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} md={5}>
                {renderStudentInfo("Name", studentInfo.name)}
                {renderStudentInfo("Programme", studentInfo.programme)}
                {renderStudentInfo("Academic Year", studentInfo.academicYear)}
                {renderStudentInfo("Email", studentInfo.email, true)}
                {renderStudentInfo("Phone Number", studentInfo.phoneNumber)}
              </Grid>
              <Grid item xs={12} sm={6} md={5}>
                {renderStudentInfo(
                  "Registration Number",
                  studentInfo.regNumber
                )}
                {renderStudentInfo("Project Name", studentInfo.projectName)}
                {renderStudentInfo("Project Stage", studentInfo.projectStage)}
                {renderStudentInfo(
                  "Supervisor Name",
                  studentInfo.supervisorName
                )}
                {renderStudentInfo(
                  "Supervisor Email",
                  studentInfo.supervisorEmail,
                  true
                )}
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <MDBox
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  border={2}
                  borderRadius={4}
                  p={2}
                >
                  <MDBox sx={{ fontSize: 80 }}>
                    <AccountCircle />
                  </MDBox>
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>
          <MDTypography variant="h5" align="center" gutterBottom>
            Concept Paper
          </MDTypography>
          <MDBox p={2} border={1} borderRadius={2} mb={5}>
            {conceptpapers.length > 0 ? (
              renderTable(conceptpapers)
            ) : (
              <MDBox>
                <MDTypography variant="button" align="center">
                  N/A
                </MDTypography>
              </MDBox>
            )}
          </MDBox>
          <MDTypography variant="h5" align="center" gutterBottom>
            Proposal
          </MDTypography>
          <MDBox p={2} border={1} borderRadius={2} mb={5}>
            {proposals.length > 0 ? (
              renderTable(proposals)
            ) : (
              <MDBox>
                <MDTypography variant="button" align="center">
                  N/A
                </MDTypography>
              </MDBox>
            )}
          </MDBox>
          <MDTypography variant="h5" align="center" gutterBottom>
            Report
          </MDTypography>
          <MDBox p={2} border={1} borderRadius={2} mb={5}>
            {reports.length > 0 ? (
              renderTable(reports)
            ) : (
              <MDBox>
                <MDTypography variant="button" align="center">
                  N/A
                </MDTypography>
              </MDBox>
            )}
          </MDBox>
        </MDBox>
      </Card>
    </MDBox>
  );
});

const ReportTrial = () => {
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Research Tracker Report",
    onBeforeGetContent: () => {
      return new Promise((resolve) => {
        const style = document.createElement("style");
        style.innerHTML = `
          @page {
            size: 11in 17in landscape;
          }
        `;
        document.head.appendChild(style);
        resolve();
      });
    },
  });

  return <PrintComponent ref={componentRef} handlePrint={handlePrint} />;
};

export default ReportTrial;
