import { ProjectsContext } from "context/ProjectContext";
import { useContext } from "react";

export const useProjectContext = () => {
  const context = useContext(ProjectsContext);

  if (!context) {
    throw Error(
      "useProjectContext must be used inside an ProjectsContextProvider"
    );
  }

  return context;
};
