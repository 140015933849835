import React, { useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Dashboard components
import Projects from "layouts/studentLayouts/dashboard/components/Projects";
import OrdersOverview from "layouts/studentLayouts/dashboard/components/OrdersOverview";
import ProfileSection from "./components/profileSection";
import RegistrationSection from "./components/registrationSection";

const GetStarted = ({ onClick }) => {
  const handleClick = () => {
    // Additional functionality
    // Call other functions here if needed
    onClick();
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6} lg={8}>
        {/* <Projects onClick={onSubmit} /> */}
        <ProfileSection />
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        {/* <OrdersOverview /> */}
        <RegistrationSection onClick={handleClick} />
      </Grid>
    </Grid>
  );
};

export default GetStarted;
