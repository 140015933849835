import React, { useState, useEffect } from "react";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

//My components
// import StatisticsSection from "mycomponents/Coordinator/coordinatorDashboard/statisticsSection";
import ApprovalsTable from "mycomponents/Coordinator/coordinatorDashboard/approvalsTable";
import AddStudent from "mycomponents/Coordinator/coordinatorDashboard/addStudentPopup";
import Chatting from "mycomponents/Coordinator/coordinatorDashboard/chatTrial";
import StatisticsSection from "./components/statisticsSection";

function Dashboard() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <StatisticsSection />
        {/* <ApprovalsTable /> */}
        {/* <AddStudent /> */}
        {/* <Chatting /> */}
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
