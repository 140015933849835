import React, { useState, useEffect } from 'react';

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

//My components
import StatisticsSection from "mycomponents/DepartmentCoordinator/departmentcoordinatorDashboard/statisticsSection";
import ApprovalsTable from "mycomponents/DepartmentCoordinator/departmentcoordinatorDashboard/approvalsTable";
import AddStudent from 'mycomponents/DepartmentCoordinator/departmentcoordinatorDashboard/addStudentPopup';
import Chatting from 'mycomponents/DepartmentCoordinator/departmentcoordinatorDashboard/chatTrial';


function Dashboard() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <StatisticsSection />
        <ApprovalsTable />
        {/* <AddStudent /> */}
        {/* <Chatting /> */}
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
