import { useState } from "react";

import { useAcademiYearContext } from "hooks/useAcademicYearContext";
import { useAuthContext } from "hooks/useAuthContext";
import { API_URL } from "../../config";

export const useEditAcademicYear = () => {
  const { academicyears, dispatch } = useAcademiYearContext();
  const { user } = useAuthContext();

  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const editacademicyear = async (id, academicyear) => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await fetch(`${API_URL}/academicyears/edityear/${id}`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
        body: JSON.stringify({
          year: academicyear,
        }),
      });

      if (!response.ok) {
        const json = await response.json();
        throw new Error(json.error);
      }

      const json = await response.json();
      dispatch({ type: "EDIT_ACADEMICYEAR", payload: json });
      setSuccess("Academic Year edited successfully");
    } catch (error) {
      setError(error.message || "An error occurred when editing Academic Year");
    } finally {
      setIsLoading(false);
    }
  };

  return { editacademicyear, isLoading, error, success, setError, setSuccess };
};
