import React, { useState } from "react";
import MDBox from "components/MDBox";
// import MDTypography from "components/MDTypography";

import Patience from "../components/patience";
import { useAuthContext } from "hooks/useAuthContext";
import { Card, Grid, Menu } from "@mui/material";
import DetailSection from "../components/detailSection";
import ProposalDocumentSection from "../components/proposalDocumentSection";
import MDTypography from "components/MDTypography";
import { MoreVert, PictureAsPdf, Slideshow } from "@mui/icons-material";
import NotificationItem from "examples/Items/NotificationItem";
import ProposalPresentationSection from "../components/proposalPresentationSection";

const Proposal = ({ onSubmit }) => {
  const { user } = useAuthContext();
  const stage = user.project.stage;

  const [menu, setMenu] = useState(null);

  const [presentation, setPresentation] = useState(false);
  const [documentation, setDocumentation] = useState(true);
  const [title, setTitle] = useState("Documentation");

  const openMenu = ({ currentTarget }) => {
    setMenu(currentTarget);
  };
  const closeMenu = () => setMenu(null);

  const handlePresentationClick = () => {
    setTitle("Presentation");
    setPresentation(true);
    setDocumentation(false);
    closeMenu();
  };

  const handleDocumentationClick = () => {
    setTitle("Documentation");
    setDocumentation(true);
    setPresentation(false);
    closeMenu();
  };

  const renderMenu = (
    <Menu
      id="simple-menu"
      anchorEl={menu}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(menu)}
      onClose={closeMenu}
    >
      <NotificationItem
        icon={<Slideshow sx={{ color: "#FF9800" }} />}
        title="Presentation"
        onClick={handlePresentationClick}
      />
      <NotificationItem
        icon={<PictureAsPdf color="error" />}
        title="Document"
        onClick={handleDocumentationClick}
      />
    </Menu>
  );

  return stage === "Concept Paper" ? (
    <Patience />
  ) : stage === "Proposal" ? (
    <MDBox>
      <Grid container spacing={3}>
        <Grid item xs={12} md={8} lg={8}>
          <Card sx={{ height: "100%" }}>
            <MDBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              px={3}
              pt={3}
            >
              <MDBox ml={2}>
                <MDTypography variant="h5" gutterBottom>
                  {title}
                </MDTypography>
              </MDBox>
              <MDBox color="text" px={2}>
                <MoreVert
                  sx={{ cursor: "pointer", fontWeight: "bold" }}
                  fontSize="small"
                  onClick={openMenu}
                />
              </MDBox>
              {renderMenu}
            </MDBox>
            {presentation && <ProposalPresentationSection onClick={onSubmit} />}
            {documentation && <ProposalDocumentSection onClick={onSubmit} />}
            {/* <ProposalDocumentSection onClick={onSubmit} /> */}
          </Card>
        </Grid>
        <Grid item xs={12} md={4} lg={4}>
          <DetailSection />
        </Grid>
      </Grid>
    </MDBox>
  ) : (
    <Card sx={{ height: "100%" }}>
      <MDBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        px={3}
        pt={3}
      >
        <MDBox ml={2}>
          <MDTypography variant="h5" gutterBottom>
            {title}
          </MDTypography>
        </MDBox>
        <MDBox color="text" px={2}>
          <MoreVert
            sx={{ cursor: "pointer", fontWeight: "bold" }}
            fontSize="small"
            onClick={openMenu}
          />
        </MDBox>
        {renderMenu}
      </MDBox>
      {presentation && <ProposalPresentationSection />}
      {documentation && <ProposalDocumentSection />}
    </Card>
  );
};

export default Proposal;
