import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Grid from "@mui/material/Grid";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import { Box, Button, TextField } from "@mui/material";
import MDTypography from "components/MDTypography";

import { Edit, Save } from "@mui/icons-material";

import { useEditProgramme } from "hooks/settingsHooks/useEditProgramme";

const EditProgrammePopup = ({ programme }) => {
  const [open, setOpen] = useState(false);
  const [programmename, setProgrammename] = useState("");
  const [programmecode, setProgrammecode] = useState("");
  const [programmeduration, setProgrammeduration] = useState("");
  const { editprogramme, error, isLoading, success, setError, setSuccess } =
    useEditProgramme();

  useEffect(() => {
    if (programme) {
      setProgrammename(programme.programmename);
      setProgrammecode(programme.programmecode);
      setProgrammeduration(programme.programmeduration);
    }
  }, [programme]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log(programme.id, programmename, programmecode, programmeduration);
    await editprogramme(
      programme.id,
      programmename,
      programmecode,
      programmeduration
    );
    handleClose();
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);

    // Clear error on close
    setError(null);
    setSuccess(null);
  };

  return (
    <div>
      <Button onClick={handleClickOpen}>
        <Edit sx={{ cursor: "pointer", fontWeight: "bold" }} color="warning" />
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          style: { padding: 20, borderRadius: 10 },
        }}
      >
        <DialogTitle>Edit Programme</DialogTitle>
        <DialogContent>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <TextField
              label="Programme name..."
              name="programmeName"
              value={programmename}
              onChange={(e) => setProgrammename(e.target.value)}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Programme code..."
              name="programmeCode"
              value={programmecode}
              onChange={(e) => setProgrammecode(e.target.value)}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Programme duration..."
              name="programmeDuration"
              value={programmeduration}
              onChange={(e) => setProgrammeduration(e.target.value)}
              fullWidth
              margin="normal"
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <MDButton onClick={handleClose}>Cancel</MDButton>
          <MDButton
            onClick={handleSubmit}
            variant="contained"
            color="info"
            disabled={isLoading}
            startIcon={<Save />}
          >
            Save
          </MDButton>
        </DialogActions>
        {error && (
          <MDBox
            borderRadius="4px"
            px={3}
            mx={2}
            mb={2}
            style={{
              textAlign: "center",
              border: "1px solid #f44335", // Error redish color for box border
              backgroundColor: "rgba(244, 67, 53, 0.2)", // Lighter transparent redish color for box background
            }}
          >
            <MDTypography color="error">{error}</MDTypography>
          </MDBox>
        )}
        {success && (
          <MDBox
            borderRadius="4px"
            px={3}
            mx={2}
            mb={2}
            style={{
              textAlign: "center",
              border: "1px solid #4caf50",
              backgroundColor: "rgba(76, 175, 80, 0.2)",
            }}
          >
            <MDTypography color="success">{success}</MDTypography>
          </MDBox>
        )}
      </Dialog>
    </div>
  );
};

export default EditProgrammePopup;
