import { useState } from "react";
import { useLogin } from "hooks/useLogin";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import MUSTlogo from "../../../assets/images/logos/must.png";
import facultyimage from "assets/images/faculty-building.jpg";
import { ArrowForward } from "@mui/icons-material";

function Basic() {
  const [rememberMe, setRememberMe] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { login, error, isLoading } = useLogin();

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const handleSubmit = async (e) => {
    e.preventDefault();

    await login(email, password);
  };

  return (
    <BasicLayout image={facultyimage}>
      <Card>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox textAlign="center">
            <MDBox
              component="img"
              src={MUSTlogo}
              alt="must logo"
              width={{ xs: "30%", md: "30%" }} // Adjusted width based on screen size
            />
            <MDTypography variant="h4" fontWeight="medium" mb={1}>
              Sign in
            </MDTypography>
          </MDBox>
          <MDBox component="form" role="form" onSubmit={handleSubmit}>
            <MDBox mb={2}>
              <MDInput
                type="email"
                label="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                fullWidth
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                fullWidth
              />
            </MDBox>
            <MDBox display="flex" alignItems="center" ml={-1}>
              <Switch checked={rememberMe} onChange={handleSetRememberMe} />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                onClick={handleSetRememberMe}
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;Remember me
              </MDTypography>
            </MDBox>
            <MDBox
              mt={4}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <MDTypography
                component={Link}
                to="/forgotpassword"
                variant="button"
                color="info"
                fontWeight="medium"
                textGradient
              >
                Forgot Password?
              </MDTypography>
              <MDButton
                type="submit"
                variant="gradient"
                color="info"
                endIcon={<ArrowForward />}
                disabled={isLoading}
              >
                sign in
              </MDButton>
            </MDBox>
            <MDBox mt={3} textAlign="center">
              <MDTypography variant="button" color="text">
                Don&apos;t have an account?{" "}
                <MDTypography
                  component={Link}
                  to="/signup"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Sign up
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
        <MDBox textAlign="center">
          {error && (
            <MDBox
              borderRadius="4px"
              px={3}
              mx={2}
              mb={2}
              style={{
                border: "1px solid #f44335", // Error redish color for box border
                backgroundColor: "rgba(244, 67, 53, 0.2)", // Lighter transparent redish color for box background
              }}
            >
              <MDTypography color="error">{error}</MDTypography>
            </MDBox>
          )}
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
