// Hooks
import { useResetPassword } from "hooks/useResetPassword";
import { useState } from "react";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import bgImage from "assets/images/faculty-building.jpg";
import { Link } from "react-router-dom";
import { ArrowBack } from "@mui/icons-material";

function Cover() {
  const [newPassword, setNewPassword] = useState("");
  const [newConfirmPassword, setNewConfirmPassword] = useState("");
  const { resetpassword, error, isLoading, success } = useResetPassword();

  const handleSubmit = async (e) => {
    e.preventDefault();

    await resetpassword(newPassword, newConfirmPassword);
  };

  return (
    <CoverLayout coverHeight="10vh" image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          py={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h3" fontWeight="medium" color="white" mt={1}>
            Reset Password
          </MDTypography>
        </MDBox>
        <MDBox pt={2} pb={2} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Enter New Password"
                variant="standard"
                onChange={(e) => setNewPassword(e.target.value)}
                fullWidth
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Confirm New Password"
                variant="standard"
                onChange={(e) => setNewConfirmPassword(e.target.value)}
                fullWidth
              />
            </MDBox>
            <MDBox
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <MDButton
                variant="gradient"
                color="info"
                onClick={handleSubmit}
                disabled={isLoading}
              >
                reset
              </MDButton>
            </MDBox>
            {error && (
              <MDBox
                borderRadius="4px"
                px={3}
                mx={2}
                mt={2}
                style={{
                  textAlign: "center",
                  border: "1px solid #f44335", // Error redish color for box border
                  backgroundColor: "rgba(244, 67, 53, 0.2)", // Lighter transparent redish color for box background
                }}
              >
                <MDTypography color="error">{error}</MDTypography>
              </MDBox>
            )}
            {success && (
              <MDBox display="flex" flexDirection="column">
                <MDBox
                  borderRadius="4px"
                  px={3}
                  mx={2}
                  mt={2}
                  style={{
                    textAlign: "center",
                    border: "1px solid #4caf50",
                    backgroundColor: "rgba(76, 175, 80, 0.2)",
                  }}
                >
                  <MDTypography color="success">success</MDTypography>
                </MDBox>
                <MDButton
                  component={Link}
                  to="/signin"
                  variant="text"
                  color="info"
                  textGradient
                  startIcon={<ArrowBack />}
                >
                  Go to Login
                </MDButton>
              </MDBox>
            )}
          </MDBox>
        </MDBox>
      </Card>
    </CoverLayout>
  );
}

export default Cover;
