import React, { useEffect } from "react";
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Grid,
  IconButton,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { AccountCircle, ArrowBack } from "@mui/icons-material";
import DataTable from "examples/Tables/DataTable";

// File Data
import conceptData from "../data/conceptData";
import proposalData from "../data/proposalData";
import reportData from "../data/reportData";

import theme from "assets/theme";
import { useLocation, useNavigate } from "react-router-dom";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

const ViewProject = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const project = location.state?.project;

  //   useEffect(() => {
  //     console.log("Project:", project);
  //   }, []);

  //   const email = "2020bce044@std.must.ac.ug";

  const { columns: conceptcolumns, rows: conceptrows } = conceptData(
    project.studentEmail
  );
  const { columns: proposalcolumns, rows: proposalrows } = proposalData(
    project.studentEmail
  );
  const { columns: reportcolumns, rows: reportrows } = reportData(
    project.studentEmail
  );

  const studentInfo = {
    name: "John Doe",
    regNumber: "123456",
    programme: "Computer Science",
    academicYear: "2020/2021",
    email: "john.doe@example.com",
    phoneNumber: "123-456-7890",
    projectName: "Video Upscaler for resouce contrained environments",
    projectStage: "Concept Paper",
    supervisorName: "Dr. Smith",
    supervisorEmail: "dr.smith@example.com",
  };

  const conceptpapers = [
    {
      name: "Document 1",
      version: "1.0",
      status: "Approved",
      submissionDate: "2024-01-01",
      reviewDate: "2024-01-05",
    },
    {
      name: "Document 2",
      version: "2.0",
      status: "Pending",
      submissionDate: "2024-02-01",
      reviewDate: "N/A",
    },
  ];
  const proposals = [
    {
      name: "The Ultimate Proposal",
      version: "1.0",
      status: "Under Review",
      submissionDate: "2024-01-01",
      reviewDate: "2024-01-05",
    },
    {
      name: "The Ultimate Proposal",
      version: "2.0",
      status: "Pending",
      submissionDate: "2024-02-01",
      reviewDate: null,
    },
  ];
  const reports = [];

  const renderTable = (documents) => (
    <TableContainer component={Paper}>
      <Table>
        {/* <TableHead> */}
        <TableRow>
          <TableCell>
            <MDTypography variant="h6">Document Name</MDTypography>
          </TableCell>
          <TableCell align="center">
            <MDTypography variant="h6">Version</MDTypography>
          </TableCell>
          <TableCell align="center">
            <MDTypography variant="h6">Status</MDTypography>
          </TableCell>
          <TableCell align="center">
            <MDTypography variant="h6">Submission Date</MDTypography>
          </TableCell>
          <TableCell align="center">
            <MDTypography variant="h6">Review Date</MDTypography>
          </TableCell>
        </TableRow>
        {/* </TableHead> */}
        <TableBody>
          {documents.map((doc, index) => (
            <TableRow key={index}>
              <TableCell>
                <MDTypography variant="body2">
                  {doc.name ? doc.name : "N/A"}
                </MDTypography>
              </TableCell>
              <TableCell align="center">
                <MDTypography variant="body2">
                  {doc.version ? doc.version : "N/A"}
                </MDTypography>
              </TableCell>
              <TableCell align="center">
                <MDTypography variant="body2">
                  {doc.status ? doc.status : "N/A"}
                </MDTypography>
              </TableCell>
              <TableCell align="center">
                <MDTypography variant="body2">
                  {doc.submissionDate ? doc.submissionDate : "N/A"}
                </MDTypography>
              </TableCell>
              <TableCell align="center">
                <MDTypography variant="body2">
                  {doc.reviewDate ? doc.reviewDate : "N/A"}
                </MDTypography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const renderStudentInfo = (label, value, isEmail = false) => (
    <Grid container>
      <Grid item xs={6} md={4}>
        <MDTypography
          variant="h6"
          fontWeight="regular"
          sx={{ fontFamily: "Times New Roman", fontSize: "16px" }}
        >
          {label}:
        </MDTypography>
      </Grid>
      <Grid item xs={6} md={8}>
        <MDTypography
          variant="h6"
          fontWeight="bold"
          sx={{
            // fontFamily: "Times New Roman",
            fontSize: "14px",
            textTransform: isEmail ? "none" : "uppercase",
          }}
        >
          {value}
        </MDTypography>
      </Grid>
    </Grid>
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card>
        <MDBox p={3}>
          <IconButton
            onClick={() => {
              navigate(-1);
            }}
          >
            <ArrowBack />
          </IconButton>

          {/* Student Info */}
          <MDTypography
            variant="h5"
            align="center"
            gutterBottom
            // sx={{ fontFamily: "Times New Roman", fontSize: "12px" }}
          >
            Student Info
          </MDTypography>
          <MDBox
            p={2}
            border={1}
            borderRadius={2}
            mb={5}
            sx={{
              borderColor: theme.palette.grey[500], // Lighter border color
            }}
          >
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} md={5}>
                {renderStudentInfo(
                  "Name",
                  `${project.Student.first_name} ${project.Student.last_name}`
                )}
                {renderStudentInfo("Programme", project.Student.programme)}
                {renderStudentInfo(
                  "Academic Year",
                  project.Student.academicyear
                )}
                {renderStudentInfo("Email", project.Student.email, true)}
                {renderStudentInfo("Phone Number", project.Student.phoneNumber)}
              </Grid>
              <Grid item xs={12} sm={6} md={5}>
                {renderStudentInfo(
                  "Registration Number",
                  project.Student.regNumber
                )}
                {renderStudentInfo("Project Name", project.projectTitle)}
                {renderStudentInfo("Project Stage", project.stage)}
                {renderStudentInfo(
                  "Supervisor Name",
                  `${project.Supervisor.first_name} ${project.Supervisor.last_name}`
                )}
                {renderStudentInfo(
                  "Supervisor Email",
                  project.Supervisor.email,
                  true
                )}
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <MDBox
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  border={2}
                  borderRadius={4}
                  p={2}
                >
                  <MDBox sx={{ fontSize: 80 }}>
                    <AccountCircle />
                  </MDBox>
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>

          {/* Rendering Tables */}
          {/* Concept Table */}
          <MDTypography
            variant="h5"
            align="center"
            // sx={{ fontFamily: "Times New Roman", fontSize: "12px" }}
            gutterBottom
          >
            Concept Paper
          </MDTypography>
          <MDBox
            p={2}
            border={1}
            borderRadius={2}
            mb={5}
            sx={{
              borderColor: theme.palette.grey[500], // Lighter border color
            }}
          >
            <DataTable
              table={{ columns: conceptcolumns, rows: conceptrows }}
              showTotalEntries={false}
              isSorted={false}
              noEndBorder
              entriesPerPage={false}
              // canSearch={true}
            />
          </MDBox>

          {/* Proposal Table */}
          <MDTypography variant="h5" align="center" gutterBottom>
            Proposal
          </MDTypography>
          <MDBox
            p={2}
            border={1}
            borderRadius={2}
            mb={5}
            sx={{
              borderColor: theme.palette.grey[500], // Lighter border color
            }}
          >
            <DataTable
              table={{ columns: proposalcolumns, rows: proposalrows }}
              showTotalEntries={false}
              isSorted={false}
              noEndBorder
              entriesPerPage={false}
              // canSearch={true}
            />
            {/* {proposals.length > 0 ? (
              renderTable(proposals)
            ) : (
              <MDBox>
                {" "}
                <MDTypography
                  variant="button"
                  align="center"
                  // sx={{ fontFamily: "Times New Roman", fontSize: "12px" }}
                >
                  N/A
                </MDTypography>
              </MDBox>
            )} */}
          </MDBox>

          <MDTypography
            variant="h5"
            align="center"
            gutterBottom
            // sx={{ fontFamily: "Times New Roman", fontSize: "12px" }}
          >
            Report
          </MDTypography>
          <MDBox
            p={2}
            border={1}
            borderRadius={2}
            mb={5}
            sx={{
              borderColor: theme.palette.grey[500], // Lighter border color
            }}
          >
            <DataTable
              table={{ columns: reportcolumns, rows: reportrows }}
              showTotalEntries={false}
              isSorted={false}
              noEndBorder
              entriesPerPage={false}
              // canSearch={true}
            />
            {/* {reports.length > 0 ? (
              renderTable(reports)
            ) : (
              <MDBox>
                {" "}
                <MDTypography
                  variant="button"
                  align="center"
                  // sx={{ fontFamily: "Times New Roman", fontSize: "12px" }}
                >
                  N/A
                </MDTypography>
              </MDBox>
            )} */}
          </MDBox>
        </MDBox>
      </Card>
    </DashboardLayout>
  );
};

export default ViewProject;
