import { useState } from "react";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// FAQ Collapse Component
import FaqCollapse from "../../../../pages/LandingPages/AboutUs/sections/components/faqCollapse";

const faqs = [
  {
    title: "What is the Research Tracker?",
    answer:
      "The Research Tracker is a comprehensive project management tool designed specifically for academic researchers. It helps you organize, manage, and monitor your research projects from start to finish.",
  },
  {
    title: "How do I get started with the Research Tracker?",
    answer:
      'Getting started is easy! Simply click on the "Sign Up" button on our homepage, fill in your details, and you\'ll be ready to start managing your research in no time.',
  },
  {
    title: "Is my data secure on the Research Tracker?",
    answer:
      "Absolutely!. We prioritize your data security with robust encryption and regular backups. Your research data is stored securely and is only accessible to you and your authorized users only.",
  },
  {
    title: "Is there a mobile app for the Research Tracker?",
    answer:
      "Currently, we do not have a dedicated mobile app. However, our platform is fully responsive and can be accessed via mobile browsers, ensuring you can manage your projects on the go.",
  },
  {
    title: "How can I monitor the progress of my projects?",
    answer:
      "The Research Tracker provides progress monitoring with intuitive progress bars and charts. You can view detailed reports and analytics to track the status and performance of your projects.",
  },
];

function FAQ() {
  const [collapse, setCollapse] = useState(false);

  return (
    <MDBox component="section" py={2}>
      <Container>
        <Grid container justifyContent="center">
          <Grid item xs={12} md={6} my={4}>
            <MDTypography
              variant="h2"
              align="center"
              fontWeight="bold"
              gutterBottom
            >
              Frequently Asked Questions
            </MDTypography>
            <MDBox>
              <MDTypography variant="body2" align="center" color="text">
                These are some of the most asked questions about the research
                tracker and how we have been able to answer them
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12} md={10}>
            {/* <FaqCollapse
              title="How do I order?"
              open={collapse === 1}
              onClick={() =>
                collapse === 1 ? setCollapse(false) : setCollapse(1)
              }
            >
              If everything I did failed - which it doesn&apos;t, it actually
              succeeds - just the fact that I&apos;m willing to fail is an
              inspiration. People are so scared to lose that they don&apos;t
              even try. Like, one thing people can&apos;t say is that I&apos;m
              not trying, and I&apos;m not trying my hardest, and I&apos;m not
              trying to do the best way I know how.
            </FaqCollapse> */}
            {faqs.map((faq, index) => (
              <FaqCollapse
                key={index}
                title={faq.title}
                open={collapse === index}
                onClick={() =>
                  collapse === index ? setCollapse(false) : setCollapse(index)
                }
              >
                {faq.answer}
              </FaqCollapse>
            ))}
          </Grid>
        </Grid>
      </Container>
    </MDBox>
  );
}

export default FAQ;
