import { Card } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";

//Data
import pendingData from "../data/pendingData";

const PendingStudents = () => {
  const { columns, rows1 } = pendingData();

  return (
    <Card>
      <MDBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        px={3}
        pt={3}
      >
        <MDTypography variant="h5" gutterBottom>
          Pending Students
        </MDTypography>
      </MDBox>
      <MDBox>
        <DataTable
          table={{ columns, rows: rows1 }}
          isSorted={false}
          canSearch={true}
          entriesPerPage={false}
          showTotalEntries={true}
          // noEndBorder
        />
      </MDBox>
    </Card>
  );
};

export default PendingStudents;
